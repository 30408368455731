import React, { useEffect, useState } from "react";
import {
  ADVANCE_ACADEMIC_PERFORMANCE_REPORT,
  ADVANCE_ENROLLMENT_APPLICATION_TRACKING_REPORT,
  CALENDARS_LIST,
  PROGRAMS_LIST,
} from "../../../../apis/apis";
import { useNavigate } from "react-router-dom";
import { Button, Select, Slider } from "antd";
import SisLoader from "../../../../widgets/loader";
import AdvancedEnrollAppTrackReportSummaryTable from "./advancedEnrollAppTrackReportSummaryTable";
import { CountryDropdown } from "react-country-region-selector";

const AdvancedEnrollAppTrackReportVisualSection = () => {
  const [loader, set_loader] = useState(false);
  const [application_checklist, set_application_checklist] = useState("");
  const [school_decision, set_school_decision] = useState("");
  const [enrollments_agreements, set_enrollments_agreements] = useState("");
  const [citizenship, set_citizenship] = useState("");
  const [student_type, set_student_type] = useState("");
  const [academic_year, set_academic_year] = useState("");
  const [certificate_programs, set_certificate_programs] = useState("");
  const [programme_intended, set_programme_intended] = useState("");
  const [table_list, set_table_list] = useState([]);
  const [gender, set_gender] = useState("");
  const [identify, set_identify] = useState("");
  const [age, set_age] = useState("");
  const [errors, set_errors] = useState([]);
  const [academic_year_list, set_academic_year_list] = useState([]);
  const [semester, set_semester] = useState("");
  const [sem_list, set_sem_list] = useState([]);
  const [programme_list, set_programme_list] = useState([]);
  const [student_athlete, set_student_athlete] = useState("");
  const [student_category, set_student_category] = useState("");
  const [calender_index, set_calender_index] = useState(0);
  const navigate = useNavigate();

  const LIST_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_academic_year_list(API_RESPONSE?.data?.calendars);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  // const calender_change_function = (value) => {
  //   set_semester("");
  //   set_errors("");
  //   const index = academic_year_list.findIndex((item) => item.id === value);
  //   set_academic_year(value);
  //   set_calender_index(index);
  //   set_sem_list(academic_year_list[index].semesters);
  // };

  const calender_change_function = (value) => {
    set_semester("");
    set_errors("");
    const index = academic_year_list.findIndex((item) => item.id === value);
  
    if (index !== -1) { // Only proceed if a valid academic year is selected
      set_academic_year(value);
      set_calender_index(index);
      set_sem_list(academic_year_list[index]?.semesters || []);  // Ensure semesters exist or use an empty array
    } else {
      // Handle case when custom option (e.g., "Please select academic calendar") is selected
      set_academic_year("");  // Reset academic year
      set_calender_index(-1);  // Reset index
      set_sem_list([]);  // Reset semesters list
    }
  };

  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE.data.status) {
      set_programme_list(API_RESPONSE.data.programmes);
    } else {
      set_loader(false);
    }
  };

  const GENERATE_ADVANCE_ENROLLMENT_APPLICATION_TRACKING_REPORT = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calender_id", academic_year);
    FORM_DATA.append("semester_id", semester);
    FORM_DATA.append("application_checklist", application_checklist);
    FORM_DATA.append("program_id", programme_intended);
    FORM_DATA.append("school_decision", school_decision);
    FORM_DATA.append("certificate_programs", certificate_programs);
    FORM_DATA.append("student_type", student_type);
    FORM_DATA.append("enrollments_agreements", enrollments_agreements);
    FORM_DATA.append("age", age);
    FORM_DATA.append("gender", gender);
    FORM_DATA.append("identify", identify);
    FORM_DATA.append("citizenship", citizenship);
    FORM_DATA.append("student", identify);
    FORM_DATA.append("citizenship", citizenship);
    FORM_DATA.append("student_category", student_category);
    FORM_DATA.append("student_athlete", student_athlete);
    const API_RESPONSE = await ADVANCE_ENROLLMENT_APPLICATION_TRACKING_REPORT(
      FORM_DATA
    );
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE?.data?.report);
      set_loader(false);
    } else {
      set_errors(API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  };

  useEffect(() => {
    LIST_API();
    PROGRAMS_LIST_API();
  }, [academic_year]);
  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div className="theme-content-left-head">
            <h2>Enrollment Application Tracking Reports</h2>
            <br />
            <p>
              Track prospective student application submissions, admission
              decisions, and enrollment confirmations.
            </p>
          </div>
          <br />
          <hr />
          <br />
          <h3 style={{ fontSize: "18px", fontWeight: "600", color: "#4b4b4b" }}>
            Filters
          </h3>
          <br />
          <div className="row">
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Academic Year<i style={{ color: "red" }}>*</i>
                </label>
                <br />
                <Select
                  placeholder="--Select Academic Year--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={calender_change_function}
                  value={
                    academic_year ? parseInt(academic_year) : academic_year
                  }
                  options={[
                    { value: "", label: "Please select academic calendar" }, // Custom option
                    ...academic_year_list.map((item) => ({
                      value: item.id,
                      calender_id: item.id,
                      label: item.title,
                    })),
                  ]}
                />
                {errors?.calender_id && (
                  <>
                    <span style={{ color: "red" }}>
                      The Academic Year field is required.
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Semester<i style={{ color: "red" }}>*</i>
                </label>
                <br />

                <Select
                  placeholder="--Select Semester--"
                  style={{ width: "100%", height: "40px" }}
                  disabled={Boolean ? !academic_year : academic_year} // Disable until a academic year is selected
                  onChange={(value) => {
                    set_semester(value);
                  }}
                  value={semester}
                  options={[
                    { value: "", label: "Please select semester" }, // Static custom option
                    ...sem_list.map((item) => ({
                      value: item?.id,
                      label: item?.title,
                    })),
                  ]}
                />

                {errors?.semester_id && (
                  <>
                    <span style={{ color: "red" }}>
                      The semester field is required.
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>
                  Application Checklist<i style={{ color: "red" }}>*</i>
                </label>
                <Select
                  value={application_checklist}
                  placeholder="--Application Checklist--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_application_checklist(value)}
                >
                  <Select.Option value="">Please select application checklist</Select.Option>
                  <Select.Option value="Awaiting">Awaiting</Select.Option>
                  <Select.Option value="requested">requested</Select.Option>
                  <Select.Option value="Waived">Waived</Select.Option>
                  <Select.Option value="Completed">Completed</Select.Option>
                </Select>
                {errors?.application_checklist && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.application_checklist[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>
                  Program / Major<i style={{ color: "red" }}>*</i>
                </label>
                <Select
                  value={programme_intended}
                  placeholder="--Select programme--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_programme_intended(value)}
                >
                   <Select.Option value="">
                   Please select program
                  </Select.Option>
                  {programme_list.map((item) => {
                    if (item.status === 1 && item.soft_delete === 0) {
                      return (
                        <>
                          <Select.Option value={item.id}>
                            {item.title}
                          </Select.Option>
                        </>
                      );
                    }
                  })}
                </Select>
                {errors?.program_id && (
                  <>
                    <span style={{ color: "red" }}>
                      The Program field is required.
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-3">
              <div className="input-box">
                <label>School Decision</label>
                <Select
                  value={school_decision}
                  placeholder="--Select Decision--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_school_decision(value)}
                >
                   <Select.Option value="">
                   Please select school decision
                  </Select.Option>
                  <Select.Option value="Select School Decision">
                    Select School Decision
                  </Select.Option>
                  <Select.Option value="Regular Acceptance">
                    Regular Acceptance
                  </Select.Option>
                  <Select.Option value="Condition Acceptance">
                    Condition Acceptance
                  </Select.Option>
                  <Select.Option value="Deferred">Deferred</Select.Option>
                  <Select.Option value="Denied">Denied</Select.Option>
                  <Select.Option value="Waitlisted">Waitlisted</Select.Option>
                  <Select.Option value="Enrolled">Enrolled</Select.Option>
                </Select>
                {errors?.school_decision && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.school_decision[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className=" col-3">
              <div className="input-box">
                <label>Certificate Type</label>
                <br></br>
                <Select
                  value={certificate_programs}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_certificate_programs(value)}
                  placeholder="--Select Course Level--"
                >
                    <Select.Option value="">
                   Please select certificate type
                  </Select.Option>
                  <Select.Option value="1">Under Graduate</Select.Option>
                  <Select.Option value="2">Graduate</Select.Option>
                  <Select.Option value="3">Doctorate</Select.Option>
                  <Select.Option value="4">Certificate Programs</Select.Option>
                </Select>
                {errors?.certificate_programs && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.certificate_programs[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Student Type</label>
                <br></br>
                <Select
                  style={{ width: "100%", height: "40px" }}
                  placeholder="-- Student Type--"
                  onChange={(value) => {
                    set_student_type(value);
                  }}
                  value={student_type}
                >
                   <Select.Option value="">
                   Please select student type
                  </Select.Option>
                  {["New", "Transferred", "Returning", "Alumni"].map((item) => {
                    return (
                      <>
                        <Select.Option value={item}>{item}</Select.Option>
                      </>
                    );
                  })}
                </Select>
                {errors?.student_type && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.student_type[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Enrollment Contract</label>
                <br />
                <Select
                  value={enrollments_agreements}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_enrollments_agreements(value)}
                >
                    <Select.Option value="">
                   Please select enrollment contract
                  </Select.Option>
                  <Select.Option value="1">Submitted</Select.Option>
                  <Select.Option value="0">Not Submitted</Select.Option>
                </Select>
                {errors?.enrollments_agreements && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.enrollments_agreements[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-3">
              <div className="input-box">
                <label>
                  Student Category
                </label>
                <br />
                <Select
                  value={student_category}
                  style={{ width: "100%", height: "40px" }}
                  placeholder="--Select Student Category--"
                  onChange={(value) => set_student_category(value)}
                >
                    <Select.Option value="">
                   Please select student category
                  </Select.Option>
                  <Select.Option value="International">
                    International
                  </Select.Option>
                  <Select.Option value="Domestic">Domestic</Select.Option>
                </Select>
                {errors?.student_category && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.student_category[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>
                  Student Athlete
                </label>
                <br></br>
                <Select
                  value={student_athlete}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_student_athlete(value)}
                  placeholder="--Select Student Athlete--"
                >
                  <Select.Option value="">
                   Please select student athlete
                  </Select.Option>
                  <Select.Option value={1}>Yes</Select.Option>
                  <Select.Option value={0}>No</Select.Option>
                </Select>
                {errors?.student_athlete && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.student_athlete[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          <h3 style={{ fontSize: "15px", fontWeight: "600", color: "#4b4b4b" }}>
            Student Demographic
          </h3>
          <div className="row">
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>Age</label>
                <br />
                <Slider
                  min={15}
                  max={40}
                  onChange={(value) => {
                    if (Number.isNaN(value)) {
                      return;
                    }
                    set_age(value);
                  }}
                  value={typeof age === "number" ? age : 0}
                  step={1}
                />
                {errors?.age && (
                  <>
                    <span style={{ color: "red" }}>{errors?.age[0]}</span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Gender</label>
                <br></br>
                <Select
                  value={gender}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_gender(value)}
                  placeholder="--Select Gender--"
                >
                  <Select.Option value="">Please select gender</Select.Option>
                  <Select.Option value="male">Male</Select.Option>
                  <Select.Option value="female">Female</Select.Option>
                  <Select.Option value="others">Others</Select.Option>
                </Select>
                {errors?.gender && (
                  <>
                    <span style={{ color: "red" }}>{errors?.gender[0]}</span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Ethnicity</label>
                <Select
                  disabled={localStorage.getItem("payment_status") == 1}
                  value={identify}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_identify(value)}
                  placeholder="--identify yourself--"
                >
                    <Select.Option value="">Please select ethnicity</Select.Option>
                  <Select.Option value="american indian or native alaskan">
                    American Indian or Native Alaskan
                  </Select.Option>
                  <Select.Option value="asian or pacific islander">
                    Asian or Pacific Islander
                  </Select.Option>
                  <Select.Option value="african american">
                    African American
                  </Select.Option>
                  <Select.Option value="hispanic">Hispanic</Select.Option>
                  <Select.Option value="white">White</Select.Option>
                </Select>
                {errors?.identify && (
                  <>
                    <span style={{ color: "red" }}>{errors?.identify[0]}</span>
                  </>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Citizenship</label>
                <CountryDropdown
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_citizenship(value)}
                  value={citizenship}
                  className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                />
                {errors?.citizenship && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.citizenship[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          <Button
            onClick={GENERATE_ADVANCE_ENROLLMENT_APPLICATION_TRACKING_REPORT}
            style={{
              backgroundColor: "#4b4b4b",
              color: "#ffffff",
              width: "156px",
              height: "40px",
            }}
            type="primary"
          >
            Generate Report
          </Button>
          <br />
          <br />
          {table_list?.length > 0 && (
            <AdvancedEnrollAppTrackReportSummaryTable 
            table_list={table_list}
            academic_year={academic_year}
            semester={semester}
            application_checklist={application_checklist}
            programme_intended={programme_intended}
            school_decision={school_decision}
            certificate_programs={certificate_programs}
            student_type={student_type}
            enrollments_agreements={enrollments_agreements}
            student_category={student_category}
            student_athlete={student_athlete}
            age={age}
            gender={gender}
            identify={identify}
            citizenship={citizenship}
             />
          )}
        </>
      )}
    </>
  );
};

export default AdvancedEnrollAppTrackReportVisualSection;
