import React from 'react'
import { useEffect, useState } from "react";
import { Checkbox, Input, Table, notification, } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { CALENDARS_LIST, LIST_STUDENT_HEALTH_INSURANCE, STUDENT_SERVICE_ADD_UPDATE, } from "../../apis/apis";
import SisLoader from '../../widgets/loader';
import {useNavigate} from "react-router-dom";


function StudentListHealthInsurance() {

  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [calendars_list, set_calendars_list] = useState([]);
  const [semesters_list, set_semesters_list] = useState([]);
  const [active_calender_id, set_active_calender_id] = useState();
  const [active_sem_id, set_active_sem_id] = useState();
  const [comment, set_comment_data] = useState("");
  const [table_list, set_table_list] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [checkbox_disabled, set_checkbox_disabled] = useState(false);

  // Permissions state manage 
  const [all_permissions, set_all_permissions] = useState(false);
  const [permissions, set_permissions] = useState([]);
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === 'create') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const handleSearch = (e) => {
    const query = e.target.value;

    setSearchQuery(query);
    const filtered = table_list.filter(
      (item) => {
        var name = item.enrollment_details.middle_name === 'null' && !item.enrollment_details.middle_name ? item.enrollment_details.first_name + ' ' + item.enrollment_details.last_name : item.enrollment_details.first_name + ' ' + item.enrollment_details.middle_name + ' ' + item.enrollment_details.last_name;
        if (name.toLowerCase().includes(query.toLowerCase())) {
          return item;
        }
      }
    );
    if (query === "") {
      setFilteredData(table_list);
    } else {
      console.log('filtered0', filtered);
      setFilteredData(filtered);
    }

  };

  const ADD_API = async (health_insurance_status, comment, student_id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", active_calender_id);
    FORM_DATA.append("semester_id", active_sem_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("student_status", "Ready for payment");
    FORM_DATA.append("table_list", JSON.stringify(table_list));
    FORM_DATA.append("health_insurance_status", health_insurance_status);
    FORM_DATA.append("comment", comment);

    const API_RESPONSE = await STUDENT_SERVICE_ADD_UPDATE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Student Semester Health Insurance update successfully .",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      REGISTER_STUDENT_LIST_API(active_calender_id,active_sem_id);
      // navigate('/health-insurance/' + calender_id + '/' + sem_id)
    } else {
      set_errors(API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  };

  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    const Calendar_API_RESPONSE = await CALENDARS_LIST(FORM_DATA);

    if (Calendar_API_RESPONSE?.data?.status) {
      // set_loader(false);
      set_calendars_list(Calendar_API_RESPONSE?.data?.calendars);
      set_active_calender_id(Calendar_API_RESPONSE?.data?.calendars[0]?.id)
      set_semesters_list(Calendar_API_RESPONSE?.data?.calendars[0]?.semesters)
      set_active_sem_id(Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id);
      if(Calendar_API_RESPONSE?.data?.calendars[0]?.id && Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id)
      {
        REGISTER_STUDENT_LIST_API(Calendar_API_RESPONSE?.data?.calendars[0]?.id,Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id);
      }

    } else {
      // set_loader(false);
    }
  }

  const REGISTER_STUDENT_LIST_API = async (calender_id,sem_id) => {
     set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", calender_id);
    FORM_DATA.append("semester_id", sem_id);
    const API_RESPONSE = await LIST_STUDENT_HEALTH_INSURANCE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE?.data?.student_list_health_insurance);
      setFilteredData(API_RESPONSE?.data?.student_list_health_insurance);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  useEffect(() => {
    LIST_API();
  }, []);

  const columns = [
    {
      title: "ID",
      render:(text, record) => {
        return(
          <>
           {record.enrollment_details.id}
          </>
        )
      }
    },

    {
      title: "Name",
      render: (text, record) => {
        if (record.student_status) {
          return (
            <>
              <span>
                {record.enrollment_details.first_name + ' '}
                {(record.enrollment_details.middle_name === 'null' || record.enrollment_details.middle_name === null) ? '' : record.enrollment_details.middle_name + ' '}
                {record.enrollment_details.last_name}
              </span>
            </>

          );
        } else {
          return (
            <>
              <span
                style={{ cursor: "pointer" }}

              >
                {record.enrollment_details.first_name}
                {(record.enrollment_details.middle_name === 'null' || record.enrollment_details.middle_name === null) ? ' ' : record.enrollment_details.middle_name}
                {record.enrollment_details.last_name}
                <span
                  style={{
                    backgroundColor: "red",
                    fontSize: "10px",
                    position: "relative",
                    color: "#fff",
                    padding: "2px 5px",
                    top: "-6px",
                    left: "3px",
                    borderRadius: "5px",
                  }}
                >
                  New
                </span>
              </span>
            </>
          );
        }
      },
    },

    {
      title: "Program Intended",
      dataIndex: "program_intended",
      key: "title",
      render: (text, record) => {
        return <> {record?.enrollment_details?.title}</>;

      },
    },

    {
      title: "Student Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {

        return <> {(record?.enrollment_details?.student_status == 0 && 'Ready for Payment')||
        (record?.enrollment_details?.student_status == 2 && 'Paid')||
        (record?.enrollment_details?.student_status == 3 && 'Partial Paid')||
        (record?.enrollment_details?.student_status == 4 && 'Not Paid')
        }</>;

      },
    },

    {
      title: "Comment (if any)",
      dataIndex: "primary_email",
      key: "primary_email",
      render: (text, record) => {


        return <>
          {(check_permissions('student_semester_health_insurance', 'create') || check_permissions('student_semester_health_insurance', 'update')) &&
            <Input
              defaultValue={record?.enrollment_details?.comment}
              placeholder=" comment....."
              onChange={(e) => set_comment_data(e.target.value)}
            />
          }
        </>;
      },
    },

    {
      title: "Health Insurance",
      dataIndex: "health_insurance_status",
      key: "health_insurance_status",

      render: (text, record) => {
       const paid_disabled =  record.enrollment_details.student_status == 2 || record.enrollment_details.student_status == 3;
        return <>
          {(check_permissions('student_semester_health_insurance', 'create') || check_permissions('student_semester_health_insurance', 'update')) &&
            <>
              {record?.enrollment_details?.health_insurance_status ? <>
                <Checkbox
                disabled={paid_disabled}
                  checked
                  onChange={(e) => {
                    ADD_API(e.target.checked ? 1 : 0, comment, record.student_id);

                  }}
                />
              </> : <>
                <Checkbox
                 disabled={paid_disabled}
                  onChange={(e) => {
                    ADD_API(e.target.checked ? 1 : 0, comment, record.student_id);

                  }}
                />
              </>}
            </>
          }
        </>;

      },
    },


  ];

  const generate_sem_list = (item) => {
    set_semesters_list(item?.semesters);
    set_active_calender_id(item?.id);
    set_active_sem_id(item.semesters[0]?.id);
    REGISTER_STUDENT_LIST_API(item?.id,item.semesters[0]?.id);

  }

  const generate_students_list = (sem_detail) => {
    set_active_sem_id(sem_detail?.id);
    REGISTER_STUDENT_LIST_API(sem_detail?.calendar_id,sem_detail?.id);
  }

  return (
    <>
    {loader ? (
            <SisLoader />
          ) : (
            <>
      <div className="theme-content-left-head">
        <h3>Student Services</h3>
      </div>
      <div className="theme-content-head">
        <div className="sis-tab-menu">
          {calendars_list.length > 0 && (
            <>
              {calendars_list.map((item, index) => (
                <>
                  <>
                    {(active_calender_id == item?.id) ?
                      <>
                        <span className="active" >{item?.title} </span>
                      </>
                      :
                      <>
                        <span onClick={() => generate_sem_list(item)} >{item.title}</span>
                      </>
                    }
                  </>
                </>
              ))}
            </>
          )}
        </div>
       
      </div>
      <div className="sis-tab-sem-menu">
          {semesters_list?.length > 0 &&
            <>
              {semesters_list.map((item, index) => (
                <>
                  <>
                    {(active_sem_id == item.id) ?
                      <>
                        <span className="active" >{item.title} </span>
                      </>
                      :
                      <>
                        <span onClick={() => generate_students_list(item)} >{item.title}</span>
                      </>
                    }
                  </>
                </>
              ))}
            </>
          }
        </div>
      <div>
        <div className="input-box" style={{ maxWidth: "250px", marginTop: "30px" }}>
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
          {errors?.code && (
            <span style={{ color: "red" }}>{errors?.code[0]}</span>
          )}
        </div>
        <div className="sis-tab-content" style={{ marginTop: "50px" }}  >
        <Table columns={columns} dataSource={filteredData} pagination={false} />
        </div>
      </div>
      </>
    )}
    </>
  )
}


export default StudentListHealthInsurance