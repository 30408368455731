import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./attendance.css";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, DownOutlined, EditFilled, UpOutlined } from "@ant-design/icons";
import Reasons from "./categories/reasons";
import ReasonsView from "./categories/ReasonsView";

function AttendanceSetupRow({item, open}) {
  const navigate = useNavigate();
  const [show_container, set_show_container] = useState(open);
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if(localStorgeData.role === 2074){
        return true;
    }
    var check_permissions = [];
    if (type === 'view') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
  }
    if (type === 'create') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
        return true;
    } else {
        return false;
    }
}

  useEffect(() =>{


  }, [])
  return (
    <>
      <div className="sis-section">
        <div className="sis-section-head">
          <h4> {item.title}</h4>
          <div>
          { check_permissions('attendance_categories', 'update') && <b>
             
              <EditFilled style={{ cursor:"pointer" }} onClick={() => navigate("/attendance-category/" + btoa(item.id))} />
            </b>}
            {show_container ? (
              <b onClick={() => set_show_container(false)}>
                <DownOutlined />{" "}
              </b>
            ) : (
              <b onClick={() => set_show_container(true)}>
                {" "}
                <UpOutlined />{" "}
              </b>
            )}
          </div>
        </div>

        {show_container && (
          <>
            <div className="sis-section-container">
                  <ReasonsView attendance_category_id={item.id} />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AttendanceSetupRow;
