import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, DatePicker, Input, Radio, Select, Spin, Table, Tooltip, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, CloudUploadOutlined, LoadingOutlined, SmileOutlined, } from "@ant-design/icons";
import { SIS_STORAGE, PERSONAL_INFORMATION_SAVE, GET_PERSONAL_INFORMATION, sisDateFormat } from "../../apis/apis";
import { CountryDropdown } from "react-country-region-selector";
import './processbar.css'
import dayjs from "dayjs";
import SisLoader from "../../widgets/loader";

const PersonalInformation = ({id}) => {
    const dateFormat = 'MM-DD-YYYY';
    const navigate = useNavigate();
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    const studentImage = useRef(null);
    // Form State
    const [application_start_on, set_application_start_on] = useState("");
    const [application_no, set_application_no] = useState("");

    // basic information
    const [check_save_status, set_check_save_status] = useState("");
    const [image, set_image] = useState("");
    const [first_name, set_first_name] = useState("");
    const [middle_name, set_middle_name] = useState("");
    const [last_name, set_last_name] = useState("");

    const [student_id, set_student_id] = useState("");
    const [gender, set_gender] = useState("");
    const [student_athlete, set_student_athlete] = useState("");
    const [birth_date, set_birth_date] = useState("");
    const [country_of_birth, set_country_of_birth] = useState("");
    const [city_of_birth, set_city_of_birth] = useState("");
    const [ssn, set_ssn] = useState("");
    const [ssnerror, set_Ssn_Error] = useState('');

    // Contact
    const [phoneerror, set_Phone_Error] = useState('');
    const [emergencyphoneerror, set_emergency_phone_error] = useState('');
    const [phone, set_phone] = useState("");
    const [primary_email, set_primary_email] = useState("");
    const [secondary_email, set_secondary_email] = useState("");
    const [citizenship, set_citizenship] = useState("");
    const [emergency_first_name, set_emergency_first_name] = useState("");
    const [emergency_last_name, set_emergency_last_name] = useState("");
    const [emergency_phone, set_emergency_phone] = useState("");
    const [emergency_email, set_emergency_email] = useState("");
    const [emergency_relationship, set_emergency_relationship] = useState("");
    const [emergency_address1, set_emergency_address1] = useState("");
    const [emergency_address2, set_emergency_address2] = useState("");
    const [emergency_address_city, set_emergency_address_city] = useState("");
    const [emergency_address_state, set_emergency_address_state] = useState("");
    const [emergency_address_postal, set_emergency_address_postal] = useState("");
    const [emergency_address_country, set_emergency_address_country] = useState("");
    const [mailing_address1, set_mailing_address1] = useState("");
    const [mailing_address2, set_mailing_address2] = useState("");
    const [mailing_address_city, set_mailing_address_city] = useState("");
    const [mailing_address_state, set_mailing_address_state] = useState("");
    const [mailing_address_postal, set_mailing_address_postal] = useState("");
    const [mailing_address_country, set_mailing_address_country] = useState("");
    const [home_country_address1, set_home_country_address1] = useState("");
    const [home_country_address2, set_home_country_address2] = useState("");
    const [home_country_address_city, set_home_country_address_city] =
        useState("");
    const [home_country_address_state, set_home_country_address_state] =
        useState("");
    const [home_country_address_postal, set_home_country_address_postal] =
        useState("");
    const [home_country_address_country, set_home_country_address_country] =
        useState("");

    const [api_image, set_api_image] = useState("");


    const handleImageChange = (e, type) => {
        const files = e.target.files;
        if (type === "image") {
            set_image(files[0]);
        }
    };

    // handleRemove Image
    const handleRemoveImage = (e, type) => {
        if (type === "image") {
            set_image("");
            studentImage.current.value = '';
        }
    };

    const PERSONAL_INFORMATION_DRAFT_NEXT = async (save_status) => {
        if (!phoneerror && !emergencyphoneerror && !ssnerror) {
            set_errors("");
            set_loader(true);
            const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
            const FORM_DATA = new FormData();
            FORM_DATA.append('token', USER_DATA.token);
            FORM_DATA.append('student_user_id', id);
            FORM_DATA.append('save_status', save_status);
            FORM_DATA.append("application_start_on", application_start_on);
            FORM_DATA.append("application_no", application_no);
            //Basic Information
            FORM_DATA.append("image", image);
            FORM_DATA.append("student_id", student_id);
            FORM_DATA.append("first_name", first_name);
            FORM_DATA.append("middle_name", middle_name);
            FORM_DATA.append("last_name", last_name);
            FORM_DATA.append("birth_date", birth_date === null ? "" : birth_date);
            FORM_DATA.append("country_of_birth", country_of_birth === null ? "" : country_of_birth);
            FORM_DATA.append("city_of_birth", city_of_birth === null ? "" : city_of_birth);
            FORM_DATA.append("gender", gender === null ? "" : gender);
            FORM_DATA.append("citizenship", citizenship);
            FORM_DATA.append("ssn", ssn);
            FORM_DATA.append("student_athlete", student_athlete);

            // Contacts
            FORM_DATA.append("primary_email", primary_email);
            FORM_DATA.append("secondary_email", secondary_email);
            FORM_DATA.append("phone", phone);


            // Emergency Contact
            FORM_DATA.append("emergency_first_name", emergency_first_name);
            FORM_DATA.append("emergency_last_name", emergency_last_name);
            FORM_DATA.append("emergency_email", emergency_email);
            FORM_DATA.append("emergency_relationship", emergency_relationship);
            FORM_DATA.append("emergency_phone", emergency_phone);

            // Emergency Address
            FORM_DATA.append("emergency_address_country", emergency_address_country);
            FORM_DATA.append("emergency_address_city", emergency_address_city);
            FORM_DATA.append("emergency_address_state", emergency_address_state);
            FORM_DATA.append("emergency_address_postal", emergency_address_postal);
            FORM_DATA.append("emergency_address1", emergency_address1);
            FORM_DATA.append("emergency_address2", emergency_address2);

            // Mailing Address
            FORM_DATA.append("mailing_address_country", mailing_address_country);
            FORM_DATA.append("mailing_address_city", mailing_address_city);
            FORM_DATA.append("mailing_address_state", mailing_address_state);
            FORM_DATA.append("mailing_address_postal", mailing_address_postal);
            FORM_DATA.append("mailing_address1", mailing_address1);
            FORM_DATA.append("mailing_address2", mailing_address2);

            // Home Address
            FORM_DATA.append(
                "home_country_address_country",
                home_country_address_country === null ? "" : home_country_address_country
            );
            FORM_DATA.append("home_country_address_city", home_country_address_city === null ? "" : home_country_address_city);
            FORM_DATA.append("home_country_address_state", home_country_address_state === null ? "" : home_country_address_state);
            FORM_DATA.append(
                "home_country_address_postal",
                home_country_address_postal === null ? "" : home_country_address_postal
            );
            FORM_DATA.append("home_country_address1", home_country_address1 === null ? "" : home_country_address1);
            FORM_DATA.append("home_country_address2", home_country_address2);

            const API_RESPONSE = await PERSONAL_INFORMATION_SAVE(FORM_DATA);
            if (API_RESPONSE?.data?.status) {
                localStorage.setItem('personal_information_status', API_RESPONSE?.data?.save_status);
                if (API_RESPONSE?.data?.save_status == "1") {
                    window.location.reload();
                    notification.open({
                        message: "Success!!",
                        description: "Personal Information Saves as Draft.",
                        icon: <SmileOutlined style={{ color: "green" }} />,
                    });
                    set_loader(false);

                }

                if (API_RESPONSE?.data?.save_status == "2") {
                    notification.open({
                        message: "Success!!",
                        description: "Personal Information Saved Sucessfully",
                        icon: <SmileOutlined style={{ color: "green" }} />,
                    });
                    set_loader(false);

                    navigate('/demographic-information');
                }

            } else {
                set_errors(API_RESPONSE?.data?.errors);
                set_loader(false);
            }

        }
    }

    useEffect(() => {
         VIEW_PERSONAL_INFORMATION();
    }, []);

    const VIEW_PERSONAL_INFORMATION = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', atob(id));
        const VIEW_PERSONAL_INFORMATION_API_RESPONSE = await GET_PERSONAL_INFORMATION(FORM_DATA);
        if (VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.status) {
            set_check_save_status(VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.save_status);
            localStorage.setItem('personal_information_status', VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.save_status);
            const PERSONAL_DETAILS_DATA = VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data?.personal_details;
            const PERSONAL_CONTACT_DETAILS_DATA = VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data?.contact_details;
            set_application_no(PERSONAL_DETAILS_DATA?.application_no);
            set_application_start_on(PERSONAL_DETAILS_DATA?.application_start_date);
            set_api_image(PERSONAL_DETAILS_DATA?.image);
            set_first_name(PERSONAL_DETAILS_DATA?.first_name);
            set_middle_name(PERSONAL_DETAILS_DATA?.middle_name);
            set_last_name(PERSONAL_DETAILS_DATA?.last_name);
            set_student_id(PERSONAL_DETAILS_DATA?.student_id);
            set_birth_date(PERSONAL_DETAILS_DATA?.birth_date);
            set_country_of_birth(PERSONAL_DETAILS_DATA?.country_of_birth);
            set_city_of_birth(PERSONAL_DETAILS_DATA?.city_of_birth);
            set_gender(PERSONAL_DETAILS_DATA?.gender);
            set_citizenship(PERSONAL_DETAILS_DATA?.citizenship);
            set_student_athlete(PERSONAL_DETAILS_DATA?.student_athlete);
            set_ssn(PERSONAL_DETAILS_DATA?.ssn);
            set_primary_email(PERSONAL_CONTACT_DETAILS_DATA?.primary_email);
            set_secondary_email(PERSONAL_CONTACT_DETAILS_DATA?.secondary_email);
            set_phone(PERSONAL_CONTACT_DETAILS_DATA?.phone);
            set_emergency_first_name(PERSONAL_CONTACT_DETAILS_DATA?.emergency_contact?.emergency_first_name);
            set_emergency_last_name(PERSONAL_CONTACT_DETAILS_DATA?.emergency_contact?.emergency_last_name);
            set_emergency_email(PERSONAL_CONTACT_DETAILS_DATA?.emergency_contact?.emergency_email);
            set_emergency_relationship(PERSONAL_CONTACT_DETAILS_DATA?.emergency_contact?.emergency_relationship);
            set_emergency_phone(PERSONAL_CONTACT_DETAILS_DATA?.emergency_contact?.emergency_phone);
            var emergency_address = PERSONAL_CONTACT_DETAILS_DATA?.emergency_address;
            set_emergency_address_country(emergency_address?.emergency_address_country);
            set_emergency_address_city(emergency_address?.emergency_address_city);
            set_emergency_address_state(emergency_address?.emergency_address_state);
            set_emergency_address_postal(emergency_address?.emergency_address_postal);
            set_emergency_address1(emergency_address?.emergency_address1);
            set_emergency_address2(emergency_address?.emergency_address2);
            var mailing_address = PERSONAL_CONTACT_DETAILS_DATA?.mailing_address;
            set_mailing_address_city(mailing_address.mailing_address_city);
            set_mailing_address_state(mailing_address.mailing_address_state);
            set_mailing_address_postal(mailing_address.mailing_address_postal);
            set_mailing_address_country(mailing_address.mailing_address_country);
            set_mailing_address1(mailing_address.mailing_address1);
            set_mailing_address2(mailing_address.mailing_address2);
            var home_country_address = PERSONAL_CONTACT_DETAILS_DATA?.home_country_address;
            set_home_country_address_city(home_country_address.home_country_address_city);
            set_home_country_address_state(home_country_address.home_country_address_state);
            set_home_country_address_postal(home_country_address.home_country_address_postal);
            set_home_country_address_country(home_country_address.home_country_address_country);
            set_home_country_address1(home_country_address.home_country_address1);
            set_home_country_address2(home_country_address.home_country_address2);

            set_loader(false);
        }
        else {
            set_loader(false);
        }

    }


    return (
        <>
            {loader ?
                <>
                    <SisLoader />
                </> :
                <>
                    <div>
                        <div className='row' style={{marginLeft:0}}>
                            <div className="theme-content-head">
                                <div className="theme-content-left-head">
                                    <h2 style={{ fontSize: "22px" }}>Online Application</h2>
                                   </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div className="input-box">
                                    <label>
                                        Application Started On
                                    </label>
                                    <Input
                                        disabled={true}
                                        value={sisDateFormat(application_start_on)}
                                        placeholder="Application Started On"
                                    />
                                    {errors?.application_start_on && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.application_start_on[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="input-box">
                                    <label>
                                        Application Number
                                    </label>
                                    <Input
                                        disabled={true}
                                        value={application_no === "null" || application_no === null ? "" : application_no}
                                        placeholder="Application Number"
                                    />
                                    {errors?.application_no && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.application_no[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Basic Information */}
                        <>
                            <div>
                                <br />
                                <hr />
                                <br />
                                <div className="theme-content-left-head">
                                    <h3>Basic Information </h3>
                                </div>
                                <br />
                            </div>
                            <>
                                <div className="row">
                                    {/* Student Image */}
                                    <div className="col-3">
                                        <div className="input-box" style={{ position: "relative" }}>
                                            <label>Image</label>
                                            <br></br>
                                            <label className="upload-box upload-box-2">
                                                <input
                                                disabled={true}
                                                    ref={studentImage}
                                                    type="file"
                                                    id="upload-images"
                                                    accept="image/*"
                                                    style={{ height: "0px", overflow: "hidden" }}
                                                    onChange={(e) => handleImageChange(e, "image")}
                                                />
                                                <CloudUploadOutlined />
                                                Upload
                                            </label>
                                            {image ? (
                                                <>
                                                    <div className="image-box">
                                                        <img
                                                            src={URL.createObjectURL(image)}
                                                            frameBorder="0"
                                                            style={{ width: "100%", height: "100%" }}
                                                        ></img>
                                                    </div>
                                                        {/* <button
                                                            className="image-remove"
                                                            onClick={(e) => handleRemoveImage(e, "image")}
                                                        >
                                                            <CloseOutlined />
                                                        </button> */}
                                                </>
                                            ) : (
                                                <>
                                                    {api_image && (
                                                        <>
                                                            <div className="image-box">
                                                                <img
                                                                    src={SIS_STORAGE + "/enrollments/" + api_image}
                                                                    frameBorder="0"
                                                                    style={{ width: "100%", height: "150px" }}
                                                                ></img>
                                                            </div>
                                                                {/* <button
                                                                    className="image-remove"
                                                                    onClick={(e) => handleRemoveImage(e, "api_image")}
                                                                >
                                                                    <CloseOutlined />
                                                                </button> */}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {errors?.image && (
                                                <>
                                                    <span style={{ color: "red" }}>{errors?.image[0]}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {/* First Name */}
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label>
                                                First Name<i style={{ color: "red" }}>*</i>
                                            </label>
                                            <Input
                                             disabled={true}
                                                value={first_name}
                                                placeholder="First Name"
                                                onChange={(e) => set_first_name(e.target.value)}
                                            />
                                            {errors?.first_name && (
                                                <>
                                                    <span style={{ color: "red" }}>
                                                        {errors?.first_name[0]}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {/* Middle Name */}
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label>Middle Name (Optional)</label>
                                            <Input
                                             disabled={true}
                                                value={middle_name}
                                                placeholder="Middle Name"
                                                onChange={(e) => set_middle_name(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* Last Name */}
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label>
                                                Last Name<i style={{ color: "red" }}>*</i>
                                            </label>
                                            <Input
                                             disabled={true}
                                                value={last_name}
                                                placeholder="Last Name"
                                                onChange={(e) => set_last_name(e.target.value)}
                                            />
                                            {errors?.last_name && (
                                                <>
                                                    <span style={{ color: "red" }}>
                                                        {errors?.last_name[0]}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    {/* Student ID */}
                                    {/* <div className="col-3">
                                        <div className="input-box">
                                            <label>
                                                Student ID
                                            </label>
                                            <Input
                                                value={student_id === "null" || student_id === null ? "" : student_id}
                                                disabled={true}
                                                placeholder="Student ID"
                                            />
                                            {errors?.student_id && (
                                                <>
                                                    <span style={{ color: "red" }}>
                                                        {errors?.student_id[0]}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div> */}
                                    {/* Birth of Date */}
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label style={{ textTransform: "none" }}>
                                                Date of Birth<i style={{ color: "red" }}>*</i>
                                            </label>
                                            <DatePicker format={dateFormat}
                                             disabled={true}
                                                style={{ width: "100%", height: "40px" }}
                                                value={birth_date && dayjs(birth_date)}
                                                onChange={(date, dateString) =>
                                                    set_birth_date(dateString)
                                                }
                                            />
                                            {errors?.birth_date && (
                                                <>
                                                    <span style={{ color: "red" }}>
                                                        {errors?.birth_date[0]}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {/* Country Of Birth */}
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label style={{ textTransform: "none" }}>
                                                Country of Birth<i style={{ color: "red" }}>*</i>
                                            </label>
                                            <CountryDropdown
                                             disabled={true}
                                                style={{ width: "100%", height: "40px" }}
                                                value={country_of_birth}
                                                onChange={(value) => set_country_of_birth(value)}
                                                className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                                            />
                                            {errors?.country_of_birth && (
                                                <>
                                                    <span style={{ color: "red" }}>
                                                        {errors?.country_of_birth[0]}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {/* Birth Place / City */}
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label>
                                                Birth City<i style={{ color: "red" }}>*</i>
                                            </label>
                                            <Input
                                             disabled={true}
                                                placeholder="Enter Your Birth City"
                                                value={city_of_birth}
                                                onChange={(e) => set_city_of_birth(e.target.value)}
                                            />
                                            {/* <CountryDropdown value={city_of_birth} onChange={(value) => set_city_of_birth(value)} className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box" />*/}
                                            {errors?.city_of_birth && (
                                                <>
                                                    <span style={{ color: "red" }}>
                                                        {errors?.city_of_birth[0]}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {/* Gender */}
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label>
                                                Gender<i style={{ color: "red" }}>*</i>
                                            </label>
                                            <br></br>
                                            <Select
                                             disabled={true}
                                                value={gender}
                                                style={{ width: "100%", height: "40px" }}
                                                onChange={(value) => set_gender(value)}
                                                placeholder="--Select Gender--"
                                            >
                                                <Select.Option value="male">Male</Select.Option>
                                                <Select.Option value="female">Female</Select.Option>
                                                <Select.Option value="others">Others</Select.Option>
                                            </Select>
                                            {errors?.gender && (
                                                <>
                                                    <span style={{ color: "red" }}>{errors?.gender[0]}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* Citizenship */}
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label>
                                                Citizenship<i style={{ color: "red" }}>*</i>
                                            </label>
                                            <CountryDropdown
                                             disabled={true}
                                                style={{ width: "100%", height: "40px" }}
                                                onChange={(value) => set_citizenship(value)}
                                                value={citizenship}
                                                className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                                            />
                                            {errors?.citizenship && (
                                                <>
                                                    <span style={{ color: "red" }}>
                                                        {errors?.citizenship[0]}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {/* Student Athlete */}
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label style={{ textTransform: "none" }}>
                                                Are You an Athlete?<i style={{ color: "red" }}>*</i>
                                            </label>
                                            <br></br>
                                            <Select
                                             disabled={true}
                                                value={student_athlete}
                                                style={{ width: "100%", height: "40px" }}
                                                onChange={(value) => set_student_athlete(value)}
                                                placeholder="--Select Student Athlete--"
                                            >
                                                <Select.Option value={1}>Yes</Select.Option>
                                                <Select.Option value={0}>No</Select.Option>
                                            </Select>
                                            {errors?.student_athlete && (
                                                <>
                                                    <span style={{ color: "red" }}>{errors?.student_athlete[0]}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {/* SSN(Optional) */}
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label>SSN (Optional)</label>
                                            <Input
                                             disabled={true}
                                                value={ssn === "null" || ssn === null ? "" : ssn}
                                                maxLength={11}
                                                placeholder="SSN"
                                                // onChange={(e) => set_ssn(e.target.value)}
                                                onKeyDown={(event) => {
                                                    // Allow Backspace, Delete, Tab, Enter, Arrow keys
                                                    if (
                                                        event.key === 'Backspace' ||
                                                        event.key === 'Delete' ||
                                                        event.key === 'Tab' ||
                                                        event.key === 'Enter' ||
                                                        event.key === 'ArrowUp' ||
                                                        event.key === 'ArrowDown' ||
                                                        event.key === 'ArrowLeft' ||
                                                        event.key === 'ArrowRight'
                                                    ) {
                                                        return;
                                                    }

                                                    // Prevent non-numeric characters
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    const ssnNumber = e.target.value;
                                                    // Remove non-digit characters from the input
                                                    const cleanedSsnNumber = ssnNumber.replace(/[^0-9]/g, '');
                                                    if (cleanedSsnNumber.length <= 9) {
                                                        set_Ssn_Error('')
                                                        // Format the phone number as 510-62-8022
                                                        let formattedSsnNumber = '';
                                                        for (let i = 0; i < cleanedSsnNumber.length; i++) {
                                                            if (i === 3 || i === 5) {
                                                                formattedSsnNumber += '-';
                                                            }
                                                            formattedSsnNumber += cleanedSsnNumber[i];
                                                        }
                                                        set_ssn(formattedSsnNumber);
                                                        set_Ssn_Error('')
                                                    }
                                                    else {
                                                        set_ssn(cleanedSsnNumber)
                                                        set_Ssn_Error("SSN Number Maximum Length Should be 9 Digit ")
                                                    }
                                                }}
                                            />
                                            {errors?.ssn && (<><span style={{ color: "red" }}>{errors?.ssn[0]}</span></>)
                                                || ssnerror && (<><span style={{ color: "red" }}>{ssnerror}</span></>)
                                            }
                                        </div>
                                    </div>
                                </div>

                            </>
                            {/*Contact Details  */}
                            <>
                                <br />
                                <hr />
                                <br />


                                <div className="theme-content-left-head">
                                    <h3>Contact Details </h3>
                                </div>
                                <br />
                                <div className="row">
                                    {/* Primary Email */}
                                    <Tooltip
                                        color="#fff"
                                        placement="bottom"
                                        title={<div><h3 className='first-letter-capitalize'>Primary Email</h3>
                                            <p>Primary Email is your as a Login Email. So, you can't modified it.</p>
                                        </div>}>
                                        <div className="col-4">
                                            <div className="input-box">
                                                <label htmlFor="primary email">
                                                    Primary Email<i style={{ color: "red" }}>*</i>
                                                </label>
                                                <Input
                                                    value={primary_email}
                                                    id="primary email"
                                                    placeholder="example123@gmail.com"
                                                    disabled={true}
                                                // onChange={(e) => set_primary_email(e.target.value)}
                                                />
                                                {errors?.primary_email && (
                                                    <>
                                                        <span style={{ color: "red" }}>{errors?.primary_email[0]}</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Tooltip>
                                    {/* Secondary Email */}
                                    <div className="col-4">
                                        <div className="input-box">
                                            <label htmlFor="secondary_email">Secondary Email</label>
                                            <Input
                                             disabled={true}
                                                value={secondary_email === "null" || secondary_email === null ? "" : secondary_email}
                                                id="secondary_email"
                                                placeholder="secondaryemail123@gmail.com"
                                                onChange={(e) => set_secondary_email(e.target.value)}
                                            />
                                            {errors?.secondary_email && (
                                                <>
                                                    <span style={{ color: "red" }}>{errors?.secondary_email[0]}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {/* Phone Number */}
                                    <div className="col-4">
                                        <div className="input-box">
                                            <label htmlFor="phone">
                                                Phone Number<i style={{ color: "red" }}>*</i>
                                            </label>
                                            <Input
                                             disabled={true}
                                                maxLength={12}
                                                value={phone}
                                                placeholder="Phone Number"
                                                onKeyDown={(event) => {
                                                    // Allow Backspace, Delete, Tab, Enter, Arrow keys
                                                    if (
                                                        event.key === 'Backspace' ||
                                                        event.key === 'Delete' ||
                                                        event.key === 'Tab' ||
                                                        event.key === 'Enter' ||
                                                        event.key === 'ArrowUp' ||
                                                        event.key === 'ArrowDown' ||
                                                        event.key === 'ArrowLeft' ||
                                                        event.key === 'ArrowRight'
                                                    ) {
                                                        return;
                                                    }

                                                    // Prevent non-numeric characters
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                id="phone"
                                                // onChange={(e) => set_phone(e.target.value)}
                                                onChange={(e) => {
                                                    const phoneNumber = e.target.value;
                                                    // Remove non-digit characters from the input
                                                    const cleanedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
                                                    if (cleanedPhoneNumber.length <= 10) {
                                                        set_Phone_Error('')
                                                        // Format the phone number as 510-628-8022
                                                        let formattedPhoneNumber = '';
                                                        for (let i = 0; i < cleanedPhoneNumber.length; i++) {
                                                            if (i === 3 || i === 6) {
                                                                formattedPhoneNumber += '-';
                                                            }
                                                            formattedPhoneNumber += cleanedPhoneNumber[i];
                                                        }
                                                        set_phone(formattedPhoneNumber);
                                                        set_Phone_Error('')
                                                    }
                                                    else {
                                                        set_phone(cleanedPhoneNumber)
                                                        set_Phone_Error("Phone Number Maximum Length Should be 10 Digit ")
                                                    }
                                                }}
                                            />
                                            {errors?.phone && (
                                                <>
                                                    <span style={{ color: "red" }}>{errors?.phone[0]}</span>
                                                </>
                                            )
                                                || phoneerror && (<><span style={{ color: "red" }}>{phoneerror}</span></>)
                                            }
                                        </div>
                                    </div>
                                </div>


                                {/* Mailing Address */}
                                <div style={{ marginTop: "20px" }}>
                                    <label className="cus-label">
                                        Mailing Address
                                    </label>
                                    <div className="row">
                                        {/* country */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label htmlFor="mailingcountry">
                                                    Country
                                                </label>
                                                <CountryDropdown
                                                 disabled={true}
                                                    id="mailingcountry"
                                                    style={{ width: "100%", height: "40px" }}
                                                    placeholde="--Select Country--"
                                                    value={mailing_address_country}
                                                    onChange={(value) => set_mailing_address_country(value)}
                                                    className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                                                />
                                                {errors?.mailing_address_country && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.mailing_address_country[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* city */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label htmlFor="email">
                                                    City
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={mailing_address_city}
                                                    placeholder="City"
                                                    onChange={(e) => set_mailing_address_city(e.target.value)}
                                                />
                                                {errors?.mailing_address_city && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.mailing_address_city[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* state */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label htmlFor="email">
                                                    State
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={mailing_address_state}
                                                    placeholder="State"
                                                    onChange={(e) => set_mailing_address_state(e.target.value)}
                                                />
                                                {errors?.mailing_address_state && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.mailing_address_state[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* Postal Code */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label htmlFor="email">
                                                    Zip / Postal Code
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={mailing_address_postal}
                                                    placeholder="Zip/Postal"
                                                    onChange={(e) =>
                                                        set_mailing_address_postal(e.target.value)
                                                    }
                                                    onKeyDown={(event) => {
                                                        // Allow Backspace, Delete, Tab, Enter, Arrow keys
                                                        if (
                                                            event.key === 'Backspace' ||
                                                            event.key === 'Delete' ||
                                                            event.key === 'Tab' ||
                                                            event.key === 'Enter' ||
                                                            event.key === 'ArrowUp' ||
                                                            event.key === 'ArrowDown' ||
                                                            event.key === 'ArrowLeft' ||
                                                            event.key === 'ArrowRight'
                                                        ) {
                                                            return;
                                                        }

                                                        // Prevent non-numeric characters
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {errors?.mailing_address_postal && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.mailing_address_postal[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Mailing Address1 */}
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-box">
                                                <label>
                                                    Address Field 1
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={mailing_address1}
                                                    placeholder="Address"
                                                    onChange={(e) => set_mailing_address1(e.target.value)}
                                                />
                                                {errors?.mailing_address1 && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.mailing_address1[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* Mailing Address2 */}
                                        <div className="col-6">
                                            <div className="input-box">
                                                <label>
                                                    Address Field 2
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={mailing_address2}
                                                    placeholder="Address"
                                                    onChange={(e) => set_mailing_address2(e.target.value)}
                                                />
                                                {errors?.mailing_address2 && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.mailing_address2[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Home Address */}
                                <div style={{ marginTop: "20px" }}>
                                    <label className="cus-label">
                                        Home Address<i style={{ color: "red" }}>*</i>
                                    </label>
                                    <div className="row">
                                        {/* Country */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label>
                                                    Country<i style={{ color: "red" }}>*</i>
                                                </label>
                                                <CountryDropdown
                                                 disabled={true}
                                                    style={{ width: "100%", height: "40px" }}
                                                    value={home_country_address_country}
                                                    onChange={(value) =>
                                                        set_home_country_address_country(value)
                                                    }
                                                    className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined"
                                                />
                                                {errors?.home_country_address_country && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.home_country_address_country[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* City */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label>
                                                    City<i style={{ color: "red" }}>*</i>
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={home_country_address_city}
                                                    placeholder="City"
                                                    onChange={(e) =>
                                                        set_home_country_address_city(e.target.value)
                                                    }
                                                />
                                                {errors?.home_country_address_city && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.home_country_address_city[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* State */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label>
                                                    State<i style={{ color: "red" }}>*</i>
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={home_country_address_state}
                                                    placeholder="State"
                                                    onChange={(e) =>
                                                        set_home_country_address_state(e.target.value)
                                                    }
                                                />
                                                {errors?.home_country_address_state && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.home_country_address_state[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* Postal / ZIP Code */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label>
                                                    ZIP / Postal Code <i style={{ color: "red" }}>*</i>
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={home_country_address_postal}
                                                    placeholder="Zip/Postal"
                                                    onChange={(e) =>
                                                        set_home_country_address_postal(e.target.value)
                                                    }
                                                    onKeyDown={(event) => {
                                                        // Allow Backspace, Delete, Tab, Enter, Arrow keys
                                                        if (
                                                            event.key === 'Backspace' ||
                                                            event.key === 'Delete' ||
                                                            event.key === 'Tab' ||
                                                            event.key === 'Enter' ||
                                                            event.key === 'ArrowUp' ||
                                                            event.key === 'ArrowDown' ||
                                                            event.key === 'ArrowLeft' ||
                                                            event.key === 'ArrowRight'
                                                        ) {
                                                            return;
                                                        }

                                                        // Prevent non-numeric characters
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {errors?.home_country_address_postal && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.home_country_address_postal[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/*  Home Address1 */}
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-box">
                                                <label>
                                                    Address Field 1<i style={{ color: "red" }}>*</i>
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={home_country_address1}
                                                    placeholder="Address"
                                                    onChange={(e) => set_home_country_address1(e.target.value)}
                                                />
                                                {errors?.home_country_address1 && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.home_country_address1[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/*  Home Address2 */}
                                        <div className="col-6">
                                            <div className="input-box">
                                                <label>
                                                    Address Field 2
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={home_country_address2}
                                                    placeholder="Address"
                                                    onChange={(e) => set_home_country_address2(e.target.value)}
                                                />
                                                {errors?.home_country_address2 && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.home_country_address2[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <hr />
                                <br />
                                <div className="theme-content-left-head">
                                    <h3>Emergency Contact Details </h3>
                                </div>
                                <br />
                                {/* {Emergency Contact} */}
                                <div>
                                    <label className="cus-label">
                                        Emergency Contact
                                    </label>
                                    <div className="row">
                                        {/* Emergency First Name */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label>
                                                    First Name
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={emergency_first_name}
                                                    placeholder="First Name"
                                                    onChange={(e) => set_emergency_first_name(e.target.value)}
                                                />
                                                {errors?.emergency_first_name && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.emergency_first_name[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* Emergency Last Name */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label>
                                                    Last Name
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={emergency_last_name}
                                                    placeholder="Last Name"
                                                    onChange={(e) => set_emergency_last_name(e.target.value)}
                                                />
                                                {errors?.emergency_last_name && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.emergency_last_name[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        {/* Emergency Email */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label htmlFor="email">
                                                    Email
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={emergency_email}
                                                    id="email"
                                                    placeholder="example123@gmail.com"
                                                    onChange={(e) => set_emergency_email(e.target.value)}
                                                />
                                                {errors?.emergency_email && (
                                                    <>
                                                        <span style={{ color: "red" }}>{errors?.emergency_email[0]}</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* Emergency Phone Number */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label htmlFor="emegencyphone">
                                                    Phone Number
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    maxLength={12}
                                                    value={emergency_phone}
                                                    placeholder="Phone Number"
                                                    onKeyDown={(event) => {
                                                        // Allow Backspace, Delete, Tab, Enter, Arrow keys
                                                        if (
                                                            event.key === 'Backspace' ||
                                                            event.key === 'Delete' ||
                                                            event.key === 'Tab' ||
                                                            event.key === 'Enter' ||
                                                            event.key === 'ArrowUp' ||
                                                            event.key === 'ArrowDown' ||
                                                            event.key === 'ArrowLeft' ||
                                                            event.key === 'ArrowRight'
                                                        ) {
                                                            return;
                                                        }

                                                        // Prevent non-numeric characters
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    id="emegencyphone"
                                                    // onChange={(e) => set_emergency_phone(e.target.value)}
                                                    onChange={(e) => {
                                                        const phoneNumber = e.target.value;
                                                        // Remove non-digit characters from the input
                                                        const cleanedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
                                                        if (cleanedPhoneNumber.length <= 10) {
                                                            set_emergency_phone_error('');
                                                            // Format the phone number as 510-628-8022
                                                            let formattedPhoneNumber = '';
                                                            for (let i = 0; i < cleanedPhoneNumber.length; i++) {
                                                                if (i === 3 || i === 6) {
                                                                    formattedPhoneNumber += '-';
                                                                }
                                                                formattedPhoneNumber += cleanedPhoneNumber[i];
                                                            }
                                                            set_emergency_phone(formattedPhoneNumber);
                                                            set_emergency_phone_error('');
                                                        }
                                                        else {
                                                            set_emergency_phone(cleanedPhoneNumber)
                                                            set_emergency_phone_error("Phone Number Maximum Length Should be 10 Digit ")
                                                        }
                                                    }}
                                                />
                                                {
                                                    errors?.emergency_phone && (<><span style={{ color: "red" }}>{errors?.emergency_phone[0]}</span></>)
                                                    || emergencyphoneerror && (<><span style={{ color: "red" }}>{emergencyphoneerror}</span></>)

                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* Emergency Relationship */}
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label>
                                                    Relationship
                                                </label>
                                                <br></br>
                                                <Select
                                                 disabled={true}
                                                    value={emergency_relationship}
                                                    style={{ width: "100%", height: "40px" }}
                                                    onChange={(value) => set_emergency_relationship(value)}
                                                    placeholder="--Select Relationship--"
                                                >
                                                    <Select.Option value="father">Father</Select.Option>
                                                    <Select.Option value="mother">Mother</Select.Option>
                                                    <Select.Option value="brother">Brother</Select.Option>
                                                    <Select.Option value="sister">Sister</Select.Option>
                                                    <Select.Option value="other">Other</Select.Option>
                                                </Select>
                                                {errors?.emergency_relationship && (
                                                    <>
                                                        <span style={{ color: "red" }}>{errors?.emergency_relationship[0]}</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Emergency Address */}
                                <div>
                                    <label className="cus-label">
                                        Emergency Mailing Address
                                    </label>
                                    <div className="row">
                                        {/* Emergency country */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label htmlFor="emergencycountry">
                                                    Country
                                                </label>
                                                <CountryDropdown
                                                 disabled={true}
                                                    id="emergencycountry"
                                                    style={{ width: "100%", height: "40px" }}
                                                    placeholde="--Select Country--"
                                                    value={emergency_address_country}
                                                    onChange={(value) => set_emergency_address_country(value)}
                                                    className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                                                />
                                                {errors?.emergency_address_country && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.emergency_address_country[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* Emergency city */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label htmlFor="email">
                                                    City
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={emergency_address_city}
                                                    placeholder="City"
                                                    onChange={(e) => set_emergency_address_city(e.target.value)}
                                                />
                                                {errors?.emergency_address_city && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.emergency_address_city[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* Emergency state */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label htmlFor="email">
                                                    State
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={emergency_address_state}
                                                    placeholder="State"
                                                    onChange={(e) => set_emergency_address_state(e.target.value)}
                                                />
                                                {errors?.emergency_address_state && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.emergency_address_state[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* Emergency Postal Code */}
                                        <div className="col-3">
                                            <div className="input-box">
                                                <label htmlFor="email">
                                                    Zip / Postal Code
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={emergency_address_postal}
                                                    placeholder="Zip/Postal"
                                                    onChange={(e) =>
                                                        set_emergency_address_postal(e.target.value)
                                                    }
                                                    onKeyDown={(event) => {
                                                        // Allow Backspace, Delete, Tab, Enter, Arrow keys
                                                        if (
                                                            event.key === 'Backspace' ||
                                                            event.key === 'Delete' ||
                                                            event.key === 'Tab' ||
                                                            event.key === 'Enter' ||
                                                            event.key === 'ArrowUp' ||
                                                            event.key === 'ArrowDown' ||
                                                            event.key === 'ArrowLeft' ||
                                                            event.key === 'ArrowRight'
                                                        ) {
                                                            return;
                                                        }

                                                        // Prevent non-numeric characters
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {errors?.emergency_address_postal && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.emergency_address_postal[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Emergency Address 1*/}
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-box">
                                                <label>
                                                    Address Field 1
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={emergency_address1}
                                                    placeholder="Address"
                                                    onChange={(e) => set_emergency_address1(e.target.value)}
                                                />
                                                {errors?.emergency_address1 && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.emergency_address1[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* Emergency Address 2*/}
                                        <div className="col-6">
                                            <div className="input-box">
                                                <label>
                                                    Address Field 2
                                                </label>
                                                <Input
                                                 disabled={true}
                                                    value={emergency_address2}
                                                    placeholder="Address"
                                                    onChange={(e) => set_emergency_address2(e.target.value)}
                                                />
                                                {errors?.emergency_address2 && (
                                                    <>
                                                        <span style={{ color: "red" }}>
                                                            {errors?.emergency_address2[0]}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </>

                        {/* button */}
                        {/* <div className="row">
                        <div className="col-2">
                        <div className="input-box">
                            {loader ? (
                                <>
                                    <Button type="primary">
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#fff",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                            }
                                        />{" "}
                                        Saving
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                       //  onClick={() => { PERSONAL_INFORMATION_DRAFT_NEXT(2) }}  
                                        type="primary">
                                        Save
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                        </div> */}
                    </div>
                </>
            }
        </>
    );
}

export default PersonalInformation;