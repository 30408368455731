import React, { useEffect, useRef, useState } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import {
  ADD_SECTION,
  ADD_SEMESTER,
  EDIT_CALENDAR,
  UPDATE_CALENDAR,
} from "../../../apis/apis";
import { Button, DatePicker, Input, Select, Spin, notification } from "antd";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import dayjs from "dayjs";
const AddSemester = () => {
  // Date functions
  const dateFormat = 'MM-DD-YYYY';
  const DATE_CLASS = new Date();
  const current_month =
    DATE_CLASS.getMonth() < 9
      ? "0" + (DATE_CLASS.getMonth() + 1)
      : DATE_CLASS.getMonth();
  const today_date =
    DATE_CLASS.getFullYear() + "-" + current_month + "-" + DATE_CLASS.getDate();

  const { id } = useParams();
  const { RangePicker } = DatePicker;
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [sem_loader, set_sem_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [sem_errors, set_sem_errors] = useState([]);

  // Form State
  const [title, set_title] = useState("");
  const [description, set_description] = useState("");
  const [calendar_year, set_calendar_year] = useState("");
  const [calendar_start, set_calendar_start] = useState("");
  const [calendar_end, set_calendar_end] = useState("");

   
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if(localStorgeData.role === 2074){
        return true;
    }
    var check_permissions = [];
    if (type === 'view') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
  }
    if (type === 'create') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
        return true;
    } else {
        return false;
    }
}


  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);
    const EDIT_ROLE_API_RESPONSE = await EDIT_CALENDAR(FORM_DATA);
    if (EDIT_ROLE_API_RESPONSE.data.status) {
      set_title(EDIT_ROLE_API_RESPONSE?.data?.calendar?.title);
      set_calendar_start(
        EDIT_ROLE_API_RESPONSE?.data?.calendar?.year_start_date
      );
      set_calendar_end(EDIT_ROLE_API_RESPONSE?.data?.calendar?.year_end_date);
      set_description(EDIT_ROLE_API_RESPONSE?.data?.calendar?.description);
    } else {
      set_errors(EDIT_ROLE_API_RESPONSE.data.errors);
    }
  };

  const select_date = (date, dateString, type) => {
    if (type === "start_date") {
      set_calendar_start(dateString);
    } else {
      set_calendar_start(dateString);
    }
  };
  const UPDATE_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("title", title);
    FORM_DATA.append("description", editorRef.current.getContent());
    FORM_DATA.append("year_start_date", calendar_start);
    FORM_DATA.append("year_end_date", calendar_end);
    const API_RESPONSE = await UPDATE_CALENDAR(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Calendar Successfully Updated.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(false);
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  useEffect(() => {
    VIEW_API();
  }, []);

  const [sem_title, set_sem_title] = useState("");
  const [sem_start_date, set_sem_start_date] = useState("");
  const [sem_end_date, set_sem_end_date] = useState("");
  const [
    academic_advising_regi_start_date,
    set_academic_advising_regi_start_date,
  ] = useState("");
  const [academic_advising_regi_end_date, set_academic_advising_regi_end_date] =
    useState("");
  const [classes_begin_start_date, set_classes_begin_start_date] = useState("");
  const [classes_begin_end_date, set_classes_begin_end_date] = useState("");
  const [academic_penalty_start_date, set_academic_penalty_start_date] =
    useState("");
  const [academic_penalty_end_date, set_academic_penalty_end_date] =
    useState("");
  const [recess_start_date, set_recess_start_date] = useState("");
  const [recess_end_date, set_recess_end_date] = useState("");
  const [final_examinations_start_date, set_final_examinations_start_date] =
    useState("");
  const [final_examinations_end_date, set_final_examinations_end_date] =
    useState("");
  const [semesters_details, set_semesters_details] = useState("");

  const [input_1, set_input_1] = useState("");
  const [type_1, set_type_1] = useState("input");
  const [value_1, set_value_1] = useState("");

  const [input_2, set_input_2] = useState("");
  const [type_2, set_type_2] = useState("input");
  const [value_2, set_value_2] = useState("");

  const [input_3, set_input_3] = useState("");
  const [type_3, set_type_3] = useState("input");
  const [value_3, set_value_3] = useState("");

  const ADD_API = async () => {
    set_sem_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", atob(id));
    FORM_DATA.append("title", sem_title);
    FORM_DATA.append("start_date", sem_start_date);
    FORM_DATA.append("end_date", sem_end_date);
    FORM_DATA.append(
      "academic_advising_regi_start_date",
      academic_advising_regi_start_date
    );
    FORM_DATA.append(
      "academic_advising_regi_end_date",
      academic_advising_regi_end_date
    );
    FORM_DATA.append("classes_begin_start_date", classes_begin_start_date);
    FORM_DATA.append("classes_begin_end_date", classes_begin_end_date);
    FORM_DATA.append("academic_penalty_end_date", academic_penalty_end_date);
    FORM_DATA.append("recess_start_date", recess_start_date);
    FORM_DATA.append("recess_end_date", recess_end_date);
    FORM_DATA.append(
      "final_examinations_start_date",
      final_examinations_start_date
    );
    FORM_DATA.append(
      "final_examinations_end_date",
      final_examinations_end_date
    );
    FORM_DATA.append(
      "semesters_details",
      JSON.stringify([
        {
          input_1: input_1,
          type_1: type_1,
          value_1: value_1,

          input_2: input_2,
          type_2: type_2,
          value_2: value_2,

          input_3: input_3,
          type_3: type_3,
          value_3: value_3,
        },
      ])
    );
    const API_RESPONSE = await ADD_SEMESTER(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Semester Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/calendar-semester/" + id);
    } else {
      set_sem_errors(API_RESPONSE.data.errors);
      set_sem_loader(false);
    }
  };
  return (
    <>
      <div>
        <div className="theme-content-head">
          <div className="theme-content-left-head">
            <h3>Academic Calendar ({title})</h3>
          </div>
          <div className="theme-content-right-head"></div>
        </div>
        <div className="sis-tab-menu">
        {check_permissions('academic_calendars', 'view') &&
          <span onClick={() => navigate("/edit-calendar/" + id)}>
            Academic Calendar
          </span>}
          {check_permissions('academic_semesters', 'view') &&   <span className="active"  onClick={() => navigate("/calendar-semester/" + id)}>Semesters</span>}
          {check_permissions('calendars_schedules', 'view') && <span onClick={() => navigate("/calendar-schedule/" + id)}>
            Schedules
          </span>}
        </div>
        <hr style={{ margin: "20px 0px" }} />
        <div className="semester-form">
          <h3>Add Semester</h3>
          <div className="input-box">
            <label htmlFor="name">
              Semester Title<i style={{ color: "red" }}>*</i>
            </label>
            <Input
              value={sem_title}
              placeholder="Title"
              id="title"
              onChange={(e) => set_sem_title(e.target.value)}
            />
            {sem_errors?.title && (
              <>
                <span style={{ color: "red" }}>{sem_errors?.title[0]}</span>
              </>
            )}
          </div>
          <div className="row input-box">
            <div className="col-6">
              <label htmlFor="name">
                Semester Start Date<i style={{ color: "red" }}>*</i>
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) => set_sem_start_date(dateString)}
                style={{ width: "100%" }}
              />
              {sem_errors?.start_date && (
                <>
                  <span style={{ color: "red" }}>
                    {sem_errors?.start_date[0]}
                  </span>
                </>
              )}
            </div>
            <div className="col-6">
              <label htmlFor="name">
                Semester End Date<i style={{ color: "red" }}>*</i>
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) => set_sem_end_date(dateString)}
                style={{ width: "100%" }}
              />
              {sem_errors?.end_date && (
                <>
                  <span style={{ color: "red" }}>
                    {sem_errors?.end_date[0]}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="row input-box">
            <div className="col-6">
              <label htmlFor="name">
                Academic advising and registration Start Date
                
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) =>
                  set_academic_advising_regi_start_date(dateString)
                }
                style={{ width: "100%" }}
              />
              {sem_errors?.academic_advising_regi_start_date && (
                <>
                  <span style={{ color: "red" }}>
                    {sem_errors?.academic_advising_regi_start_date[0]}
                  </span>
                </>
              )}
            </div>
            <div className="col-6">
              <label htmlFor="name">
                Academic advising and registration End Date
                
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) =>
                  set_academic_advising_regi_end_date(dateString)
                }
                style={{ width: "100%" }}
              />
              {errors?.academic_advising_regi_end_date && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.academic_advising_regi_end_date[0]}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="row input-box">
            <div className="col-6">
              <label htmlFor="name">
                Last day classes may be added/dropped without academic penalty
                
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) =>
                  set_academic_penalty_end_date(dateString)
                }
                style={{ width: "100%" }}
              />
              {sem_errors?.academic_penalty_end_date && (
                <>
                  <span style={{ color: "red" }}>
                    {sem_errors?.academic_penalty_end_date[0]}
                  </span>
                </>
              )}
            </div>
            {/* <div className='col-6'>
                            <label htmlFor="name">Last day classes may be added/dropped without academic penalty End Date</label>
                            <DatePicker format={dateFormat}  minDate={dayjs(calendar_start)} onChange={(date, dateString) => set_academic_penalty_end_date(dateString)} style={{ width: "100%" }} />
                            {sem_errors?.academic_penalty_end_date && <><span style={{ color: "red" }}>{sem_errors?.academic_penalty_end_date[0]}</span></>}
                        </div> */}
          </div>
          <div className="row input-box">
          <div className="col-3">
              <label htmlFor="name">
                Classes begin
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) =>
                  set_classes_begin_start_date(dateString)
                }
                style={{ width: "100%" }}
              />
              {sem_errors?.classes_begin_start_date && (
                <>
                  <span style={{ color: "red" }}>
                    {sem_errors?.classes_begin_start_date[0]}
                  </span>
                </>
              )}
            </div>
            <div className="col-3">
              <label htmlFor="name">
                Recess Start Date
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) =>
                  set_recess_start_date(dateString)
                }
                style={{ width: "100%" }}
              />
              {sem_errors?.recess_start_date && (
                <>
                  <span style={{ color: "red" }}>
                    {sem_errors?.recess_start_date[0]}
                  </span>
                </>
              )}
            </div>
            <div className="col-3">
              <label htmlFor="name">
                Recess End Date
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) => set_recess_end_date(dateString)}
                style={{ width: "100%" }}
              />
              {sem_errors?.recess_end_date && (
                <>
                  <span style={{ color: "red" }}>
                    {sem_errors?.recess_end_date[0]}
                  </span>
                </>
              )}
            </div>
            
            <div className="col-3">
              <label htmlFor="name">
                Classes Resume
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) =>
                  set_classes_begin_end_date(dateString)
                }
                style={{ width: "100%" }}
              />
              {sem_errors?.classes_begin_end_date && (
                <>
                  <span style={{ color: "red" }}>
                    {sem_errors?.classes_begin_end_date[0]}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="row input-box">
            <div className="col-6">
              <label htmlFor="name">
                Final Examinations Start Date
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) =>
                  set_final_examinations_start_date(dateString)
                }
                style={{ width: "100%" }}
              />
              {sem_errors?.final_examinations_start_date && (
                <>
                  <span style={{ color: "red" }}>
                    {sem_errors?.final_examinations_start_date[0]}
                  </span>
                </>
              )}
            </div>
            <div className="col-6">
              <label htmlFor="name">
                Final Examinations End Date
              </label>
              <DatePicker format={dateFormat} 
                minDate={dayjs(calendar_start)}
                onChange={(date, dateString) =>
                  set_final_examinations_end_date(dateString)
                }
                style={{ width: "100%" }}
              />
              {sem_errors?.final_examinations_end_date && (
                <>
                  <span style={{ color: "red" }}>
                    {sem_errors?.final_examinations_end_date[0]}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="">Custom fields</label>
          </div>
          <div className="row input-box">
            <div className="col-4">
              <Input
                placeholder="Field Name"
                onChange={(e) => set_input_1(e.target.value)}
              />
            </div>
            <div className="col-4">
              <Select
                style={{ width: "100%" }}
                placeholder="Data type"
                defaultValue={"input"}
                onChange={(value) => set_type_1(value)}
                options={[
                  { value: "input", label: "input" },
                  { value: "date", label: "date" },
                ]}
              />
            </div>
            {type_1 === "input" ? (
              <>
                <div className="col-4">
                  <Input
                    onChange={(e) => set_value_1(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-4">
                  <DatePicker format={dateFormat} 
                    minDate={dayjs(calendar_start)}
                    onChange={(date, dateString) => set_value_1(dateString)}
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            )}
          </div>
          <br></br>
          <div className="row input-box">
            <div className="col-4">
              <Input
                placeholder="Field Name"
                onChange={(e) => set_input_2(e.target.value)}
              />
            </div>
            <div className="col-4">
              <Select
                style={{ width: "100%" }}
                placeholder="Data type"
                defaultValue={"input"}
                onChange={(value) => set_type_2(value)}
                options={[
                  { value: "input", label: "input" },
                  { value: "date", label: "date" },
                ]}
              />
            </div>
            {type_2 === "input" ? (
              <>
                <div className="col-4">
                  <Input
                    onChange={(e) => set_value_2(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-4">
                  <DatePicker format={dateFormat} 
                    minDate={dayjs(calendar_start)}
                    onChange={(date, dateString) => set_value_2(dateString)}
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            )}
          </div>
          <br></br>
          <div className="row input-box">
            <div className="col-4">
              <Input
                placeholder="Field Name"
                onChange={(e) => set_input_3(e.target.value)}
              />
            </div>
            <div className="col-4">
              <Select
                style={{ width: "100%" }}
                placeholder="Data type"
                defaultValue={"input"}
                onChange={(value) => set_type_3(value)}
                options={[
                  { value: "input", label: "input" },
                  { value: "date", label: "date" },
                ]}
              />
            </div>
            {type_3 === "input" ? (
              <>
                <div className="col-4">
                  <Input
                    onChange={(e) => set_value_3(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-4">
                  <DatePicker format={dateFormat} 
                    minDate={dayjs(calendar_start)}
                    onChange={(date, dateString) => set_value_3(dateString)}
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            )}
          </div>
          {check_permissions('academic_semesters', 'create') &&
          <div style={{ textAlign: "right" }} className="input-box">
            {sem_loader ? (
              <>
                <Button type="primary" className="lusisbtn" style={{width:"156px"}}>
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: "12px",
                          color: "#fff",
                          marginRight: "5px",
                        }}
                      />
                    }
                  />{" "}
                  Add Semester
                </Button>
              </>
            ) : (
              <>
                <Button className="lusisbtn" style={{width:"156px"}} type="primary" onClick={ADD_API}>
                  Add Semester
                </Button>
              </>
            )}
          </div>}
        </div>
      </div>
    </>
  );
};

export default AddSemester;
