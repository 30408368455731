
import React from 'react'

const MultipleCoursePrint = ({ student_by_semester_courses_detail, report_type }) => {
  return (
    <>
      {student_by_semester_courses_detail?.length > 0 && (
        <div className="scrollable-course-details">
          {student_by_semester_courses_detail?.map((item) => (
            <ul key={item?.course?.code}>
              <li>
                <p style={{ margin: "0 0 10px 0" }}>
                  <span>
                    <b>Course :-</b> {item?.course?.title} {" "}{"("}{item?.course?.code}{")"}
                    <br />
                  </span>
                  <span>
                    <b>Given Credit :-</b> {item?.course_semester_registration?.given_credits}
                  </span>
                </p>
              </li>
            </ul>
          ))}
        </div>
      )}
    </>
  );
}

export default MultipleCoursePrint;