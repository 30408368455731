import { DeleteOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Select, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import {
  ADD_UPDATE_ATTENDANCE,
  ATTENDANCE_CANCEL,
  ATTENDANCE_VIEW,
  LIST_ATTENDANCE_CATEGORY,
  REASONS_DELETE,
  REASONS_LIST,
  REASONS_UPDATE,
  SIS_STORAGE,
  VIEW_REASON,
} from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import { useNavigate } from "react-router-dom";

export default function RecordAttendanceViewRow({
  student,
  semester_id,
  course_id,
  faculty_id,
  start_time,
  end_time,
  selected_date,
  student_id,
  set_view_class_checkbox
}) {
  const navigate = useNavigate();

  const [row_loader, set_row_loader] = useState(true);
  const [student_attendance, set_student_attendance] = useState();
  const [errors, set_errors] = useState([]);
  const [attendance, set_attendance] = useState("");
  const [reason_cat, set_reason_cat] = useState("");
  const [cancelled, set_cancelled] = useState(false);
  const [reason_list, set_reason_list] = useState([]);
  const [show_reason, set_show_reason] = useState(false);
  const [reason, set_reason] = useState("");
  const [comment, set_comment] = useState("");
  const [cat_list, set_cat_list] = useState([]);


  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if(localStorgeData.role === 2074){
        return true;
    }
    var check_permissions = [];
    if (type === 'view') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
  }
    if (type === 'create') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
        return true;
    } else {
        return false;
    }
}



  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("faculty_id", faculty_id);
    FORM_DATA.append("selected_date", selected_date);
    FORM_DATA.append("student_id", student_id);
    const RESPONSE = await ATTENDANCE_VIEW(FORM_DATA);
    if (RESPONSE?.data?.status) {
      const attendance= RESPONSE?.data?.attendance;
      if(attendance){
        set_reason_cat(attendance.reason_cat)
        if(attendance.reason_cat){
          REASON_LIST_API(attendance.reason_cat)
          set_reason(attendance.reason)
        }
        set_comment(attendance.comment)
        if(attendance.cancelled){
          set_view_class_checkbox(true)
        }
        set_cancelled(attendance.cancelled)
      }
      set_row_loader(false);
    } else {
      set_errors(RESPONSE.data.errors);
      set_row_loader(false);
    }
  };
  const CAT_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const RESPONSE = await LIST_ATTENDANCE_CATEGORY(FORM_DATA);
    if (RESPONSE?.data?.status) {
      set_cat_list(RESPONSE?.data?.categories);
      if (RESPONSE?.data?.categories.length > 0) {
        set_show_reason(true);
      }
    } else {
    }
  };
  const REASON_LIST_API = async (id) => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("attendance_category_id", id);
    const API_RESPONSE = await REASONS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      var data = API_RESPONSE?.data?.attendance_reasons?.filter(item => item.reason_title)
      set_reason_list(data);
      set_show_reason(true);
    } else {
    }
  };
  useEffect(() => {
    VIEW_API();
    CAT_LIST_API();
  }, [row_loader]);

  const category_change = (cat_id) => {
    set_reason_cat(cat_id);
    set_reason('');
    REASON_LIST_API(cat_id);
  };
  const ADD_UPDATE_API = async () => {
    
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("selected_date", selected_date);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("start_time", start_time);
    FORM_DATA.append("end_time", end_time);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("reason_cat", reason_cat);
    FORM_DATA.append("reason", reason);
    FORM_DATA.append("faculty_id", faculty_id);
    FORM_DATA.append("comment", comment);
    const API_RESPONSE = await ADD_UPDATE_ATTENDANCE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Attendance saved Successfully.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_errors([]);
      set_row_loader(true);
    } else {
      set_errors(API_RESPONSE.data.errors);
    }
  };

 

  return (
    <>
      {row_loader ? (
        <p style={{ textAlign: "center", padding: "10px" }}>
          <Spin />
        </p>
      ) : (
        <>
         {check_permissions('attendances', 'update') &&
          <div className="row" style={{marginTop:"10px", marginBottom:"10px"}}>
            <div className="col-3">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "10px",
                    marginRight: "10px",
                  }}
                  src={SIS_STORAGE + "/enrollments/" + student.image}
                  alt="Profile Picture"
                />
                <label>
                  {" "}
                  <span>
                    {student.first_name}{" "}
                    {student.middle_name === "null" ? " " : student.middle_name}{" "}
                    {student.last_name}
                  </span>
                </label>
              </div>
            </div>
            <div className="col-3">
              {cat_list.length > 0 ? (
                <>
                  <Select
                    style={{ width: "100%" }}
                    value={reason_cat}
                    placeholder="Mark Attendance"
                    onChange={(value) => category_change(value)}
                    disabled={student.edit_access == 0 ? true : cancelled}
                  >
                    {cat_list.map((item) => (
                      <>
                        <Select.Option value={item.id}>
                          {item.title}
                        </Select.Option>
                      </>
                    ))}
                  </Select>
                  {errors?.reason_cat && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.reason_cat[0]}
                      </span>
                    </>
                  )}
                </>
              ) : (
                <>
                  <span>List Not Found</span>
                </>
              )}
            </div>
            <div className="col-2">
              {show_reason ? (
                <>
                  {reason_list.length > 0 ? (
                    <>
                      <Select
                        style={{ width: "100%" }}
                        value={cancelled ? 'Present' : reason}
                        placeholder="Mark Attendance"
                        onChange={(value) => set_reason(value)}
                        disabled={student.edit_access == 0 ? true : cancelled}
                      >
                        {reason_list.map((item) => (
                          <>
                            <Select.Option value={item.id}>
                              {item.reason_title}
                            </Select.Option>
                          </>
                        ))}
                      </Select>
                      {errors?.reason && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.reason[0]}
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Select style={{width:"100%"}} placeholder="List Not Found" disabled></Select>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Select
                  style={{width:"100%"}}
                    placeholder="Select Mark Attendance First"
                    disabled
                  ></Select>
                </>
              )}
            </div>
            <div className="col-2">
              <Input
                style={{ height: "32px" }}
                placeholder=" Comment....."
                value={comment}
                onChange={(e) => set_comment(e.target.value)}
                disabled={student.edit_access == 0 ? true : cancelled}
              />
              {errors?.comment && (
                <>
                  <span style={{ color: "red" }}>{errors?.comment[0]}</span>
                </>
              )}
            </div>
            <div className="col-1">
              {cancelled ? <>
                <Button type="primary" disabled danger>
                Class Canceled
              </Button>
              </> : <>
              <Button disabled={student.edit_access == 0 ? true : false} type="primary" onClick={ADD_UPDATE_API} style={{ marginRight: "5px" }}>
                Save
              </Button>
              </>}
            
              {/* <Popconfirm
              title="Student Attendance"
              description="Are you sure to update Student Attendance Successfully"
              onConfirm={() => {

              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" size="small">
                <DeleteOutlined />
              </Button>
            </Popconfirm> */}
            </div>
          </div>}
        </>
      )}
    </>
  );
}
