import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { CALENDARS_LIST, ACADEMIC_REPORT_GRAPH } from '../../apis/apis';
import { Select } from 'antd';

const AcademicGraphReports = () => {
  const [loader, set_loader] = useState(false);
  const [academic_year, set_academic_year] = useState("");
  const [errors, set_errors] = useState([]);
  const [academic_year_list, set_academic_year_list] = useState([]);
  const [semester, set_semester] = useState("");
  const [semesterName, setSemesterName] = useState("");
  const [sem_list, set_sem_list] = useState([]);
  const [calender_index, set_calender_index] = useState(0);
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  });

  const ACADEMIC_REPORT_GRAPH_VIEW = async (sem_id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('semester_id', sem_id);
    const API_RESPONSE = await ACADEMIC_REPORT_GRAPH(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      //const data = API_RESPONSE.data;
      setGraphData(API_RESPONSE?.data)
    }
    set_loader(false);
  };

  const LIST_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      const calendars = API_RESPONSE?.data?.calendars;
      set_academic_year_list(calendars);

      if (calendars.length > 0) {
        set_academic_year(calendars[0].id);
        set_sem_list(calendars[0].semesters);
        if (calendars[0].semesters.length > 0) {
          set_semester(calendars[0].semesters[0].id);
          setSemesterName(calendars[0].semesters[0].title); 
          ACADEMIC_REPORT_GRAPH_VIEW(calendars[0].semesters[0].id);
        }
      }
    }
    set_loader(false);
  };

  const calender_change_function = (value) => {
    set_semester('');
    setSemesterName('');
    set_errors('');
    const index = academic_year_list.findIndex(item => item.id === value);
    set_academic_year(value);
    set_calender_index(index);
    set_sem_list(academic_year_list[index].semesters);
  };

  useEffect(() => {
    LIST_API();
  }, []);

  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const combinedChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: graphData.programmes,
        datasets: [
          {
            label: 'Enrolled Students',
            data: graphData.enrolled_students,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
          },
          {
            label: 'Registered Students',
            data: graphData.register_students,
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
          },
          {
            label: 'New Students',
            data: graphData.new_students,
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
          },
          {
            label: 'Returning Students',
            data: graphData.returning_students,
            backgroundColor: 'rgba(255, 206, 86, 0.6)',
          },
          {
            label: 'Transfer In',
            data: graphData.transfer_in,
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
          },
          {
            label: 'Transfer Out',
            data: graphData.transfer_out,
            backgroundColor: 'rgba(255, 159, 64, 0.6)',
          },
          {
            label: 'Withdraw Out',
            data: graphData.withdraw_out,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
          },
          {
            label: 'Part Time',
            data: graphData.part_time,
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
          },
          {
            label: 'Full Time',
            data: graphData.full_time,
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
          },
          {
            label: 'Faculties',
            data: graphData.faculties,
            backgroundColor: 'rgba(255, 206, 86, 0.6)',
          },
        ]
      },
      options: {
        plugins: {
          legend: {
            position: 'top',
            labels: {
              boxWidth: 12, // Set your desired width for the color bar here
              font: {
                size: 12 // Set your desired font size here
            }
          }
          },
          title: {
            display: true,
            // text: 'Student Academic Report',
          },
        },
        responsive: true,
        scales: {
          x: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Program Code',
              font: {
                size: 14,
                weight: 'bold',
                family: 'Arial',
                color: '#000'
              }
            }
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'No. of Students',
              font: {
                size: 14,
                weight: 'bold',
                family: 'Arial',
                color: '#000'
              }
            }
          },
        },
      },
    });

    return () => {
      combinedChart.destroy();
    };
  }, [graphData]);

  return (
    <div>
      <h1> Academic Reports</h1>
      <div className="row">
        <div style={{ width: "100%", float: 'right' }}>
          <div className="col-6">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Select Academic Year<i style={{ color: "red" }}>*</i>
              </label>
              <br />
              <Select
                placeholder="--Select Academic Year--"
                style={{ width: "100%" }}
                onChange={calender_change_function}
                value={academic_year ? parseInt(academic_year) : academic_year}
                options={academic_year_list?.map((item) => ({
                  value: item?.id,
                  calender_id: item?.id,
                  label: item?.title,
                }))}
              />
              {errors?.entering_year && (
                <>
                  <span style={{ color: "red" }}>
                    Please Select Academic Year
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Select Semester<i style={{ color: "red" }}>*</i>
              </label>
              <br />
              <Select
                placeholder="--Select Semester--"
                style={{ width: "100%" }}
                disabled={!academic_year}
                onChange={(value, option) => {
                  set_semester(value);
                  setSemesterName(option.label);
                  ACADEMIC_REPORT_GRAPH_VIEW(value);
                }}
                value={semester}
                options={sem_list?.map((item) => ({
                  value: item?.id,
                  label: item?.title,
                }))}
              />
              {errors?.semester_id && (
                <>
                  <span style={{ color: "red" }}>
                    The semester field is required.
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      {semesterName &&
      <div className='row'style={{ display: 'flex', justifyContent: 'center'}}>
        <h4 style={{ color:'#777'}}>{"Student Academic Report For "}{semesterName}</h4>
      </div>
      }
      <div style={{ width: '100%', margin: '0 auto' }}>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default AcademicGraphReports;
