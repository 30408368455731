import React, { useEffect, useState } from "react";
// import { Button, Popconfirm, Table, notification, Tooltip } from 'antd';
import "./style.css";
import {
  TableColumnsType,
  Button,
  TableProps,
  Popconfirm,
  Table,
  notification,
  Input,
  Tooltip,
  Badge,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  USERS_LIST,
  STATUS_USER,
  DELETE_USER,
  STATUS_STUDENT,
  DELETE_STUDENT,
  ENROLLMENTS_LIST,
  STUDENTS_ENROLLMENTS_LIST,
} from "../../apis/apis";
import {
  DeleteOutlined,
  FilePdfOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  SmileOutlined,
  StopOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import SisLoader from "../../widgets/loader";
import { BACKEND_URL } from "../../config/config";

const StudentsList = () => {
  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [popStatusDescription, set_popStatusDescription] = useState(
    "Do you want to change Status of Student"
  );
  const [application_count, set_application_count] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Form State
  // const [title, set_title] = useState('');
  const [table_list, set_table_list] = useState([]);
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === 'create') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  // Search
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = table_list.filter(
      (item) =>
        item.first_name.toLowerCase().includes(query.toLowerCase()) ||
        item.phone
          .toString()
          .toLowerCase()
          .includes(query.toString().toLowerCase()) ||
        item.primary_email
          .toString()
          .toLowerCase()
          .includes(query.toString().toLowerCase()) ||
        item.student_status
          .toString()
          .toLowerCase()
          .includes(query.toString().toLowerCase()) ||
        item.application_no
          .toString()
          .toLowerCase()
          .includes(query.toString().toLowerCase())
    );
    if (query === "") {
      setFilteredData(table_list);
    } else {
      setFilteredData(filtered);
    }
  };

  // Status Api
  const STATUS_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await STATUS_STUDENT(FORM_DATA);
    if (API_RESPONSE.data.status) {
      if (API_RESPONSE.data.type === "yes") {
        notification.open({
          message: "Success!!",
          description: API_RESPONSE.data.message,
          icon: <SmileOutlined style={{ color: "green" }} />,
        });
      } else {
        notification.open({
          message: "Note!!",
          description: API_RESPONSE.data.message,
          icon: <SmileOutlined style={{ color: "yellow" }} />,
        });
      }

      navigate("/registration-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };

  // delete api
  const DELETE_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await DELETE_STUDENT(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student Successfully deleted.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      console.log(API_RESPONSE);
      navigate("/registration-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };

  // handle status Text
  const handleStatusText = (id, status) => {
    if (status === 1) {
      set_popStatusDescription("Do you want to make this student Inactive");
    } else {
      set_popStatusDescription("Do you want to make this student Active");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Application ID",
      dataIndex: "application_no",
      key: "application_no",
      sorter: (a, b) => a.application_no.localeCompare(b.application_no),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "primary_email",
      key: "primary_email",
    },
    {
      title: "Student Status",
      dataIndex: "student_status",
      render: (text, record) => {
        if (record.student_status == 1) {
          return 'Ready for Programme Registration';
        }
        if (record.student_status == 2) {
          return 'Enrolled';
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        if (record.status) {
          return <span className="table-status-activate">Active</span>;
        } else {
          return <span className="table-status-deactivate">Inactive</span>;
        }
      },
    },
    {
      title: "Action",
      key: "Action",
      render: (text, record) => {
        return (
          <>
            {check_permissions('enrollments', 'status') &&
              <Popconfirm
                title="Change Status"
                description={popStatusDescription}
                onConfirm={() => {
                  STATUS_API(btoa(record.id));
                }}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  size="small"
                  style={{ backgroundColor: "#888888", marginRight: "5px" }}
                  onChange={() => handleStatusText(record.id, record.status)}
                >
                  <div className="tooltip">
                    <StopOutlined />
                    <span className="tooltiptext">Status Change</span>
                  </div>
                </Button>
              </Popconfirm>}
            {check_permissions('enrollments', 'update') &&
              <Button
                type="primary"
                size="small"
                onClick={() => navigate("/edit-registration/" + btoa(record.id))}
                style={{ marginRight: "5px" }}
              >
                <div className="tooltip">
                  <EditOutlined />
                  <span className="tooltiptext">Edit student View</span>
                </div>
              </Button>}
            {check_permissions('enrollments', 'delete') &&
              <Popconfirm
                title="Delete Student"
                description="Are you sure to delete this student?"
                onConfirm={() => {
                  DELETE_API(btoa(record.id));
                }}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" size="small" danger>
                  <div class="tooltip">
                    {" "}
                    <DeleteOutlined /> <span class="tooltiptext">
                      Delete
                    </span>{" "}
                  </div>
                </Button>
              </Popconfirm>}
          </>
        );
      },
    },
  ];
  // DB list
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await STUDENTS_ENROLLMENTS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_application_count(API_RESPONSE?.data?.new_register_count);
      set_table_list(API_RESPONSE?.data?.enrollments);
      setFilteredData(API_RESPONSE?.data?.enrollments);
      set_loader(false);
    } else {
      set_loader(false);
    }
    console.log(API_RESPONSE);
  };
  useEffect(() => {
    LIST_API();
  }, [loader]);

  return (
    <>
    {loader ? (
      <SisLoader />
    ) : (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Enrollment List</h3>
        </div>
        <div className="theme-content-right-head">
          {check_permissions('enrollments', 'create') &&
            <>
              <Button  className="lusisbtn" type="primary" onClick={() => navigate("/student-online-application-list")}
              style={ loader ? {marginRight:"10px",width:"177px"} : 
                (application_count == 0 ? {marginRight:"10px", width:"177px"} : {marginRight:"-8px",width:"177px"})}
              >
                Online Application
              </Button>
              {application_count > 0 &&
                <Badge
                  offset={[-6, -28]}
                  color="red"
                  count={application_count}
                >
                </Badge>
              }
              <Button className="lusisbtn" style={{marginRight:"5px",width:"177px"}}
              type="primary" onClick={() => navigate("/add-registration")}><PlusOutlined/>
                New Enrollment
              </Button>
            </>

          }

          <Tooltip title="Download enrollment  List CSV">
            <Button
              type="primary"
              onClick={() =>
              (window.location =
                BACKEND_URL +
                "/download-enrollments/" +
                JSON.parse(localStorage.getItem("sis_user_data")).token)
              }
              ghost
              style={{ marginLeft: "5px" }}
            >
              <CloudDownloadOutlined />
            </Button>
          </Tooltip>

          {/* PDF Download button  */}
          <Tooltip title="Download enrollment List PDF">
            <Button
              type="primary"
              onClick={() =>
              (window.location =
                BACKEND_URL +
                "/download-enrollment-pdf/" +
                JSON.parse(localStorage.getItem("sis_user_data")).token)
              }
              ghost
              style={{ marginLeft: "5px" }}
            >
              <FilePdfOutlined />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="theme-content-head">
        <div className="input-box">
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
          {errors?.code && (
            <span style={{ color: "red" }}>{errors?.code[0]}</span>
          )}
        </div>
      </div>
        <Table columns={columns} dataSource={filteredData} />
     
    </div>
  )}
  </>
  );
};

export default StudentsList;
