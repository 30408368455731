import React, { useEffect, useState } from "react";
import "./style.css";
import { Button, DatePicker, Input, InputNumber, Select, notification } from "antd";
import {
  COURSES_LIST,
  TRANSFERRED_COURSE_ADD,
  TRANSFERRED_COURSE_DELETE,
  TRANSFERRED_COURSE_LIST,
  TRANSFERRED_COURSE_UPDATE,
} from "../../../apis/apis";
import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined, SmileOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import SisLoader from "../../../widgets/loader";
export default function SingleTransferredCourse(props) {
  const {
    acad_calender_id,
    student_id,
    semester_id,
    sem_reg_id,
    set_sem_reg_id,
    set_transferred_Course,
    transferred_course,
    set_loader_change,
    set_main_page_loader,
    set_loader,
  } = props;
  const [page_loader, set_page_loader] = useState(true)
  const dateFormat = 'MM-DD-YYYY';
  const [courses, set_courses] = useState([]);
  const [table_list_2, set_table_list_2] = useState([]);
  const [course_id, set_course_id] = useState('');
  const [credit, set_credit] = useState(0);
  const [transferred_institution, set_transferred_institution] = useState('');
  const [api_transferred_course, set_api_transferred_course] = useState('');
  const [transferred_semester, set_transferred_semester] = useState('');
  const [begin_date, set_begin_date] = useState('');
  const [end_date, set_end_date] = useState('');
  const [credit_attempted, set_credit_attempted] = useState('');
  const [credit_earn, set_credit_earn] = useState('');
  const [errors, set_errors] = useState([]);

  const COURSES_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await COURSES_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      var filteredData = API_RESPONSE?.data?.courses.filter(
        (item) => item.soft_delete === 0
      );
      set_table_list_2(filteredData);
      set_loader(false);
      set_page_loader(false)
    } else {
      set_loader(false);
    }
  };
  const DELETE_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await TRANSFERRED_COURSE_DELETE(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Transfer course removed successfully!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(false);
      set_main_page_loader(true)
    } else {
      set_loader(false);
    }
  };
  const set_course_id_and_credit = (id) =>{
    
      const course_row = table_list_2.filter(row => row.id === parseInt(id));
     
      set_course_id(course_row[0].id);
      set_credit(course_row[0].units);
  }
  const UPDATE_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", transferred_course.id);
    FORM_DATA.append("acad_calender_id", transferred_course.acad_calender_id);
    FORM_DATA.append("semester_id", transferred_course.semester_id);
    FORM_DATA.append("student_id", transferred_course.student_id);
    FORM_DATA.append("semester_registration_id", transferred_course.semester_registration_id);
    FORM_DATA.append("course_id", course_id == "null" || course_id == null ? "" : course_id);
    FORM_DATA.append("credit", credit == "null" || credit == null ? "" : credit);
    FORM_DATA.append("transferred_institution",  transferred_institution == "null" || transferred_institution == null ? "" : transferred_institution);
    FORM_DATA.append("transferred_semester", transferred_semester == "null" || transferred_semester == null ? "" : transferred_semester);
    FORM_DATA.append("transferred_course", api_transferred_course == "null" || api_transferred_course == null ? "" : api_transferred_course);
    FORM_DATA.append("begin_date", begin_date == "null" || begin_date == null ? "" : begin_date);
    FORM_DATA.append("end_date", end_date == "null" || end_date == null ? "" : end_date);
    FORM_DATA.append("credit_attempted",  credit_attempted == "null" || credit_attempted == null ? "" : credit_attempted);
    FORM_DATA.append("credit_earn", credit_earn == "null" || credit_earn == null ? "" :  credit_earn);
    const API_RESPONSE = await TRANSFERRED_COURSE_UPDATE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Transfer course updated successfully !",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });

      set_loader(false);
      set_main_page_loader(true)
    } else {
      set_errors(API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  };
  useEffect(() => {
    COURSES_LIST_API();
  }, []);
  useEffect(() => {
     set_course_id(transferred_course.course_id);
     set_api_transferred_course(transferred_course.transferred_course);
     set_credit(transferred_course.credit);
     set_begin_date(transferred_course.begin_date);
     set_transferred_institution(transferred_course.transferred_institution);
     set_transferred_semester(transferred_course.transferred_semester);
     set_credit_attempted(transferred_course.credit_attempted)
     set_end_date(transferred_course.end_date);
     set_credit_earn(transferred_course.credit_earn);
     
  }, []);
  
console.log('transferred_course', course_id)
  
  return (
    <>
    {page_loader ? <SisLoader /> : <>
      <div className="TransferredCourse-row" style={{marginBottom:"25px"}}>
        <div className="input-box" style={{ maxWidth:"150px", position:"relative"}} >
          <label>LU Course</label><i style={{ color: "red" }}>*</i>
          <Select
            placeholder="Select Courses"
            showSearch={true}
            style={{ width: "100%",  height:"40px" }}
          
            filterOption={(inputValue, option) => {
              if(!option.code.toLowerCase().indexOf(inputValue.toLowerCase())){
                return !option.code.toLowerCase().indexOf(inputValue.toLowerCase())
            }else{
                return !option.title.toLowerCase().indexOf(inputValue.toLowerCase())
            }
            }}
            onChange={(value) => set_course_id_and_credit(value)}
            value={course_id}
            options={table_list_2.map((item) => ({
              value: item.id,
              title: item.title,
              label: item.title + "(" + item.code + ")",
              code: item.code
            }))}
          ></Select>
          {errors?.course_id &&
        <span style={{color:"red",fontSize:"9px", position:"absolute", left:"0px", bottom:"-12px"}}>{errors?.course_id[0]}</span>
        }
        </div>
        <div className="input-box" style={{ maxWidth:"50px", position:"relative"}}>
          <label>Credit</label><i style={{ color: "red" }}>*</i>
          <Input style={{ maxWidth:"50px",fontSize: "10px"}} value={credit} disabled/>
          {errors?.credit &&
        <span style={{color:"red",fontSize:"9px", position:"absolute", left:"0px", bottom:"-40px"}}> {errors?.credit[0]}</span>
        }
        </div>
        <div className="input-box" style={{ maxWidth:"100px", position:"relative" }}>
          <label>Transfer Course</label><i style={{ color: "red" }}>*</i>
          <Input value={api_transferred_course} onChange={(e) => set_api_transferred_course(e.target.value)} style={{ maxWidth:"100px" , fontSize:"10px" }} />
          {errors?.transferred_course &&
        <span style={{color:"red",fontSize:"9px", position:"absolute", left:"0px", bottom:"-28px"}}> {errors?.transferred_course[0]}</span>
        }
        </div>
        <div className="input-box" style={{ maxWidth:"100px", position:"relative" }}>
          <label>Transfer institute</label><i style={{ color: "red" }}>*</i>
          <Input  value={transferred_institution} onChange={(e) => set_transferred_institution(e.target.value)} style={{ maxWidth:"100px",fontSize: "10px" }} />
          {errors?.transferred_institution &&
        <span style={{color:"red",fontSize:"9px", position:"absolute", left:"0px", bottom:"-40px"}}>{errors?.transferred_institution[0]}</span>
        }
        </div>
        <div className="input-box" style={{ maxWidth:"100px", position:"relative" }}>
          <label>Transfer Semester</label><i style={{ color: "red" }}>*</i>
          <Input  value={transferred_semester} onChange={(e) => set_transferred_semester(e.target.value)} style={{ maxWidth:"100px",fontSize: "10px" }} />
          {errors?.transferred_semester &&
        <span style={{color:"red",fontSize:"9px", position:"absolute", left:"0px", bottom:"-28px"}}>{errors?.transferred_semester[0]}</span>
        }
        </div>
        <div className="input-box" style={{ maxWidth:"115px", position:"relative"}}>
          <label>Begin Date</label><i style={{ color: "red" }}>*</i>
          <DatePicker style={{ maxWidth:"115px" ,  height:"35px" , fontSize:"10px"}} format={dateFormat}  value={begin_date && dayjs(begin_date)} onChange={(date, dateString) => set_begin_date(dateString)}  />
          {errors?.begin_date &&
        <span style={{color:"red",fontSize:"9px", position:"absolute", left:"0px", bottom:"-27px"}}> {errors?.begin_date[0]}</span>
        }
        </div>
        <div className="input-box" style={{ maxWidth:"115px", position:"relative"}}>
          <label>End Date</label><i style={{ color: "red" }}>*</i>
          <DatePicker style={{ maxWidth:"115px" , height:"35px" , fontSize:"10px" }} format={dateFormat} value={end_date && dayjs(end_date)} onChange={(date, dateString) => set_end_date(dateString)} />
          {errors?.end_date &&
        <span style={{color:"red",fontSize:"9px", position:"absolute", left:"0px", bottom:"-27px"}}>{errors?.end_date[0]}</span>
        }
        </div>
        <div  style={{ maxWidth:"60px", position:"relative"}} className="input-box">
          <label >Attempted</label><i style={{ color: "red" }}>*</i>
         <InputNumber  style={{ maxWidth:"60px" , height:"35px", fontSize:"12px" }} value={credit_attempted} min={1}
          // max={credit > 0 ? credit : 1}
            onChange={(value) => set_credit_attempted(value)} />
            {errors?.credit_attempted &&
        <span style={{color:"red",fontSize:"9px", position:"absolute", left:"0px", bottom:"-51px"}}>{errors?.credit_attempted[0]}</span>
        }
        </div>
        <div  style={{ maxWidth:"50px", position:"relative"}} className="input-box">
          <label>Earned</label><i style={{ color: "red" }}>*</i>
          <InputNumber  style={{ maxWidth:"50px", height:"35px", fontSize:"12px"}}  value={credit_earn} min={1}
          //  max={credit > 0 ? credit : 1} 
            onChange={(value) => set_credit_earn(value)} />
        {errors?.credit_earn &&
        <span style={{color:"red",fontSize:"9px", position:"absolute", left:"0px", bottom:"-39px"}}> {errors?.credit_earn[0]}</span>
        }
        </div>
       
        <div className="input-box" style={{ minWidth:"95px" }}>
          <label htmlFor="" style={{ color: "#f5f4f4" }}>
            Action buttons
          </label><br></br>
          <div>
          <Button type="primary" size="small" style={{fontSize:"10px", marginRight:"5px"}}  onClick={UPDATE_API}>UPDATE</Button>
          <Button type="primary" size="small" style={{fontSize:"10px"}}  onClick={() => DELETE_API(transferred_course.id)} danger>
          <DeleteOutlined
          />
          </Button>
          </div>
        </div>
      </div>
      </>}
    </>
  );
}
