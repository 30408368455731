
import React, { useEffect, useState } from 'react';
import { Table, Input, Avatar, Button } from 'antd';
import { UpOutlined, DownOutlined, EyeFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ADMIN_STUDENTS_LISTING, SIS_STORAGE, sisDateFormat, STUDENT_REQUEST_LIST } from '../../apis/apis';
import SisLoader from '../../widgets/loader';
const StudentsRequestsList = () => {

    const navigate = useNavigate();

    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    // Form State
    const [table_list, set_table_list] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
   


    useEffect(() => {
        VIEW_STUDENT_REQUEST_LIST();
    }, []);


    const VIEW_STUDENT_REQUEST_LIST = async() => {
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
       const VIEW_STUDENT_REQUEST_LIST_API_RESPONSE = await STUDENT_REQUEST_LIST(FORM_DATA);
       if(VIEW_STUDENT_REQUEST_LIST_API_RESPONSE?.data?.status)
       {
        set_table_list(VIEW_STUDENT_REQUEST_LIST_API_RESPONSE?.data?.students_request);
        setFilteredData(VIEW_STUDENT_REQUEST_LIST_API_RESPONSE?.data?.students_request);
        set_loader(false);
    } else {
        set_loader(false);
    }
    }


    // Search
    const handleSearch = (e) => {
        const query = e.target.value;
        console.log("query",query);
        setSearchQuery(query);
        const filtered = table_list.filter(
            (item) => {
                var name = item.enrollment_middle_name === 'null' && !item.enrollment_middle_name ? item.enrollment_first_name + ' ' + item.enrollment_last_name : item.enrollment_first_name + ' ' + item.enrollment_middle_name + ' ' + item.enrollment_last_name;
                if (name.toLowerCase().includes(query.toLowerCase()) || item.course_title.toLowerCase().includes(query.toLowerCase()) || item.course_code.toLowerCase().includes(query.toLowerCase()) || item.request_type.toLowerCase().includes(query.toLowerCase())) {
                    return item;
                }
            }
        );
        if (query === "") {
            setFilteredData(table_list);
        } else {
            setFilteredData(filtered);
        }
    };

    const students_requests_list_column = [
        
        {
            title: "Name",
            width:"22%",
            render: (text, record) => {

                const handleNavigation = () => {
                    if (record?.request_type === "repeat") {
                        navigate('/student-repeat-request-detail/' + btoa(record?.student_id) + "/" 
                            + btoa(record?.course_id) + "/" + btoa(record?.semester_id));
                    }
                    if (record?.request_type === "add_drop") {
                        navigate('/student-drop-request-detail/' + btoa(record?.student_id) + "/" 
                            + btoa(record?.course_id) + "/" + btoa(record?.semester_id));
                    }
                     if(record?.request_type === "withdraw") {
                        navigate('/student-withdraw-request-detail/' + btoa(record?.student_id) + "/" + btoa(record?.semester_id));
                    }
                    if(record?.request_type === "add_course") {
                        navigate('/student-add-request-detail/' + btoa(record?.student_id) + "/"  + btoa(record?.semester_id));
                    }
                };
                

                return <><span style={{cursor:"pointer"}}
                onClick={handleNavigation}
                > {record?.first_name}{" "}{record?.middle_name != "null" && record?.middle_name}{" "}{record?.last_name}</span></>;
            },
            sorter: (a, b) => a.course_title.localeCompare(b.course_title),
        },
        {
            title: "Course Name",
            width:"33%",
            render: (text, record) => {
                return <><span>{record?.course_code} - {record?.course_title}</span></>;
            },
            sorter: (a, b) => a.course_code.localeCompare(b.course_code),
        },
        {
            title: "Request Type",
            width:"15%",
            render: (text, record) => {
                return <><span>{record?.request_type}</span></>;
            },
        },
        {
            title: "Request Status",
            width:"15%",
            render: (text, record) => {
                return <>
                {record?.approve_status === 0 &&
                <span>Pending</span>
            }
             {record?.approve_status === 1 &&
                    <span style={{color:"limegreen"}}>Approved</span>
             }
             {record?.approve_status === 2 &&
                <span style={{color:"red"}}>Rejected</span>
            }
                    </>;
            },
        },
        {
            title: "Request Date",
            width:"15%",
            render: (text, record) => {
                const apiDate = record?.updated_at;
                const simpleDate = apiDate.split("T")[0];
                return <><span>{sisDateFormat(simpleDate)}</span></>;
            },
        },
       
    ];

    return (
        <>
         {loader ? <SisLoader /> : 
        <div>
            <div className="theme-content-head">
                <div className="theme-content-left-head">
                    <h3>Student Requests</h3>
                </div>
            </div>

            <div className="theme-content-head">
                <div className="input-box">
                    <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => handleSearch(e)}
                    />
                    {errors?.code && (
                        <span style={{ color: "red" }}>{errors?.code[0]}</span>
                    )}
                </div>
            </div>
           <Table columns={students_requests_list_column} dataSource={filteredData}/>
        </div>
        }
        </>
    );
}

export default StudentsRequestsList;




