import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Input, Select, Spin, Table, notification } from 'antd';
import './style.css';
import { TableColumnsType, TableProps } from 'antd';
import { CloseOutlined, CloudUploadOutlined, EyeInvisibleOutlined, EyeTwoTone, FrownOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { ADD_USER, ROLE_LIST } from '../../apis/apis';
import SisLoader from '../../widgets/loader';
import Item from 'antd/es/list/Item';
import { useNavigate } from 'react-router-dom';

const AddUser = () => {
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const [role_list, set_role_list] = useState([]);
    const [phoneerror, set_Phone_Error] = useState('');
    const userImage = useRef(null);

    const departments_list= ['Accounting and Finance', 'Admissions and Record', 'Athletic', 'Computer Lab', 'Diagnostic Imaging', 'Library', 'Office of the President', 'Office of the Administrative Vice President', 
    'Office of the Provost', 'Registrar Office', 'Student Services' ];

    // Form State
    const [name, set_name] = useState('');
    const [phone, set_phone] = useState('');
    const [country_code, set_country_code] = useState('');
    const [email, set_email] = useState('');
    // const [password, set_password] = useState('');
    // const [password_confirmation, set_password_confirmation] = useState('');
    const [address, set_address] = useState('');
    const [permission_role_id, set_permission_role_id] = useState('');
    const [image, set_image] = useState('');
    const [department, set_department] = useState(''); 
    const [showUserImageAlert, setShowUserImageAlert] = useState(false);


    // ROLES list
    const ROLE_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const ROLE_LIST_API_RESPONSE = await ROLE_LIST(FORM_DATA);
        if (ROLE_LIST_API_RESPONSE?.data?.status) {
            set_role_list(ROLE_LIST_API_RESPONSE?.data?.roles);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }

    useEffect(() => {
        ROLE_LIST_API();
    }, []);

    const handleImageChange = (e) => {
        const files = e.target.files;
          const file = files[0];
          const maxSize = 2 * 1024 * 1024;
    
          if ((file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < maxSize) {
            set_image(file);
          }
       else{
        setShowUserImageAlert(true);
        userImage.current.value = "";
       }
      };

    // const handleImageChange = (e) => {
    //     const files = e.target.files;
    //     if ((files[0].type === 'image/jpeg' || files[0].type === 'image/jpg' || files[0].type === 'image/png') && files[0].size < 2000001) {
    //         set_image(files[0]);
    //     } else {
    //         notification.open({
    //             message: 'Not Allowed',
    //             description: 'Please check image File format must be .jpg or .png and Max. file size 2 Mb',
    //             icon: <SmileOutlined style={{ color: 'red' }} />,
    //         });
    //         userImage.current.value='';
    //     }
    // }
    const handleRemoveImage = (e) => {
        set_image('');
        userImage.current.value='';
    }

    const ondepartmentChange = (value) => {

        set_department(value); 
    }

    const ADD_API = async () => {
        if(!phoneerror)
    {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('name', name);
        FORM_DATA.append('phone', phone);
        FORM_DATA.append('email', email );
        FORM_DATA.append('permission_role_id', permission_role_id);
        // FORM_DATA.append('password', password);
        // FORM_DATA.append('password_confirmation', password_confirmation);
        FORM_DATA.append('image', image);
        FORM_DATA.append('address', address);
        FORM_DATA.append('department', department);
        const API_RESPONSE = await ADD_USER(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            notification.open({
                message: 'Success!!',
                description: 'User Successfully added.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/users-list')
        } else {
            set_errors(API_RESPONSE?.data?.errors);
            set_loader(false)
        }
    }
    }
    return (
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Add User</h3>
                </div>
                <div className='theme-content-right-head'>

                </div>
            </div>
            <div className='common-form'>
                {errors?.try && <><span style={{ color: "red" }}>{errors?.try[0]}</span></>}
                {errors?.catch && <><span style={{ color: "red" }}>{errors?.catch[0]}</span></>}
                <div className='row'>
                    <div className='col-12'>
                        <div className='input-box'>
                            <label htmlFor="name">Name<i style={{ color: "red" }}>*</i></label>
                            <Input placeholder="Name" id='name' onChange={(e) => set_name(e.target.value)} />
                            {errors?.name && <><span style={{ color: "red" }}>{errors?.name[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            <label htmlFor="phone">Phone Number<i style={{ color: "red" }}>*</i></label>
                            <Input
                            value={phone}
                             maxLength={12}
                              onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} 
                              placeholder="Phone Number" id='phone' 
                            //   onChange={(e) => set_phone(e.target.value)} 
                            onChange={(e) => {
                                const phoneNumber = e.target.value;
                                // Remove non-digit characters from the input
                                const cleanedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
                                if(cleanedPhoneNumber.length<=10)
                                    {
                                set_Phone_Error('')
                                // Format the phone number as 510-628-8022
                                let formattedPhoneNumber = '';
                                for (let i = 0; i < cleanedPhoneNumber.length; i++) {
                                    if (i === 3 || i === 6) {
                                        formattedPhoneNumber += '-';
                                    }
                                    formattedPhoneNumber += cleanedPhoneNumber[i];
                                }
                                set_phone(formattedPhoneNumber);
                                set_Phone_Error('')
                            }
                                else
                                {
                                  set_phone(cleanedPhoneNumber)
                                  set_Phone_Error("Phone Number Maximum Length Should be 10 Digit ")
                                }
          
                            }}
                              />
                            {
                            errors?.phone && <><span style={{ color: "red" }}>{errors?.phone[0]}</span></>
                            || phoneerror && <><span style={{ color: "red" }}>{phoneerror}</span></>
                            }
                        </div>
                        <div className='input-box'>
                            <label htmlFor="email">Email<i style={{ color: "red" }}>*</i></label>
                            <Input placeholder="Email" id='email' onChange={(e) => set_email(e.target.value)} />
                            {errors?.email && <><span style={{ color: "red" }}>{errors?.email[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            <label htmlFor="Select-Role">Select Role<i style={{ color: "red" }}>*</i></label><br></br>
                            <Select
                                placeholder="Select Role"
                                //value={category}
                                onChange={(value) => set_permission_role_id(value)}
                                style={{ width: '100%' }}
                                allowClear
                                showSearch
                                filterOption={(inputValue, option) => {
                                    return !option.label.toLowerCase().indexOf(inputValue.toLowerCase())
                                }}
                                options={role_list.map((item) => {
                                    if(item.status === 1){
                                        return({
                                            value: item.id,
                                            label: item.role_name,
                                        })
                                    }else{
                                        return({
                                            value: item.id,
                                            label: item.role_name,
                                            disabled: true
                                        })
                                    }
                                })}
                            />

                            {/* <Select onChange={(value) => set_permission_role_id(value)} style={{ width: "100%" }} placeholder="Select User Role">
                                {role_list?.length > 0 && <>
                                    {role_list?.map(role => {
                                       <>
                                       <Select.Option value={role.id}>{role.role_name}</Select.Option>
                                       </>
                                    })}
                                </>}
                            </Select> */}
                            {errors?.permission_role_id && <><span style={{ color: "red" }}>{errors?.permission_role_id[0]}</span></>}
                        </div>


                        <div className='input-box'>
                            <label htmlFor="Select-Department">Select Department<i style={{ color: "red" }}>*</i></label><br></br>
                            <Select
                                placeholder="Select Department"
                                value={department}
                                onChange={ondepartmentChange}
                                style={{ width: '100%' }}
                                options={departments_list.map((item) => ({
                                    value: item,
                                    label: item,
                                }))}
                            />

                            {errors?.department && <><span style={{ color: "red" }}>{errors?.department[0]}</span></>}
                        </div>

                        {/* <div className='input-box'>
                            <label htmlFor="Password">Password<i style={{ color: "red" }}>*</i></label>
                            <Input.Password placeholder="input password" autoComplete="new-password" onChange={(e) => set_password(e.target.value)} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                            {errors?.password && <><span style={{ color: "red" }}>{errors?.password[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            <label htmlFor="Password">Password Confirmation<i style={{ color: "red" }}>*</i></label>
                            <Input.Password placeholder="input password" autoComplete="new-password" onChange={(e) => set_password_confirmation(e.target.value)} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                            {errors?.password_confirmation && <><span style={{ color: "red" }}>{errors?.password_confirmation[0]}</span></>}
                        </div> */}
                        <div className='input-box'>
                            <label htmlFor="address">Address</label>
                            <Input placeholder="address" id='address' onChange={(e) => set_address(e.target.value)} />
                            {errors?.address && <><span style={{ color: "red" }}>{errors?.address[0]}</span></>}
                        </div>
                        <div className='input-box' style={{ position: "relative" }}>
                            <label htmlFor="address">User Photo</label><br></br>
                            <label className='upload-box'> <input ref={userImage} disabled={showUserImageAlert} type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={handleImageChange} /> <CloudUploadOutlined /> Upload Image</label>
                            {showUserImageAlert ?
                            <>
                            <Alert
                            message={<span><FrownOutlined style={{ color: 'red',fontSize:"25px" }} /> Error</span>}
                            description="Image file format must be .jpg, .jpeg or .png and the image size should not exceed 2MB."
                            type="error"
                            //   showIcon
                              closable
                              onClose={() => setShowUserImageAlert(false)}
                            />
                            <br/>
                            </>
                          :
                        <> 
                            {image &&
                                <>
                                    <div className="image-box" style={{ backgroundImage: "url(" + URL.createObjectURL(image) + ")" }}></div>
                                    <button className='image-remove' onClick={() => handleRemoveImage()}><CloseOutlined /></button>
                                </>
                            }
                            {errors?.image && <><span style={{ color: "red" }}>{errors?.image[0]}</span></>}
                            </>
                    }
                        </div>

                    </div>
                    <div className='col-12'>
                        <div className='input-box'>
                            {loader ? <>
                                <Button type="primary" className='lusisbtn'><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Saving</Button>
                            </> : <>
                                <Button type="primary" className='lusisbtn' onClick={ADD_API}>Save</Button>
                            </>}
                        </div>

                    </div>
                </div>

            </div>

        </div>
    );
};

export default AddUser;