import { Button, DatePicker, Select, Tooltip } from "antd";
import { BACKEND_URL } from "../../../../config/config";
import { CloudDownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { CALENDARS_LIST } from "../../../../apis/apis";
import dayjs from 'dayjs';


const AllAttendanceReportDownload = () =>{
    const dateFormat = 'MM-DD-YYYY';
    const [academic_year, set_academic_year] = useState("");
    const [errors, set_errors] = useState([]);
    const [academic_year_list, set_academic_year_list] = useState([]);
    const [semester, set_semester] = useState("");
    const [sem_list, set_sem_list] = useState([]);
    const [calender_index, set_calender_index] = useState(0)

    const DATE_CLASS = new Date();
    const current_month = DATE_CLASS.getMonth() < 9 ? '0' + (DATE_CLASS.getMonth() + 1) : DATE_CLASS.getMonth();
    const today_date =  current_month + '-' + DATE_CLASS.getDate() +  '-' + DATE_CLASS.getFullYear();
   // const default_date = current_month + '-' + DATE_CLASS.getDate() + '-' + DATE_CLASS.getFullYear();

    const [selectedDate, setSelectedDate] = useState(today_date);

    const onDateChange = (date, dateString) => {
        setSelectedDate(dateString)
      };
    
    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            const calendars = API_RESPONSE?.data?.calendars;
            set_academic_year_list(calendars);
      
            if (calendars.length > 0) {
              set_academic_year(calendars[0].id);
              set_sem_list(calendars[0].semesters);
              if (calendars[0].semesters.length > 0) {
                set_semester(calendars[0].semesters[0].id);
              }
            }
          }else {
          
        }
    }


    const calender_change_function = (value) => {
        set_semester('');
        set_errors('');
        const index = academic_year_list.findIndex(item => item.id === value)
        set_academic_year(value);
        set_calender_index(index);
        set_sem_list(academic_year_list[index].semesters);
    }

    useEffect(() => {
        LIST_API();
    }, []);


    return(
        <>
                   <h3>Download Attendance Reports</h3>
                   <div className="row">
                <div className="col-6">
                    <div className="input-box" style={{ width: "100%" }}>
                        <label>
                            Select Academic Year<i style={{ color: "red" }}>*</i>
                        </label>
                        <br />
                        <Select
                            placeholder="--Select Academic Year--"
                            style={{ width: "100%", height: "40px" }}
                            onChange={calender_change_function}
                            value={academic_year ? parseInt(academic_year) : academic_year}
                            options={academic_year_list.map((item) => ({
                                value: item.id,
                                calender_id: item.id,
                                label: item.title,
                            }))}

                        />
                        {errors?.entering_year && (
                            <>
                                <span style={{ color: "red" }}>
                                    Please Select Academic Year
                                </span>
                            </>
                        )}
                    </div>
                </div>
                <div className="col-6">
                    <div className="input-box" style={{ width: "100%" }}>
                        <label>
                            Select Semester<i style={{ color: "red" }}>*</i>
                        </label>
                        <br />

                        <Select
                            placeholder="--Select Semester--"
                            style={{ width: "100%", height: "40px" }}
                            disabled={Boolean ? !academic_year : academic_year} // Disable until a academic year is selected
                            onChange={(value) => {
                                set_semester(value);
                            }}
                            value={semester}
                            options={sem_list.map((item) => ({
                                value: item?.id,
                                label: item?.title,
                            }))}
                        />

                        {errors?.semester_id && (
                            <>
                                <span style={{ color: "red" }}>
                                The semester field is required.
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="row">
            <div className='col-6' >
                <span>Select Date</span><i style={{ color: "red" }}>*</i>
                <DatePicker style={{ width: "100%", height:"40px", margin: "10px 0" }}
                 format={dateFormat}
                 defaultValue={dayjs(today_date)}
                 onChange={onDateChange}/>
                </div>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>Report</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ paddingLeft: "15px" }}>Attendance Absence Report</td>
                        <td>
                        <Tooltip title="Download Attendance Absence Report CSV">
                            <Button type='primary'
                                onClick={() => window.location = BACKEND_URL + '/csv-attendance-absence-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token+ "/" + selectedDate + "/" + semester} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                            <Tooltip title="Download Attendance Absence Report PDF">
                                <Button type='primary'
                                onClick={() => window.open(BACKEND_URL + '/attendance-absence-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + selectedDate + "/" + semester)}
                                  ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingLeft: "15px" }}>Attendance Present Report</td>
                        <td>
                        <Tooltip title="Download Attendance Present Report CSV">
                            <Button type='primary'
                                onClick={() => window.location = BACKEND_URL + '/csv-attendance-present-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token+ "/" + selectedDate + "/" + semester } ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                            <Tooltip title="Download Attendance Present Report PDF">
                                <Button type='primary' 
                              onClick={() => window.open(BACKEND_URL + '/attendance-present-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + selectedDate + "/" + semester)}
                                ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingLeft: "15px" }}> Attendance Canceled Report </td>
                        <td>
                        <Tooltip title="Download Attendance Canceled Report CSV">
                            <Button type='primary'
                                onClick={() => window.location = BACKEND_URL + '/csv-attendance-canceled-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token+ "/" + selectedDate + "/" + semester} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                            <Tooltip title="Download Attendance Canceled Report PDF">
                                <Button type='primary'
                               onClick={() => window.open(BACKEND_URL + '/attendance-canceled-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + selectedDate + "/" + semester)}
                                 ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default AllAttendanceReportDownload;