import { useState, useEffect } from "react";

import "../../Attendance/attendance.css";
import { Input, Select, Button, Table, Popconfirm, notification, Spin } from "antd";
import { DeleteOutlined, LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { REASONS_DELETE, REASONS_LIST, REASONS_UPDATE } from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";
function ReasonsView({ attendance_category_id }) {
  const [loader, set_loader] = useState(true);
  const [table_list, set_table_list] = useState([]);
  const [errors, set_errors] = useState([]);
  // Attendance Category
  // const [faculty_access, set_faculty_access] = useState('');
  // const [reason_title, set_reason_title] = useState('');
  // const [reason_type, set_reason_type] = useState('');
  // const [isExcused, set_isExcused] = useState('');
  // const [isUsedinCalculations, set_isUsedinCalculations] = useState('');
  // const [isFullDay, set_isFullDay] = useState('');


  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      // render: (text, record) => {
      //   return <></>;
      // },
    },

    {
      title: "Reason",
      dataIndex: "Reason",
      key: "Reason",
      width: "30%",
      render: (text, record) => {
        return (
          <>
            {record.reason_title}

          </>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, record) => {
        return (
          <>
            {record.status ? 'Active' : 'Inactive'}
          </>

        );
      },
    },

    {
      title: "Faculty Access",
      dataIndex: "Access",
      key: "Access",
      render: (text, record) => {
        return (
          <>
            {record.faculty_access ? 'Yes' : 'No'}
          </>


        );
      },
    },

    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      render: (text, record) => {
        return (
          <>
            {record.reason_type == 0 && 'Absence'}
            {record.reason_type == 1 && 'Present'}
            {record.reason_type == 2 && 'Tardy'}
          </>


        );
      },
    },

    {
      title: "Excused/ Unexcused",
      dataIndex: "Permission",
      key: "Permission",
      render: (text, record) => {
        return (
          <>
            {record.isExcused == 0 && 'Unexcused'}
            {record.isExcused == 1 && 'Excused'}
            {record.isExcused == 2 && 'N/A'}
          </>

        );
      },
    },

    {
      title: "Use in calculation ",
      render: (text, record) => {
        return (
          <>
            {record.isUsedinCalculations ? 'Yes' : 'No'}
          </>


        );
      },
    },

    {
      title: "Full Day / Half Day",
      render: (text, record) => {
        return (
          <>
            {record.isFullDay == 0 && 'Half Day'}
            {record.isFullDay == 1 && 'Full Day'}
            {record.isFullDay == 2 && 'N/A'}
          </>

        );
      },
    }
  ];
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('attendance_category_id', attendance_category_id);
    const API_RESPONSE = await REASONS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE?.data?.attendance_reasons);
      set_loader(false)
    } else {
      set_loader(false)
    }
  }
  useEffect(() => {
    LIST_API();
  }, [loader]);

  return (
    <>
      {loader ? <SisLoader /> : <>
        <Table dataSource={table_list} columns={columns} pagination={false} />
      </>}

    </>
  );
}

export default ReasonsView;
