import { useEffect, useRef, useState } from "react";
import Chart from 'chart.js/auto';
import { ALL_ATTENDANCE_REPORT_GRAPH } from "../../../../apis/apis";

const AttendanceReportSummaryGraph = () =>{
    const [graphData, setGraphData] = useState({
        labels: [],
        absence: { excused: [], unexcused: [] },
        present: { counts: [] },
        canceled: { counts: [] },
      });
      const chartRef = useRef(null);

      const VIEW_ALL_ATTENDANCE_REPORT_GRAPH = async() => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const VIEW_ALL_ATTENDANCE_REPORT_GRAPH_API_RESPONSE = await ALL_ATTENDANCE_REPORT_GRAPH(FORM_DATA);
        if (VIEW_ALL_ATTENDANCE_REPORT_GRAPH_API_RESPONSE?.data?.status) {
          setGraphData(VIEW_ALL_ATTENDANCE_REPORT_GRAPH_API_RESPONSE?.data?.graph);
        }

      }

      useEffect(()=>{
        VIEW_ALL_ATTENDANCE_REPORT_GRAPH();
      },[])

      useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
    
        const combinedChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: graphData?.labels, // Use API data
            datasets: [
              {
                label: 'Excused Absences',
                data: graphData?.absence.excused, // Use API data
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
              },
              {
                label: 'Unexcused Absences',
                data: graphData?.absence.unexcused, // Use API data
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
              },
              {
                label: 'Present',
                data: graphData?.present.counts, // Use API data
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
              },
              {
                label: 'Canceled Classes',
                data: graphData?.canceled.counts, // Use API data
                backgroundColor: 'rgba(255, 206, 86, 0.6)',
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  boxWidth: 12, // Set your desired width for the color bar here
                  font: {
                    size: 12 // Set your desired font size here
                }
              }
              },
              title: {
                display: true,
                // text: 'Student Attendance Report',
              },
            },
            responsive: true,
            scales: {
              x: {
                beginAtZero: true,
                title: {
                  display: true,
                   text: 'Course Code',
                  font: {
                    size: 14,
                    weight: 'bold',
                    family: 'Arial',
                    color: '#000'
                  }
                }
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'No. of Students',
                  font: {
                    size: 14,
                    weight: 'bold',
                    family: 'Arial',
                    color: '#000'
                  }
                }
              },
            },
          },
        });
        return () => {
          combinedChart.destroy();
        };
      }, [graphData]);
    
    return(
        <>
         <h3>Visualization Summary of Attendance Report</h3>
        <div style={{ width: '100%', margin: '0 auto' }}>
        <canvas ref={chartRef}></canvas>
      </div>
        </>
    )
}

export default AttendanceReportSummaryGraph;