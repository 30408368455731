import React, { useEffect, useState } from 'react';
import SisSections from './component/Sections';
import SisProgramme from './component/AddBasicInfo';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';
import AddSection from './component/sections/AddSection';
import { SECTIONS_LIST } from '../../apis/apis';
import EditSection from './component/sections/EditSection';
import SisLoader from '../../widgets/loader';
import { PlusOutlined } from '@ant-design/icons';

const SectionList = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [add_section_button, set_add_section_button] = useState(true);
    const [add_section_form, set_add_section_form] = useState(false);
    const [sections_list, set_sections_list] = useState([]);
    const [open_section, set_open_section] = useState(0);
    const [page_loader, set_page_loader] = useState(true)
    const [show_empty, set_show_empty] = useState(true)
    
    const add_section_row = () => {
        set_add_section_button(false);
        set_add_section_form(true);
        set_show_empty(false)
    }
    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('programme_id', atob(id));
        const API_RESPONSE = await SECTIONS_LIST(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            set_sections_list(API_RESPONSE?.data.sections);
        } else {

        }
    }
    useEffect(() => {
        LIST_API()
        set_page_loader(false);
    }, [page_loader])
    return (
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Programme Section <Button  style={{backgroundColor:"#B64141"}} type='primary' size='small' onClick={() => navigate('/edit-programme/' + id)}>Back</Button></h3>
                </div>
                <div className='theme-content-right-head'>

                </div>
            </div>
            <div className='common-form'>
                <div className='form-step-box'>
                    <div className={'form-step active'}>
                        <div className='form-step-line'></div>
                        <span>1</span>
                        <h3>Basic Information </h3>
                    </div>
                    <div className={'form-step running'}>
                        <span>2</span>
                        <h3>Programme Section</h3>
                    </div>

                </div>
                <div className='form-step-container'>
                    <div className='theme-content-head'>
                        <div className='theme-content-left-head'>
                            {/* <p>Units Requirement met 123</p> */}
                        </div>
                        <div className='theme-content-right-head'>
                            {add_section_button ? <><Button style={{width:"156px"}} className='lusisbtn' type='primary' onClick={add_section_row}><PlusOutlined/> Add Section</Button></> : <><Button style={{width:"156px"}} className='lusisbtn' type='primary' disabled>Add Section</Button></>}
                        </div>
                    </div>
                </div>
                {page_loader ? <SisLoader /> : <>
                    {sections_list.length > 0 ? <>
                        {sections_list.map((item, index) => (
                            <>

                                <EditSection open_section={open_section} section_id={item.id} section_item={item} set_open_section={set_open_section} programme_id={atob(id)} set_page_loader={set_page_loader} set_add_section_form={set_add_section_form} set_add_section_button={set_add_section_button} />


                            </>
                        ))}

                    </> : <>
                        {show_empty &&  <p className='error-empty'>No section available</p>}
                    </>}
                </>}
                {add_section_form && <AddSection set_open_section={set_open_section} programme_id={atob(id)} set_page_loader={set_page_loader} set_add_section_form={set_add_section_form} set_add_section_button={set_add_section_button} />}
                {sections_list.length > 0 && <>
                    <div className='input-box' style={{ textAlign: "right" }}>
                        <Button type='primary' className='lusisbtn' onClick={() => navigate('/programmes-list')}>Submit</Button>
                    </div>
                </>}
            </div>

        </div>
    );
};

export default SectionList;