import { useState, useEffect } from "react";

import "../../Attendance/attendance.css";
import {
  Input,
  Select,
  Button,
  Table,
  Popconfirm,
  notification,
  Spin,
} from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  REASONS_DELETE,
  REASONS_LIST,
  REASONS_UPDATE,
} from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";
import ReasonRow from "./ReasonRow";
function Reasons({ attendance_category_id, history, reason_row_loader, set_reason_row_loader }) {
  const [loader, set_loader] = useState(true);
  const [table_list, set_table_list] = useState([]);
  const [errors, set_errors] = useState([]);
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("attendance_category_id", attendance_category_id);
    const API_RESPONSE = await REASONS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE?.data?.attendance_reasons);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  useEffect(() => {
    LIST_API();
  }, [history]);

  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          {/* <Table dataSource={table_list} columns={columns} pagination={false} /> */}
          <div className="reason-row reason-head">
            <div className="reason-column">Reason</div>
            <div className="reason-column">Status</div>
            <div className="reason-column">Faculty Access</div>
            <div className="reason-column">Type</div>
            <div className="reason-column">Excused/ Unexcused</div>
            <div className="reason-column">Use in calculation</div>
            <div className="reason-column">Full Day / Half Day</div>
            <div className="reason-column">Action</div>
          </div>
          {table_list.length > 0 ? <>
           {table_list?.map((item) => (
            <>
            <ReasonRow  reason_row_loader={reason_row_loader} set_reason_row_loader={set_reason_row_loader} history={history} item={item} set_loader={set_loader} id={item?.id} />
            </>
           ))}
          </> : <p style={{color:"red", fontSize:"16px", textAlign:"center"}}>Empty Data</p>}
          
        </>
      )}
    </>
  );
}

export default Reasons;
