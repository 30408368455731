import React, { useEffect, useState } from 'react';
import { Button, Input, Popconfirm, Table, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, SmileOutlined, StopOutlined } from '@ant-design/icons';
import SisLoader from '../../widgets/loader';
import { ENROLLMENTS_LIST, DELETE_ENROLLMENT, STATUS_ENROLLMENT, STUDENTS_ENROLLMENTS_LIST } from '../../apis/apis';

const EnrollmentsList = () => {
    const navigate = useNavigate();
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    const [student_id, set_student_id] = useState('')
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);

      // Permissions state manage 
    const [all_permissions, set_all_permissions] = useState(false);
    const [permissions, set_permissions] = useState([]);
    const check_permissions = (module_name, type) => {
        const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if (localStorgeData.role === 2074) {
            return true;
        }
        var check_permissions = [];
        if (type === 'create') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
        }
        if (type === 'update') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
        }
        if (type === 'status') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
        }
        if (type === 'delete') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
        }

        if (check_permissions.length > 0) {
            return true;
        } else {
            return false;
        }
    }


    // Form State
    const [table_list, set_table_list] = useState([]);
    const STATUS_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await STATUS_ENROLLMENT(FORM_DATA);
        console.log("hi22", API_RESPONSE.data.status)
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Student Enrollment status Successfully changed.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/enrollments-list')
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    const DELETE_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await DELETE_ENROLLMENT(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Student Enrollment Successfully deleted.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/enrollments-list')
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    const columns = [
        {
            title: 'Program Regn Id',
            dataIndex: 'id',
            key: 'id'

        },
        {
            title: 'Application ID',
            dataIndex: 'application_no',
            key: 'application_no'
        },
        {
            title: 'Programme Title',
            dataIndex: 'program_title',
            key: 'program_title'
        },
        {
            title: 'Student Details',
            render: (text, record) => {
                return (
                    <>
                        <span>{record.first_name} {record.last_name}</span>
                        {/* <span><b>Email: </b>{record.primary_email} </span><br></br>
                        <span><b>Phone: </b>{record.phone} </span> */}
                    </>
                );
            }
        },
        {
            title : 'Student Status',
            render: (text, record) => {
                if (record.student_status == 1) {
                  return 'Ready for Programme Registration';
                }
                if (record.student_status == 2) {
                  return 'Enrolled';
                } 
            },
        },
        {
            title : 'Modify',
            key  : 'modify',
            render : (record)=>{
                return(
                    <>
                          {(check_permissions('students_programmes_registrations', 'create') || check_permissions('students_programmes_registrations', 'update'))  && 
                    <Button style={{background:"#b64141"}} type='primary' onClick={() => navigate('/add-enrollment/' + btoa(record.id))}>Register For Program</Button> 
                }
                    </>
                )

            }
        },
        
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     render: (text, record) => {
        //         if (record.status) {
        //             return (
        //                 <span className="table-status-activate">Activate</span>
        //             );
        //         } else {
        //             return (
        //                 <span className="table-status-deactivate">Deactivate</span>
        //             );
        //         }
        //     }
        // },
        // {
        //     title: 'Action',
        //     key: 'Action',
        //     render: (text, record) => {
        //         return (
        //             <>
        //                 <Popconfirm
        //                     title="Change Status"
        //                     description="Are you sure to change Status this room?"
        //                     onConfirm={() => {
        //                         STATUS_API(btoa(record.id))
        //                     }}
        //                     // onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <Button type="primary" size="small" style={{ backgroundColor: "#888888", marginRight: "5px" }}><StopOutlined /></Button>
        //                 </Popconfirm>

        //                 <Button type="primary" size="small" onClick={() => navigate('/edit-enrollment/' + btoa(record.id))} style={{ marginRight: "5px" }}><EditOutlined /></Button>

        //                 <Popconfirm
        //                     title="Change Status"
        //                     description="Are you sure to delete this room?"
        //                     onConfirm={() => {
        //                         DELETE_API(btoa(record.id))
        //                     }}
        //                     // onCancel={cancel}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <Button type="primary" size="small" danger><DeleteOutlined /></Button>
        //                 </Popconfirm>
        //             </>
        //         )
        //     }

        // },
    ];
     // Search
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = table_list.filter(
        (item) => {
          var name = item.first_name + ' ' + item.last_name;
          if (name.toLowerCase().includes(query.toLowerCase()) || item.program_title.toLowerCase().includes(query.toLowerCase()) || item.application_no.toLowerCase().includes(query.toLowerCase()) || item.student_status.toLowerCase().includes(query.toLowerCase())) {
            return item;
          }
        }
      );
    if (query === "") {
      setFilteredData(table_list);
    } else {
      setFilteredData(filtered);
    }
  };
    // DB list
    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await STUDENTS_ENROLLMENTS_LIST(FORM_DATA);
        console.log("145", API_RESPONSE)
        if (API_RESPONSE?.data?.status) {
            set_table_list(API_RESPONSE?.data?.enrollments);
            setFilteredData(API_RESPONSE?.data?.enrollments);
            set_student_id(API_RESPONSE?.data?.enrollments?.student_id)
            set_loader(false);
        } else {
            set_loader(false);
        }
    }
    useEffect(() => {
        LIST_API();
    }, [loader]);


    return (
        <>
        {loader ? <SisLoader /> : <>
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Program Registrations List</h3>
                </div>
                {/* <div className='theme-content-right-head'>
                    <Button type='primary' onClick={() => navigate('/add-enrollment')}>Register Student in Program</Button>
                </div> */}

            </div>
            <div className="theme-content-head">
        <div className="input-box">
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
          {errors?.code && (
            <span style={{ color: "red" }}>{errors?.code[0]}</span>
          )}
        </div>
      </div>
            <Table columns={columns} dataSource={filteredData} />
        </div>
        </>}
        </>
    );
};

export default EnrollmentsList;