/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Radio, Spin, notification } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import {
  CloseOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  ADD_COURSE,
  ADD_PROGRAM,
  EDIT_PROGRAM,
  SIS_STORAGE,
  UPDATE_PROGRAM,
} from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";
import { TINY_KEY } from "../../../config/config";
const SisProgrammeAdd = (props) => {
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);

  // Form State
  const [certificate_programs, set_certificate_programs] = useState(1);
  const [title, set_title] = useState("");
  const [unit, set_unit] = useState("");
  const [image, set_image] = useState("");
  const [programme_code, set_programme_code] = useState("");
  const [description, set_description] = useState("");
  const [url_image, set_url_image] = useState("");
  const [program_concentration, set_program_concentration] = useState("");
  const handleImageChange = (e) => {
    const files = e.target.files;
    if (
      (files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png") &&
      files[0].size < 2000001
    ) {
      set_image(files[0]);
    } else {
      notification.open({
        message: "Not Allowed",
        description:
          "Please check image File format must be .jpg or .png and Max. file size 2 Mb",
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
    }
  };
  const handleRemoveImage = (e) => {
    set_image("");
  };

  const ADD_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("title", title);
    FORM_DATA.append("programme_code", programme_code);
    FORM_DATA.append("description", editorRef.current.getContent());
    FORM_DATA.append("image", image);
    FORM_DATA.append("program_concentration", program_concentration);
    FORM_DATA.append("unit", unit);
    FORM_DATA.append("certificate_programs", certificate_programs);
    const API_RESPONSE = await ADD_PROGRAM(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Programme Basic Information Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      // set_programme_id(API_RESPONSE.data.id);
      set_loader(false);
      navigate("/section/" + btoa(API_RESPONSE.data.id));
      // set_show_basic(false)
      // set_show_section(true)
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };

  return (
    <>
      <div>
        <div className="theme-content-head">
          <div className="theme-content-left-head">
            <h3>
              Add Programme{" "}
              <Button
              style={{backgroundColor:"#B64141"}}
                type="primary"
                size="small"
                onClick={() => navigate("/programmes-list")}
              >
                Back
              </Button>
            </h3>
          </div>
          <div className="theme-content-right-head"></div>
        </div>
        <div className="common-form">
          <div className="form-step-box">
            <div className={"form-step running"}>
              <div className="form-step-line"></div>
              <span>1</span>
              <h3>Basic Information </h3>
            </div>
            <div className={"form-step"}>
              <span>2</span>
              <h3>Programme Section</h3>
            </div>
          </div>
          <div className="form-step-container">
            <div className="row">
              <div className="col-12">
                <div className="input-box">
                  <label htmlFor="name">
                    Programme Title<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={title}
                    placeholder="Title"
                    id="title"
                    onChange={(e) => set_title(e.target.value)}
                  />
                  {errors?.title && (
                    <>
                      <span style={{ color: "red" }}>{errors?.title[0]}</span>
                    </>
                  )}
                </div>
                <div className="input-box">
                  <label htmlFor="name">
                    Programme Code<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={programme_code}
                    placeholder="Title"
                    id="title"
                    onChange={(e) => set_programme_code(e.target.value)}
                  />
                  {errors?.programme_code && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.programme_code[0]}
                      </span>
                    </>
                  )}
                </div>
                <div className="input-box">
                  <label htmlFor="name">
                    Programme Description<i style={{ color: "red" }}>*</i>
                  </label>
                  <Editor
                    apiKey={TINY_KEY}
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                      set_description(editorRef.current.getContent());
                    }}
                    initialValue={""}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins:
                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools",
                      toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  {errors?.description && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.description[0]}
                      </span>
                    </>
                  )}
                </div>

                <div className="input-box">
                  <label htmlFor="program_concentration">
                    Programme Units<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={unit}
                    maxLength={10}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Programme Units"
                    id="Units"
                    onChange={(e) => set_unit(e.target.value)}
                  />
                  {errors?.unit && (
                    <>
                      <span style={{ color: "red" }}>{errors?.unit[0]}</span>
                    </>
                  )}
                </div>
                {/* <div className='input-box'>
                                        <label htmlFor="Units">Program Concentration<i style={{ color: "red" }}>*</i></label>
                                        <Input value={program_concentration} placeholder="Program Concentration" id='program_concentration' onChange={(e) => set_program_concentration(e.target.value)} />
                                        {errors?.program_concentration && <><span style={{ color: "red" }}>{errors?.program_concentration[0]}</span></>}
                                    </div> */}
                <div className="input-box">
                  <label htmlFor="Units">
                    Program Type<i style={{ color: "red" }}>*</i>
                  </label>
                </div>
                <div>
                  <Radio.Group
                    onChange={(e) => set_certificate_programs(e.target.value)}
                    value={certificate_programs}
                  >
                    <Radio value={1}>Under Graduate</Radio>
                    <Radio value={2}>Graduate</Radio>
                    <Radio value={3}>Doctorate</Radio>
                    <Radio value={4}>Certificate Programs</Radio>
                  </Radio.Group>
                  <br></br>
                  {errors?.certificate_programs && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.certificate_programs[0]}
                      </span>
                    </>
                  )}
                </div>
                <div className="input-box" style={{ position: "relative" }}>
                  <label htmlFor="address">Programme Photo</label>
                  <br></br>
                  <label className="upload-box">
                    {" "}
                    <input
                      type="file"
                      id="upload-images"
                      accept="image/*"
                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={handleImageChange}
                    />{" "}
                    <CloudUploadOutlined /> Upload Image
                  </label>
                  {image && (
                    <>
                      <div
                        className="image-box"
                        style={{
                          backgroundImage:
                            "url(" + URL.createObjectURL(image) + ")",
                        }}
                      ></div>
                      <button
                        className="image-remove"
                        onClick={() => handleRemoveImage()}
                      >
                        <CloseOutlined />
                      </button>
                    </>
                  )}
                  {errors?.image && (
                    <>
                      <span style={{ color: "red" }}>{errors?.image[0]}</span>
                    </>
                  )}
                </div>
                <div className="input-box" style={{ marginTop: "20px" }}>
                  {loader ? (
                    <>
                      <Button type="primary" className='lusisbtn' style={{width:"156px"}}>
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />{" "}
                        Save & Next
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type="primary" onClick={ADD_API} className='lusisbtn' style={{width:"156px"}}>
                        Save & Next
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SisProgrammeAdd;
