import { Table } from "antd";
import { useEffect, useState } from "react";
import { ACADEMIC_REPORT_STATISTICS } from "../../../../apis/apis";


const AcademicReportSummaryTable = () => {
    const[academicsummarytable, setAcademicSummaryTable] = useState([]);

    useEffect(()=>{
        VIEW_ACADEMIC_REPORT_STATISTICS();
        },[])
        
        const VIEW_ACADEMIC_REPORT_STATISTICS = async() =>{
            const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
            const FORM_DATA = new FormData();
            FORM_DATA.append('token', USER_DATA.token);
            const VIEW_ACADEMIC_REPORT_STATISTICS_API_RESPONSE = await ACADEMIC_REPORT_STATISTICS(FORM_DATA);
            if(VIEW_ACADEMIC_REPORT_STATISTICS_API_RESPONSE?.data?.status)
                {
                    setAcademicSummaryTable(VIEW_ACADEMIC_REPORT_STATISTICS_API_RESPONSE?.data?.statistics)
                }
        }
    
    const columns = [
        {
            title: "Enrolled Students",
            render: (text, record) => {
                return (
                    <>
                      <span>{record?.enrolled_students_count}</span>
                    </>
                )
            }
        },

        {
            title: "Registered Students",
            render: (text, record) => {
                return (
                    <>
                     <span>{record?.register_students_count}</span>
                    </>
                )
            },
        },

        {
            title: "New Students",
            render: (text, record) => {
                return (
                    <>
                    <span>{record?.new_students_count}</span>
                    </>
                )
            },
        },

        {
            title: "Returning Students",
            render: (text, record) => {
                return (
                    <>
                    <span>{record?.returning_students_count}</span>
                    </>
                )
            },
        },

        {
            title: "Transfer In",
            render: (text, record) => {
                return (
                    <>
                      <span>{record?.transfer_in_count}</span>
                    </>
                )
            },
        },

        {
            title: "Transfer Out",
            render: (text, record) => {
                return (
                    <>
                       <span>{record?.transfer_out_count}</span>
                    </>
                )
            },
        },

        {
            title: "Withdraw Out",
            render: (text, record) => {
                return (
                    <>
                      <span>{record?.withdraw_out_count}</span>
                    </>
                )
            },
        },
        {
            title: "Part Time",
            render: (text, record) => {
                return (
                    <>
                      <span>{record?.part_time_count}</span>
                    </>
                )
            },
        },
        {
            title: "Full Time",
            render: (text, record) => {
                return (
                    <>
                     <span>{record?.full_time_count}</span>
                    </>
                )
            },
        },
        {
            title: "Faculties",
            render: (text, record) => {
                return (
                    <>
                     <span>{record?.faculties_count}</span>
                    </>
                )
            },
        },
        {
            title: "Total No. of Students",
            render: (text, record) => {
                return (
                    <>
                    <span>
                    {record?.total_Students}
                    </span>
                    </>
                )
            },
        },
    ];

    return(
        <>
       <Table columns={columns} dataSource={academicsummarytable}/>
        </>
    )
}

export default AcademicReportSummaryTable;