import React, { useEffect, useState } from "react";
// import "./style.css";
import { Button, DatePicker, Input, Checkbox, Popconfirm, InputNumber, Select, notification } from "antd";
import {
  SyncOutlined,
} from '@ant-design/icons';
import {
  ADD_PAYMENT_INSTALLMENT_API,
  VIEW_PAYMENT_PLAN_TABLE_API,
} from "../../apis/apis";
import { DeleteOutlined, EditOutlined, PlusOutlined, SmileOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import SisLoader from "../../widgets/loader";


const GeneratePaymentTable = (props) => {

  const {
    acad_calender_id,
    student_id,
    semester_id,
    payment_amount_row_array,
    no_of_installments,
    set_loader,
    index,
    plan_id,
    upfront_payment_amount,
    onInstallmentUpdate,
  } = props;


  const payment_name1 = 'payment-' + (props.index + 1)
  const [page_loader, set_page_loader] = useState(true)
  const dateFormat = 'MM-DD-YYYY';
  const [table_list_2, set_table_list_2] = useState([]);
  const [payment_date, set_payment_date] = useState(payment_amount_row_array[index]?.payment_date);
  const [amount, set_amount] = useState(0);
  const [amount_due, set_amount_due] = useState(payment_amount_row_array[index]?.amount_due);
  const [payment_name, set_payment_name] = useState(payment_amount_row_array[index]?.payment_name);
  const [amount_paid_checked, set_amount_paid_checked] = useState(false);
  const [paid_status, set_paid_status] = useState('0')
  const [errors, set_errors] = useState([]);
  const [payment_plans_dates_amount_array, set_payment_plans_dates_amount_array] = useState('')



  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (e) => {
    setOpen(true);
    //  console.log("hi", e);
    //    set_paid_status(e.target.checked)
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const VIEW_PAYMENT_PLAN_TABLE = async () => {

    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", parseInt(student_id));
    FORM_DATA.append("semester_id", parseInt(semester_id));
    FORM_DATA.append("acad_calender_id", parseInt(acad_calender_id));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("plan_id", plan_id);


    const VIEW_PAYMENT_RESPONSE = await VIEW_PAYMENT_PLAN_TABLE_API(FORM_DATA);

    if (VIEW_PAYMENT_RESPONSE?.data?.status) {

      set_payment_plans_dates_amount_array(VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates);
      set_payment_date(VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates[index]?.payment_date)
      set_amount(VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates[index]?.amount)
      const apiAmount = parseInt(VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates[index]?.amount);
      const validAmount = isNaN(apiAmount) || apiAmount === undefined ? 0 : apiAmount
      onInstallmentUpdate(index, validAmount, VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates[index]?.paid)
      set_amount_due(VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates[index]?.amount_due)
      set_payment_name(VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates[index]?.payment_name)
      set_paid_status(VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates[index]?.paid == undefined ? '0' : ((VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates[index]?.paid) ? '1' : '0'))
      set_amount_paid_checked(VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates[index]?.amount ? 1 : 0)
    }

  }

  useEffect(() => {

    VIEW_PAYMENT_PLAN_TABLE();
    set_payment_date(payment_plans_dates_amount_array[index]?.payment_date)
    set_amount(payment_plans_dates_amount_array[index]?.amount)
    set_amount_due(payment_amount_row_array[index]?.amount_due)

  }, [set_loader]);



  const MANAGE_PAYMENT_INSTALLMENT = async () => {

    setConfirmLoading(true);
    const paidStatus = paid_status == "1" ? 1 : 0;
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", parseInt(props.student_id));
    FORM_DATA.append("user_id", parseInt(props.student_id));
    FORM_DATA.append("semester_id", parseInt(props.semester_id));
    FORM_DATA.append("acad_calender_id", parseInt(props.acad_calender_id));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("payment_name", payment_name);
    FORM_DATA.append("payment_date", payment_date);
    FORM_DATA.append("amount", amount);
    FORM_DATA.append("amount_due", amount_due);
    FORM_DATA.append("installment_no", index + 1);
    FORM_DATA.append("plan_id", props?.plan_id);
    FORM_DATA.append("paid", paid_status)

    const API_Response = await ADD_PAYMENT_INSTALLMENT_API(FORM_DATA);

    if (API_Response?.status) {

      setOpen(false);
      setConfirmLoading(false);
      set_amount_paid_checked(paid_status)
      set_paid_status(paid_status)
      onInstallmentUpdate(index, amount, paid_status);

    } else {

      setOpen(false);
      setConfirmLoading(false);

    }
  }
  return (
    <>
      {<>
        <div className="row billing-input-row">

          <div className="input-box col-1">
            <Input className="billing-input" value={(index + 1)} onChange={(e) => set_payment_name(e.target.value)} disabled />
          </div>

          <div className="input-box col-3">
            <DatePicker format={dateFormat} className="billing-input" value={payment_date && dayjs(payment_date)} onChange={(date, dateString) => set_payment_date(dateString)} />
            {errors?.payment_date && <><span style={{ color: "red" }}>{errors?.payment_date[0]}</span></>}
          </div>
          <div className="input-box col-2">
            <Input className="billing-input" value={amount} placeholder="amount" id='amount' onChange={(e) => set_amount(e.target.value)} />
          </div>

          <div className="input-box col-2">
            <Input  className="billing-input" value={amount_due} placeholder="amount_due" id='amount_due' onChange={(e) => set_amount_due(e.target.value)} />
          </div>
          <div className="input-box col-3">
            <Select
              placeholder="Payment Status"
              className="billing-input"
              value={paid_status == '1' ? 'paid' : 'unpaid'}
              onChange={(value) => set_paid_status(value)}
            >
              <Select.Option value='0' >
                unpaid
              </Select.Option>
              <Select.Option value='1'>
                paid
              </Select.Option>
            </Select>
          </div>
          <div className="input-box col-1">
            <Popconfirm
              title="Installment Update Alert!!"
              description="Are you sure to update the status of this Installment"
              open={open}
              onConfirm={MANAGE_PAYMENT_INSTALLMENT}
              okButtonProps={{
                loading: confirmLoading,
              }}
              onCancel={handleCancel}
            >

              <Button size="small" icon={<SyncOutlined />} style={{ fontSize:"10px" }} onClick={(e) => showPopconfirm(e)} >

              </Button>




            </Popconfirm>
          </div>

        </div>

      </>}
    </>
  );
}


export default GeneratePaymentTable