import { CloudDownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Button, Select, Tooltip } from "antd";
import { BACKEND_URL } from "../../../../config/config";
import { useEffect, useState } from "react";
import { CALENDARS_LIST } from "../../../../apis/apis";


const ALLAcademicReportDownload = () =>{
    const [academic_year, set_academic_year] = useState("");
    const [errors, set_errors] = useState([]);
    const [academic_year_list, set_academic_year_list] = useState([]);
    const [semester, set_semester] = useState("");
    const [sem_list, set_sem_list] = useState([]);
    const [calender_index, set_calender_index] = useState(0)

    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            const calendars = API_RESPONSE?.data?.calendars;
            set_academic_year_list(calendars);
            if (calendars.length > 0) {
              set_academic_year(calendars[0].id);
              set_sem_list(calendars[0].semesters);
              if (calendars[0].semesters.length > 0) {
                set_semester(calendars[0].semesters[0].id);
              }
            }
          }else {
          
        }
    }


    const calender_change_function = (value) => {
        set_semester('');
        set_errors('');
        const index = academic_year_list.findIndex(item => item.id === value)
        set_academic_year(value);
        set_calender_index(index);
        set_sem_list(academic_year_list[index].semesters);
    }

    useEffect(() => {
        LIST_API();
    }, []);

    return(
        <>
        <h3>Download Academic Reports</h3>
        <div className="row">
        <div style={{ width: "100%", float: 'right' }}>
          <div className="col-6">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Select Academic Year<i style={{ color: "red" }}>*</i>
              </label>
              <br />
              <Select
                placeholder="--Select Academic Year--"
                style={{ width: "100%" }}
                onChange={calender_change_function}
                value={academic_year ? parseInt(academic_year) : academic_year}
                options={academic_year_list?.map((item) => ({
                  value: item?.id,
                  calender_id: item?.id,
                  label: item?.title,
                }))}
              />
              {errors?.entering_year && (
                <>
                  <span style={{ color: "red" }}>
                    Please Select Academic Year
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Select Semester<i style={{ color: "red" }}>*</i>
              </label>
              <br />
              <Select
                placeholder="--Select Semester--"
                style={{ width: "100%" }}
                disabled={!academic_year}
                onChange={(value, option) => {
                  set_semester(value);
                }}
                value={semester}
                options={sem_list?.map((item) => ({
                  value: item?.id,
                  label: item?.title,
                }))}
              />
              {errors?.semester_id && (
                <>
                  <span style={{ color: "red" }}>
                    The semester field is required.
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>Report</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ paddingLeft: "15px" }}>Program Registration Report</td>
                        <td>
                        <Tooltip title="Download Program Registration Report CSV">
                            <Button type='primary'
                                onClick={() => window.location = BACKEND_URL + '/csv-program-registration-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + semester} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                           <Tooltip title="Download Program Registration Report PDF">
                                <Button type='primary' onClick={() => window.open(BACKEND_URL + '/program-registration-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + semester)} ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingLeft: "15px" }}>Enrollment Report</td>
                        <td>
                        <Tooltip title="Download Enrollment Report CSV">
                            <Button type='primary'
                                onClick={() => window.location = BACKEND_URL + '/csv-enrollment-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + semester} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                            <Tooltip title="Download Enrollment Report PDF">
                                <Button type='primary'
                                 onClick={() => window.open(BACKEND_URL + '/enrollment-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + semester)}
                                 ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingLeft: "15px" }}>Semester Registration Report </td>
                        <td>
                        <Tooltip title="Download Semester Registration Report CSV">
                            <Button type='primary'
                                onClick={() => window.location = BACKEND_URL + '/csv-semester-registration-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + semester} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                            <Tooltip title="Download Semester Registration Report PDF">
                                <Button type='primary'
                                 onClick={() => window.open(BACKEND_URL + '/semester-registration-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + semester)}
                                ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr>
                    {/* <tr>
                        <td style={{ paddingLeft: "15px" }}>Units Taken Report</td>
                        <td>
                        <Tooltip title="Download Units Taken Report CSV">
                            <Button type='primary'
                                onClick={() => window.open(BACKEND_URL + '/student-category-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + semester)} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                            <Tooltip title="Download Units Taken Report PDF">
                                <Button type='primary'
                                 onClick={() => window.open(BACKEND_URL + '/student-category-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + semester)}
                                 ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr> */}
                    <tr>
                        <td style={{ paddingLeft: "15px" }}>List of Faculty Report</td>
                        <td>
                        <Tooltip title="Download List of Faculty Report CSV">
                            <Button type='primary'
                                onClick={() => window.location = BACKEND_URL + '/csv-list-of-faculty-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + semester} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>
                            <Tooltip title="Download List of Faculty Report PDF">
                                <Button type='primary'
                                 onClick={() => window.open(BACKEND_URL + '/list-of-faculty-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token + "/" + semester)}
                                 ghost style={{ marginLeft: "10px" }}><FilePdfOutlined /></Button>
                            </Tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default ALLAcademicReportDownload;