import React from "react";
import { Button, Table, Tooltip } from "antd";
import MultipleCoursePrint from "../../MultipleCoursePrint/multipleCoursePrint";
import { CloudDownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { sisDateFormat } from "../../../../apis/apis";
import { BACKEND_URL } from "../../../../config/config";

const AdvancedFinancialReportSummaryTable = ({table_list,academic_year,semester,entering_classification,programme_intended,payment_status,payment_method,fees_type,account_balance_status}) => {
  const columns = [
    {
      title: "Student Name",
      render: (text, record) => {
        return (
          <>
            {record?.student?.first_name}{" "}
            {record?.student?.middle_name == "null"
              ? ""
              : record?.student?.middle_name}{" "}
            {record?.student?.last_name}
          </>
        );
      },
    },
    {
      title: "Student Id",
      render: (text, record) => {
        return <>{record?.student?.student_id}</>;
      },
    },
    {
      title: "Email",
      render: (text, record) => {
        return <>{record?.student?.primary_email}</>;
      },
    },
    // {
    //   title: "Account Balance",
    //   render: (text, record) => {
    //     return (
    //       <>
    //         <span style={{ textTransform: "capitalize" }}></span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Tution Charges",
      render: (text, record) => {
        return <>${record?.total_tuition_fee}</>;
      },
    },
    {
      title: "Fees",
      render: (text, record) => {
        const fees = {
          semester_registrations_fees:
            record?.semester_registration_fees?.semester_registrations_fees,
          late_registrations_fees:
            record?.semester_registration_fees?.late_registrations_fees,
          health_insurance:
            record?.semester_registration_fees?.health_insurance,
          late_charges: record?.semester_registration_fees?.late_charges,
          material_fee: record?.semester_registration_fees?.material_fee,
          test_fee: record?.semester_registration_fees?.test_fee,
          student_body: record?.semester_registration_fees?.student_body,
          id_card_fee: record?.semester_registration_fees?.id_card_fee,
          note_fee: record?.semester_registration_fees?.note_fee,
          publication_fee: record?.semester_registration_fees?.publication_fee,
          ecg_lab_fee: record?.semester_registration_fees?.ecg_lab_fee,
          tuition_recover_fee:
            record?.semester_registration_fees?.tuition_recover_fee,
          library_fee: record?.semester_registration_fees?.library_fee,
          lab_fee: record?.semester_registration_fees?.lab_fee,
        };

        // Filter out keys with null or "NaN" values
        const filteredFees = Object?.entries(fees).filter(
          ([, value]) =>
            value !== null &&
            value !== undefined &&
            value !== "null" &&
            value !== "NaN" &&
            value !== "undefined"
        );

        // If there are no fees, render nothing
        if (filteredFees?.length === 0)
          return <>No Course Semester Registration</>;

        const formatKey = (str) =>
          str?.replace(/_/g, " ")?.replace(/\b\w/g, (char) => char?.toUpperCase());

        return (
          <div>
        {filteredFees?.map(([key, value]) => (
          <p key={key}>{`${formatKey(key)}: $${value}`}</p>
        ))}
      </div>
        );
      },
    },
    {
      title: "Payments Plans",
      render: (text, record) => {
        return (
          <>
            {record?.payment_plans_dates?.length > 0 ? (
              record.payment_plans_dates.map((plan) => (
                <div key={plan?.id}>
                  <strong>{plan?.payment_name}:</strong> Due on{" "}
                  {sisDateFormat(plan?.payment_date)}, Amount: {plan?.amount} -{" "}
                  {plan.paid ? "Paid" : "Not Paid"}
                </div>
              ))
            ) : (
              <div>No Payment Plan</div>
            )}
          </>
        );
      },
    },
    // {
    //   title: "Refund Status",
    //   dataIndex: "Refund Status",
    //   key: "Refund Status",
    // },
  ];

  return (
    <>
      <div
        style={{ display: "inline-block", float: "right", marginRight: "10px" }}
      >
        {/* CSV Download button  */}
        <Tooltip title="Download Financial Report CSV">
          <Button
            type="primary"
            onClick={() => window.open(BACKEND_URL + '/csv-financial-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token +'/'+ academic_year +'/'+ semester+'/'+ entering_classification +'/'+ programme_intended +'/'+ payment_status+'/'+ payment_method +'/'+ fees_type+'/'+ account_balance_status)} 
            ghost
            style={{ marginLeft: "5px" }}
          >
            <CloudDownloadOutlined />
          </Button>
        </Tooltip>
        {/* PDF Download button  */}
        <Tooltip title="Download Financial Report PDF">
          <Button
            type="primary"
            onClick={() => window.open(BACKEND_URL + '/pdf-financial-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token +'/'+ academic_year +'/'+ semester+'/'+ entering_classification +'/'+ programme_intended +'/'+ payment_status+'/'+ payment_method +'/'+ fees_type+'/'+ account_balance_status)} 

            ghost
            style={{ marginLeft: "5px" }}
          >
            <FilePdfOutlined />
          </Button>
        </Tooltip>
      </div>
      <Table columns={columns} pagination={true} dataSource={table_list} />
    </>
  );
};

export default AdvancedFinancialReportSummaryTable;
