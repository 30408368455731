import React, { useEffect, useState } from 'react';
import RecordAttendanceClassTimingList from './recordAttendanceClassTimingList';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { DatePicker, Select } from 'antd';
import { ALL_SEMESTERS_LIST, FACULTIES_LIST } from '../../apis/apis';
import SisLoader from '../../widgets/loader';
import dayjs from 'dayjs';

const RecordAttendanceClass = () => {
    const dateFormat = 'MM-DD-YYYY';
    const [loader, setLoader] = useState(true)
   
    const [sem_table_list, set_sem_table_list] = useState([]);
    const [faculty_id, set_faculty_id] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [selected24Time, setSelected24Time] = useState('');
    const [facultytablelist, setFacultyTableList] = useState([]);
    const [semesterid, setSemesterId] = useState('');
    const check_permissions = (module_name, type) => {
        const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if(localStorgeData.role === 2074){
            return true;
        }
        var check_permissions = [];
        if (type === 'view') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
      }
        if (type === 'create') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
        }
        if (type === 'update') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
        }
        if (type === 'status') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
        }
        if (type === 'delete') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
        }
    
        if (check_permissions.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    
    const hours = [
        {
            print_time: "12:00 AM",
            time_24: 0,
        },
        {
            print_time: "1:00 AM",
            time_24: 1,
        },
        {
            print_time: "2:00 AM",
            time_24: 2,
        },
        {
            print_time: "3:00 AM",
            time_24: 3,
        },
        {
            print_time: "4:00 AM",
            time_24: 4,
        },
        {
            print_time: "5:00 AM",
            time_24: 5,
        },
        {
            print_time: "6:00 AM",
            time_24: 6,
        },
        {
            print_time: "7:00 AM",
            time_24: 7,
        },
        {
            print_time: "8:00 AM",
            time_24: 8,
        },
        {
            print_time: "9:00 AM",
            time_24: 9,
        },
        {
            print_time: "10:00 AM",
            time_24: 10,
        },
        {
            print_time: "11:00 AM",
            time_24: 11,
        },
        {
            print_time: "12:00 PM",
            time_24: 12,
        },
        {
            print_time: "1:00 PM",
            time_24: 13,
        },
        {
            print_time: "2:00 PM",
            time_24: 14,
        },
        {
            print_time: "3:00 PM",
            time_24: 15,
        },
        {
            print_time: "4:00 PM",
            time_24: 16,
        },
        {
            print_time: "5:00 PM",
            time_24: 17,
        },
        {
            print_time: "6:00 PM",
            time_24: 18,
        },
        {
            print_time: "7:00 PM",
            time_24: 19,
        },
        {
            print_time: "8:00 PM",
            time_24: 20,
        },
        {
            print_time: "9:00 PM",
            time_24: 21,
        },
        {
            print_time: "10:00 PM",
            time_24: 22,
        },
        {
            print_time: "11:00 PM",
            time_24: 23,
        }
    ];

    const DATE_CLASS = new Date();
    const current_month = DATE_CLASS.getMonth() < 9 ? '0' + (DATE_CLASS.getMonth() + 1) : DATE_CLASS.getMonth();
    const today_date =  current_month + '-' + DATE_CLASS.getDate() +  '-' + DATE_CLASS.getFullYear();
    const default_date = current_month + '-' + DATE_CLASS.getDate() + '-' + DATE_CLASS.getFullYear();
    
    const [selectedDate, setSelectedDate] = useState(today_date);
    // Get the current hour, minute, and second
    var new_hours = DATE_CLASS.getHours();
    var minutes = DATE_CLASS.getMinutes();

    // Determine if it's AM or PM
    var meridiem = new_hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    new_hours = new_hours % 12;
    new_hours = new_hours ? new_hours : 12; // Handle midnight (0 hours)

    // Pad minutes with leading zero if necessary
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Format the time nicely
    const currentTime = new_hours + ":" + minutes + " " + meridiem;
    const current24Time = DATE_CLASS.getHours();
    const LIST_API = async () => {
        const FACULTIES_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', FACULTIES_DATA.token);
        const API_RESPONSE = await FACULTIES_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            setFacultyTableList(API_RESPONSE?.data?.faculty);
            setLoader(false);
        } else {
            setLoader(false);
        }
    };

    
    // DB list
    const ALL_SEMESTER_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        // FORM_DATA.append('calendar_id', calendar_id);
        const API_RESPONSE = await ALL_SEMESTERS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_sem_table_list(API_RESPONSE?.data?.academic_semester);
            setSemesterId(API_RESPONSE?.data?.academic_semester[0]?.id);
            setLoader(false);
        } else {
            setLoader(false);
        }
    }


    useEffect(() => {
        
        // setSelectedTime(currentTime)
        // setSelected24Time(current24Time)
        LIST_API()
    }, [selectedDate,semesterid])

    useEffect(() => {
      ALL_SEMESTER_LIST_API();
    }, [])

    const onChange = (date, dateString) => {
        setSelectedDate(dateString)
      };

    const sliderClick = (selected_date, click_type) => {
        const currentDate = new Date();
        const nextDate = new Date(selected_date);

        if (click_type === 'plus') {
            nextDate.setDate(nextDate.getDate() + 1);
        } else {
            nextDate.setDate(nextDate.getDate() - 1);
        }

        const new_month = nextDate.getMonth() < 9 ? '0' + (nextDate.getMonth() + 1) : nextDate.getMonth() + 1;
        const new_date = nextDate.getFullYear() + '-' + ('0' + new_month).slice(-2) + '-' + ('0' + nextDate.getDate()).slice(-2);

        setSelectedDate(new_date);
    }

    const getDayOfWeek = (selectedDate) => {
        var selectedDate = new Date(selectedDate);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayIndex = selectedDate.getDay();
        return days[dayIndex];
    };

    const getCurrentDate = (selectedDate) => {
        var selectedDate = new Date(selectedDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return selectedDate.toLocaleDateString('en-US', options);
    };


    const compareTimes = (time1, time2) => {
        if (time1 >= time2 && time1 <= time2) {
            return 1;
        } else {
            return 0;
        }
    }

    return (
        <>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h2> Record Attendance</h2>
                </div>
            </div>
            {(check_permissions('attendances', 'update'))&&
            <div className='row'>
            <div className='col-3'>
                <span>Select Semesters</span>
                    <Select value={semesterid} onChange={(value) => setSemesterId(value)} style={{ width: "100%", margin: "10px 0" }} placeholder="--Select Semester--">
                        {sem_table_list?.length > 0 && <>
                            {sem_table_list?.map(itemv => (
                                <><Select.Option value={itemv.id}>{itemv.title}</Select.Option></>
                            ))}
                        </>}
                    </Select>
                </div>
                <div className='col-3'>
                <span> Select Faculty</span>
                    <Select value={faculty_id} onChange={(value) => set_faculty_id(value)} style={{ width: "100%", margin: "10px 0" }} placeholder="--Select Faculty---">
                        {facultytablelist?.length > 0 && <>
                            <><Select.Option value={''}>All</Select.Option></>
                            {facultytablelist?.map(itemv => (
                                <><Select.Option value={itemv.id}>{itemv.name}</Select.Option></>
                            ))}
                        </>}
                    </Select>
                </div>
                <div className='col-3' >
                <span>Select Date</span>
                <DatePicker style={{ width: "100%", margin: "10px 0" }}
                 format={dateFormat}
                 defaultValue={dayjs(default_date)}
                 onChange={onChange}/>
                </div>
            </div>}
            {loader ? <SisLoader /> : <>
            
                <div style={{ display: 'flex', alignItems: 'center', marginTop:"15px" }}>
                    {/* <LeftOutlined className='Icon_Styling_left ' onClick={() => { sliderClick(selectedDate, 'min') }} /> */}
                    <h3 style={{ fontSize: "20px" }}>{getDayOfWeek(selectedDate)} {getCurrentDate(selectedDate)}</h3>
                    {/* <RightOutlined className='Icon_Styling_right ' onClick={() => sliderClick(selectedDate, 'plus')} /> */}
                </div>
                <br />
                <hr />
                < RecordAttendanceClassTimingList  faculty_id={faculty_id} class_date={selectedDate} semester_id={semesterid}  />
                <ul>
                  
                </ul>
            </>}

        </>
    );
}

export default RecordAttendanceClass;
