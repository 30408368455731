import React, { useEffect, useState } from "react";
import { Tabs, notification } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Flex } from "antd";
import {
  UploadOutlined,
  PaperClipOutlined,
  EditOutlined,
  SmileOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
// import { PaperClipOutlined } from '@ant-design/icons';

import "./student.css";
import {
  SIS_STORAGE,
  STUDENTS_DOCUMENTS_ADD,
  STUDENT_DOCUMENTS_LIST,
  STUDENT_PROFILE,
  sisDateFormat,
} from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";

const { TabPane } = Tabs;

const StudentsDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [personal_details, set_personal_details] = useState("");
  const [contact_details, set_contact_details] = useState("");
  const [student_documents, set_student_documents] = useState("");
  const [enrollments_documents_details, set_enrollments_documents_details] =
    useState("");

  const [loader, set_loader] = useState(true);
  const [mailing_address, set_mailing_address] = useState("");
  const [home_country_address, set_home_country_address] = useState("");
  const [page_loader, set_page_loader] = useState(true);
  const [staff_details, set_staff_details] = useState("");
  const [student_documents_list, set_student_documents_list] = useState([]);

  const location = useLocation();
  const profilePicUrl = location.state?.student?.profile_pic;
  const [selectedFiles, setSelectedFiles] = useState(
    Array(7)
      .fill(null)
      .map(() => ({ file: null, fileName: "" }))
  );

  // Document
  const [enrollments_agreements, set_enrollments_agreements] = useState("");
  const [
    high_school_diploma_and_transcripts,
    set_high_school_diploma_and_transcripts,
  ] = useState("");
  const [
    transcripts_from_higher_educational,
    set_transcripts_from_higher_educational,
  ] = useState("");
  const [resume, set_resume] = useState("");
  const [
    photocopy_of_passport_or_equivalent_documents,
    set_photocopy_of_passport_or_equivalent_documents,
  ] = useState("");
  const [proof_of_english, set_proof_of_english] = useState("");
  const [essay_explaining_interest, set_essay_explaining_interest] =
    useState("");

    const check_permissions = (module_name, type) => {
      const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
      if(localStorgeData.role === 2074){
          return true;
      }
      var check_permissions = [];
      if (type === 'view') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
    }
      if (type === 'create') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
      }
      if (type === 'update') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
      }
      if (type === 'status') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
      }
      if (type === 'delete') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
      }
  
      if (check_permissions.length > 0) {
          return true;
      } else {
          return false;
      }
  }

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/pdf" || file.type.includes("image/"))
    ) {
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles[index] = { file, fileName: file.name };
      setSelectedFiles(newSelectedFiles);
    } else {
      alert("Please upload only PDF or image files.");
    }
  };

  const handleUploadClick = (index) => {
    document.getElementById(`fileInput${index}`).click();
  };

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", atob(id));
    FORM_DATA.append("token", USER_DATA.token);

    const EDIT_API_RESPONSE = await STUDENT_PROFILE(FORM_DATA);

    if (EDIT_API_RESPONSE?.data?.status) {
      set_personal_details(EDIT_API_RESPONSE?.data?.data?.personal_details);
      set_contact_details(EDIT_API_RESPONSE?.data?.data?.contact_details);
      set_mailing_address(
        JSON.parse(
          EDIT_API_RESPONSE?.data?.data?.contact_details?.mailing_address
        )
      );
      set_home_country_address(
        JSON.parse(
          EDIT_API_RESPONSE?.data?.data?.contact_details?.home_country_address
        )
      );
      set_student_documents(EDIT_API_RESPONSE?.data?.data?.student_documents);
      set_enrollments_documents_details(
        EDIT_API_RESPONSE?.data?.enrollments_documents_details
      );
      set_staff_details(EDIT_API_RESPONSE?.data?.data?.staff_details);
      set_page_loader(false);

    }
  };
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", atob(id));
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await STUDENT_DOCUMENTS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_student_documents_list(API_RESPONSE?.data?.studentDocuments);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  useEffect(() => {
    VIEW_API();
    LIST_API();
  }, [loader]);
  const ADD_API = async (type, document_file) => {
    set_page_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("document_type", type);
    FORM_DATA.append("document_file", document_file);
    FORM_DATA.append("student_id", atob(id));
    FORM_DATA.append("user_type", "staff");
    const API_RESPONSE = await STUDENTS_DOCUMENTS_ADD(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "User document Successfully Uploaded....",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(true);
    } else {
      notification.open({
        message: "Error!!",
        description: API_RESPONSE?.data?.errors.document_file[0],
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
      set_loader(true);
    }
  };
  const handleImageChange = (e, type) => {
    const files = e.target.files;
    const fSize = Math.round(files[0].size / 1024);
    const maxSize = 3072; // Allowing file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)/;
    if (!allowedExtensions.exec(files[0]) && fSize < maxSize) {
      ADD_API(type, files[0]);
    } else {
      notification.open({
        message: "Not Allowed",
        description:
          "Please check file format must be .jpg or .png and Max. file size 3Mb",
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
    }
  };

  const get_document_type_list = (type) => {
    var local_student_documents_list = student_documents_list;
    console.log(local_student_documents_list);
    if (local_student_documents_list.length > 0) {
      local_student_documents_list = local_student_documents_list.filter(
        (doc) => doc?.document_type === type
      );
      if (local_student_documents_list.length > 0) {
        return (
          <>
            {local_student_documents_list.map((item) => (
              <>
                <li
                  onClick={() =>
                    window.open(
                      SIS_STORAGE + "/enrollments/" + item?.document_file
                    )
                  }
                >
                  {item?.document_file}
                </li>
              </>
            ))}
          </>
        );
      }
    }
  };
  const get_date_format = (s_date) => {
    const date = new Date(s_date);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div className="sis-tab-menu">
          {check_permissions('enrollments', 'view') &&
            <span className="active">Profile</span>}
             {check_permissions('attendances', 'view') &&
            <span onClick={() => navigate("/student-academic-detail/" + id)} >Academic</span>}
             {check_permissions('students_grades', 'view') &&
            <span onClick={() => navigate("/students-grade-detail/" + id)} >Grades</span>}
          </div>
          <div>
            <div className="row">
              <div
                className="col-2"
                style={{ maxWidth: "100px", minWidth: "100px" }}  >
                <img
                  style={{
                    width: "78px",
                    height: "78px",
                    borderRadius: "14px",
                    marginleft: "-12px",
                  }}
                  src={SIS_STORAGE + "/enrollments/" + personal_details?.image}
                 
                  alt="Profile Picture" />
              </div>
              <div className="col-8">
                <h3 style={{
                  marginBottom: "10px", width: "260px",
                  fontFamily: "roboto",
                  fontSize: "24px",
                  fontWeight: "401",
                }}>
                  {personal_details?.first_name}{" "}
                  {personal_details?.middle_name !== "null" &&
                    personal_details?.middle_name &&
                    personal_details?.middle_name}{" "}
                  {personal_details?.last_name}{" "}
                </h3>
                <div className="row">
                  <div className="col-3">
                    <p>Application number</p>
                    <h4 style={{ marginTop: "6px" }}>  {personal_details?.application_no}</h4>
                  </div>
                  <div className="col-3">
                    <p>Student ID</p>
                    <h4 style={{ marginTop: "6px" }}> {personal_details?.student_id}</h4>
                  </div>
                   {check_permissions('enrollments', 'update') &&
                  <div className="col-3" style={{ textAlign: "left" }}>
                    <Button
                    className="lusisbtn"
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => navigate("/edit-student-model/" + id)}
                    >
                      Edit
                    </Button>
                  </div>}
                </div>
              </div>
            </div>
            <div className="frame-427318301" style={{ marginTop: "15px" }}>
              <div className="personal-details">Personal Details</div>
              <div className="frame-427318267">
                <div className="frame-427318261">
                  <div className="birth-date">Birth Date</div>
                  <span className="feb-1990">
                    {sisDateFormat(personal_details?.birth_date)}
                  </span>
                </div>
                <div className="frame-427318262">
                  <div className="country-of-birth">Country of birth</div>
                  <span className="india">
                    {personal_details?.country_of_birth}
                  </span>
                </div>
                <div className="frame-427318263">
                  <div className="birth-placecity">Birth place/city</div>
                  <span className="jaipur">
                    {personal_details?.city_of_birth}
                  </span>
                </div>
                <div className="frame-427318265">
                  <div className="student-category">Student Category</div>
                  <span className="international">
                    {personal_details?.student_category}
                  </span>
                </div>
                <div className="frame-4273182671">
                  <div className="gender">Gender</div>
                  <span className="male">{personal_details?.gender}</span>
                </div>
              </div>
              <div className="frame-427318269">
                <div className="frame-4273182641">
                  <div className="citizenship">Citizenship</div>
                  <span className="indian">
                    {personal_details?.citizenship}
                  </span>
                </div>
                <div className="frame-4273182641">
                  <div className="citizenship">SSN</div>
                  <span className="indian">{personal_details?.ssn === 'null' ? "N/A" :personal_details?.ssn}</span>
                </div>
              </div>
            </div>
          </div>
          <hr
            style={{
              border: "1px solid rgba(238, 238, 238, 1)",
            }} />

          {/* <div> */}

          {/* <div className="frame-427318304"> */}
          
          <div className="contact-details">Contact Details</div>

          <div className="row">
            <div className="col-3">
              <label>
                primary email
              </label>
              <br></br>
            </div>
            <div className="col-3">
              <label>
                secondary email
              </label>
              <br></br>
            </div>
            <div className="col-2">
              <label>
                Phone Number
              </label>
              <br></br>
            </div>
            <div className="col-2">
              <label>
                Emergency Mobile
              </label>
              <br></br>
            </div>
          </div>


          <div className="row">
            <div className="col-3 student-profile-information">
              <label className="feb-1990" style={{textTransform:"none"}} >
                {contact_details?.primary_email}
              </label>
              <br></br>
            </div>
            <div className="col-3 student-profile-information">
              <label className="feb-1990" style={{textTransform:"none"}}  >
                {contact_details?.secondary_email ? contact_details?.secondary_email : 'N/A'}
              </label>
              <br></br>
            </div>
            <div className="col-2 student-profile-information">
              <label className="feb-1990" style={{textTransform:"none"}} >
                {contact_details?.phone}
              </label>
              <br></br>
            </div>
            <div className="col-2 student-profile-information">
              <label className="feb-1990" style={{textTransform:"none"}}>
                {contact_details?.phone}
              </label>
              <br></br>
            </div>
          </div>
          <br />
          <div className="frame-427318302">
            <div className="current-mailing-address">
              Current Mailing Address
            </div>

            <span className="bristol-avenue-broad-street-4-th-lane-new-york-usa-99090">
              {mailing_address?.mailing_address1},{" "}
              {mailing_address?.mailing_address2 ? mailing_address?.mailing_address2 : ''},{" "}
              {mailing_address?.mailing_address_city},{" "}
              {mailing_address?.mailing_address_country},{" "}
              {mailing_address?.mailing_address_postal}.
            </span>
          </div>
          <div className="frame-427318303">
            <div className="home-address">Home Address</div>
            <span className="b-22-krishna-colony-manohar-nagar-jaipur-india-302025">
              {home_country_address?.home_country_address1},{" "}
              {home_country_address?.home_country_address2 ? home_country_address?.home_country_address2 : ''},{" "}
              {home_country_address?.home_country_address_city},{" "}
              {home_country_address?.home_country_address_country},{" "}
              {home_country_address?.home_country_address_postal}.
            </span>
          </div>
          {/* </div> */}
          {/* </div> */}

          <hr style={{ border: "1px solid rgba(238, 238, 238, 1)" }} />
          <h1
            style={{
              fontWeight: "401",
              fontSize: "18px",
              color: "var(--neutral-500, #8E8E8E)",
            }} >
            Documents
          </h1>
          {page_loader ? (
            <SisLoader />
          ) : (

            <>
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="col-3" style={{ position: "relative" }}>
                  <h4 style={{ color: "#8E8E8E" }}>Enrollment agreement</h4>
                  <ul className="document-list">
                    <li
                      title={student_documents?.enrollments_agreements}
                      onClick={() =>
                        window.open(
                          SIS_STORAGE +
                          "/enrollments/" +
                          student_documents?.enrollments_agreements
                        )} >
                      {student_documents?.enrollments_agreements}
                    </li>
                    {get_document_type_list("enrollments_agreements")}
                  </ul>
                </div>

                <div className="col-3" style={{ position: "relative" }}>
                  <h4 style={{ color: "#8E8E8E" }}>High school diploma and transcripts</h4>
                  <ul className="document-list">
                    <li
                      title={student_documents?.high_school_diploma_and_transcripts}
                      onClick={() =>
                        window.open(
                          SIS_STORAGE +
                          "/enrollments/" +
                          student_documents?.high_school_diploma_and_transcripts
                        )
                      }  >
                      {student_documents?.high_school_diploma_and_transcripts}
                    </li>
                    {get_document_type_list(
                      "high_school_diploma_and_transcripts"
                    )}
                  </ul>
                </div>

                <div className="col-3" style={{ position: "relative" }}>
                  <h4 style={{ color: "#8E8E8E" }}>Transcription from higher education</h4>
                  <ul className="document-list">
                    <li
                      title={student_documents?.transcripts_from_higher_educational}
                      onClick={() =>
                        window.open(
                          SIS_STORAGE +
                          "/enrollments/" +
                          student_documents?.transcripts_from_higher_educational
                        )
                      } >
                      {student_documents?.transcripts_from_higher_educational}
                    </li>
                    {get_document_type_list(
                      "transcripts_from_higher_educational"
                    )}
                  </ul>
                </div>
                <div className="col-3" style={{ position: "relative", paddingLeft: "33px" }}>
                  <h4 style={{ color: "#8E8E8E" }}>Resume</h4>
                  <ul className="document-list">
                    <li
                      title={student_documents?.resume}
                      onClick={() =>
                        window.open(
                          SIS_STORAGE +
                          "/enrollments/" +
                          student_documents?.resume
                        )
                      }  >
                      {student_documents?.resume}
                    </li>
                    {get_document_type_list("resume")}
                  </ul>
                </div>
              </div>
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="col-3" style={{ position: "relative" }}>
                  <h4 style={{ color: "#8E8E8E" }}>Passport</h4>
                  <ul className="document-list">
                    <li
                      title={
                        student_documents?.photocopy_of_passport_or_equivalent_documents
                      }
                      onClick={() =>
                        window.open(
                          SIS_STORAGE +
                          "/enrollments/" +
                          student_documents?.photocopy_of_passport_or_equivalent_documents
                        )
                      }
                    >
                      {
                        student_documents?.photocopy_of_passport_or_equivalent_documents
                      }
                    </li>
                    {get_document_type_list(
                      "photocopy_of_passport_or_equivalent_documents"
                    )}
                  </ul>
                </div>
                <div className="col-3" style={{ position: "relative" }}>
                  <h4 style={{ color: "#8E8E8E" }}>Proof of English</h4>
                  <ul className="document-list">
                    <li
                      title={student_documents?.proof_of_english}
                      onClick={() =>
                        window.open(
                          SIS_STORAGE +
                          "/enrollments/" +
                          student_documents?.proof_of_english
                        )
                      }
                    >
                      {student_documents?.proof_of_english}
                    </li>
                    {get_document_type_list("proof_of_english")}
                  </ul>
                </div>
                <div className="col-3" style={{ position: "relative" }}>
                  <h4 style={{ color: "#8E8E8E" }}>Essay explaining interest</h4>
                  <ul className="document-list">
                    <li
                      title={student_documents?.essay_explaining_interest}
                      onClick={() =>
                        window.open(
                          SIS_STORAGE +
                          "/enrollments/" +
                          student_documents?.essay_explaining_interest
                        )
                      }
                    >
                      {student_documents?.essay_explaining_interest}
                    </li>
                    {get_document_type_list("essay_explaining_interest")}
                  </ul>
                </div>
              </div>
            </>
          )}
          <br />
          
        
          {staff_details && (
            <>
              <div style={{ color: "#8E8E8E" }}>
                Last updated by:{" "}
                <b style={{ color: "#C7797A" }}>{staff_details.name}</b>{" "}
                {get_date_format(student_documents.updated_at)}
              </div>
              <br />
            </>
          )}
        </>
      )}
    </>
  );
};

export default StudentsDetails;
