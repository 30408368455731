import React, { useEffect, useState} from 'react';
import { Button, Checkbox, DatePicker, Input, Radio, Select, Spin, Table, notification } from 'antd';
import './style.css';
import { TableColumnsType, TableProps } from 'antd';
import { CloseOutlined, CloudUploadOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { ADD_STUDENT, ADD_USER, APPLICATION_DOCUMENT, APPLICATION_DOCUMENT_VIEW, EDIT_STUDENT, EMERGENCY_CONTACT, EMERGENCY_CONTACT_VIEW, PROGRAMS_LIST, ROLE_LIST, SIS_STORAGE, UPDATE_STUDENT } from '../../apis/apis';
import SisLoader from '../../widgets/loader';
import Item from 'antd/es/list/Item';
import { useNavigate, useParams } from 'react-router-dom';
import { CountryDropdown } from 'react-country-region-selector';
import dayjs from 'dayjs';

const StudentDocuments = () => {
    const { id } = useParams();
    const dateFormat = 'YYYY-MM-DD';
    const DATE_CLASS = new Date();
    const current_month = DATE_CLASS.getMonth() < 9 ? '0' + (DATE_CLASS.getMonth() + 1) : DATE_CLASS.getMonth();
    const today_date = DATE_CLASS.getFullYear() + '-' + current_month + '-' + DATE_CLASS.getDate();


    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const [programme_list, set_programme_list] = useState([]);


    // Form State


    const [image, set_image] = useState('');
    const [photo, set_photo] = useState('');
    const [high_school_diploma_and_transcripts, set_high_school_diploma_and_transcripts] = useState('');
    const [transcripts_from_higher_educational, set_transcripts_from_higher_educational] = useState('');
    const [resume, set_resume] = useState('');
    const [photocopy_of_passport_or_equivalent_documents, set_photocopy_of_passport_or_equivalent_documents] = useState('');
    const [proof_of_english, set_proof_of_english] = useState('');
    const [essay_explaining_interest, set_essay_explaining_interest] = useState('');

    const [api_photo, set_api_photo] = useState('');
    const [api_high_school_diploma_and_transcripts, set_api_high_school_diploma_and_transcripts] = useState('');
    const [api_transcripts_from_higher_educational, set_api_transcripts_from_higher_educational] = useState('');
    const [api_resume, set_api_resume] = useState('');
    const [api_photocopy_of_passport_or_equivalent_documents, set_api_photocopy_of_passport_or_equivalent_documents] = useState('');
    const [api_proof_of_english, set_api_proof_of_english] = useState('');
    const [api_essay_explaining_interest, set_api_essay_explaining_interest] = useState('');


    const [recruiter, set_recruiter] = useState('');

    const PROGRAMS_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
        if (API_RESPONSE.data.status) {
            set_programme_list(API_RESPONSE.data.programmes);
        } else {
            set_loader(false);
        }
    }
    const VIEW_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('student_id', atob(id));
        FORM_DATA.append('token', USER_DATA.token);
        const EDIT_API_RESPONSE = await APPLICATION_DOCUMENT_VIEW(FORM_DATA);
        if (EDIT_API_RESPONSE?.data?.status && EDIT_API_RESPONSE?.data?.applicationDocuments) {
            set_api_photo(EDIT_API_RESPONSE?.data?.applicationDocuments.photo);
            set_api_high_school_diploma_and_transcripts(EDIT_API_RESPONSE?.data?.applicationDocuments.high_school_diploma_and_transcripts);
            set_api_transcripts_from_higher_educational(EDIT_API_RESPONSE?.data?.applicationDocuments.transcripts_from_higher_educational);
            set_api_resume(EDIT_API_RESPONSE?.data?.applicationDocuments.resume);
            set_api_photocopy_of_passport_or_equivalent_documents(EDIT_API_RESPONSE?.data?.applicationDocuments.photocopy_of_passport_or_equivalent_documents);
            set_api_proof_of_english(EDIT_API_RESPONSE?.data?.applicationDocuments.proof_of_english);
            set_api_essay_explaining_interest(EDIT_API_RESPONSE?.data?.applicationDocuments.essay_explaining_interest);
            set_recruiter(EDIT_API_RESPONSE?.data?.applicationDocuments.recruiter);
        }
    }
    useEffect(() => {
        PROGRAMS_LIST_API();
        VIEW_API();
    }, []);



    const handleImageChange = (e, type) => {
        const files = e.target.files;
        const maxSize = 33 * 1024 * 1024;
        if ((files[0].type === 'image/jpeg' || files[0].type === 'image/jpg' || files[0].type === 'image/png'|| files[0].type === 'application/pdf') && files[0].size < maxSize) {
            if (type === 'high_school_diploma_and_transcripts') {
                set_high_school_diploma_and_transcripts(files[0])
            }
            if (type === 'resume') {
                set_resume(files[0])
            }
            if (type === 'photo') {
                set_photo(files[0])
            }
            if (type === 'photocopy_of_passport_or_equivalent_documents') {
                set_photocopy_of_passport_or_equivalent_documents(files[0])
            }
            if (type === 'proof_of_english') {
                set_proof_of_english(files[0])
            }
            if (type === 'transcripts_from_higher_educational') {
                set_transcripts_from_higher_educational(files[0])
            }
            if (type === 'essay_explaining_interest') {
                set_essay_explaining_interest(files[0])
            }
            
        } else {
            notification.open({
                message: 'Not Allowed',
                description: 'Please check file format must be.jpg or .png and Max. file size 33.65 Mb',
                icon: <SmileOutlined style={{ color: 'red' }} />,
            });
        }
    }
    const handleRemoveImage = (e, type) => {
        if (type === 'high_school_diploma_and_transcripts') {
           set_high_school_diploma_and_transcripts('')
        }
        if (type === 'resume') {
            set_resume('')
        }
        if (type === 'photo') {
            set_photo('')
        }
        if (type === 'photocopy_of_passport_or_equivalent_documents') {
            set_photocopy_of_passport_or_equivalent_documents('')
        }
        if (type === 'transcripts_from_higher_educational') {
            set_transcripts_from_higher_educational('')
        }
        if (type === 'proof_of_english') {
            set_proof_of_english('')
        }
        if (type === 'essay_explaining_interest') {
            set_essay_explaining_interest('')
        }
    }

    const ADD_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_id', atob(id));
        FORM_DATA.append('photo', photo);
        FORM_DATA.append('high_school_diploma_and_transcripts', high_school_diploma_and_transcripts);
        FORM_DATA.append('transcripts_from_higher_educational', transcripts_from_higher_educational);
        FORM_DATA.append('resume', resume);
        FORM_DATA.append('photocopy_of_passport_or_equivalent_documents', photocopy_of_passport_or_equivalent_documents);
        FORM_DATA.append('proof_of_english', proof_of_english);
        FORM_DATA.append('essay_explaining_interest', essay_explaining_interest);
        FORM_DATA.append('recruiter', recruiter);
        const API_RESPONSE = await APPLICATION_DOCUMENT(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Student Registration Successfully done.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/registration-list/');
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }


    return (
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Application Documents</h3>
                </div>
                <div className='theme-content-right-head'>

                </div>
            </div>
            <div className='common-form'>
                <div className='form-step-box'>
                    <div className={'form-step active'}>
                        <div className='form-step-line'></div>
                        <span>1</span>
                        <h3>Basic Information </h3>
                    </div>
                    <div className={'form-step active'}>
                        <div className='form-step-line'></div>
                        <span>2</span>
                        <h3>Emergency Contact</h3>
                    </div>
                    <div className={'form-step running'}>
                        <span>3</span>
                        <h3>Document</h3>
                    </div>

                </div>
                {errors?.try && <><span style={{ color: "red" }}>{errors?.try[0]}</span></>}
                {errors?.catch && <><span style={{ color: "red" }}>{errors?.catch[0]}</span></>}

              
                <div className='row'>
                    <div className='col-4'>
                        <div className='input-box' style={{ position: "relative" }}>
                            <label>Photo</label><br></br>
                            <label className='upload-box upload-box-2'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={(e) => handleImageChange(e, 'photo')} /> <CloudUploadOutlined />Drop a file here and click upload<p style={{color: "grey"}}>Maximum file size:33.65MB</p></label>

                            {photo ?
                                <>
                                    <div className="image-box">
                                        <img src={URL.createObjectURL(photo)} frameborder="0" style={{ width: "100%", height: "100%" }}></img>
                                    </div>
                                    <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'photo')}><CloseOutlined /></button>
                                </>
                                : <>
                                    {api_photo && <>
                                        <div className="image-box">
                                            <img src={SIS_STORAGE + "/students/" + api_photo} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                        </div>
                                        <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'api_photo')}><CloseOutlined /></button>
                                    </>}

                                </>}
                            {errors?.photo && <><span style={{ color: "red" }}>{errors?.photo[0]}</span></>}

                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-box' style={{ position: "relative" }}>
                            <label>High school diploma and transcripts</label><br></br>
                            <label className='upload-box upload-box-2'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={(e) => handleImageChange(e, 'high_school_diploma_and_transcripts')} /> <CloudUploadOutlined />Drop a file here and click upload<p style={{color: "grey"}}>Maximum file size:33.65MB</p></label>
                            
                            {high_school_diploma_and_transcripts ?
                                <>
                                    <div className="image-box">
                                        <img src={URL.createObjectURL(high_school_diploma_and_transcripts)} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                    </div>
                                    <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'high_school_diploma_and_transcripts')}><CloseOutlined /></button>
                                </>
                                : <>
                                    {api_high_school_diploma_and_transcripts && <>
                                        <div className="image-box">
                                            <img src={SIS_STORAGE + "/students/" + api_high_school_diploma_and_transcripts} frameborder="0" style={{ width: "100px", height: "150px" }}></img>
                                        </div>
                                        <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'api_high_school_diploma_and_transcripts')}><CloseOutlined /></button>
                                    </>}

                                </>}
                            {errors?.high_school_diploma_and_transcripts && <><span style={{ color: "red" }}>{errors?.high_school_diploma_and_transcripts[0]}</span></>}

                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-box' style={{ position: "relative" }}>
                            <label>Transcripts From Higher Educational Institutions Attended</label><br></br>
                            <label className='upload-box upload-box-2'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={(e) => handleImageChange(e, 'transcripts_from_higher_educational')} /> <CloudUploadOutlined />Drop a file here and click upload <p style={{color: "grey"}}>Maximum file size:33.65MB</p></label>

                            {transcripts_from_higher_educational ?
                                <>
                                    <div className="image-box">
                                        <img src={URL.createObjectURL(transcripts_from_higher_educational)} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                    </div>
                                    <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'transcripts_from_higher_educational')}><CloseOutlined /></button>
                                </>
                                : <>
                                    {api_transcripts_from_higher_educational && <>
                                        <div className="image-box">
                                            <img src={SIS_STORAGE + "/students/" + api_transcripts_from_higher_educational} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                        </div>
                                        <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'api_transcripts_from_higher_educational')}><CloseOutlined /></button>
                                    </>}
                                </>}
                            {errors?.transcripts_from_higher_educational && <><span style={{ color: "red" }}>{errors?.transcripts_from_higher_educational[0]}</span></>}

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='input-box' style={{ position: "relative" }}>
                            <label>Resume with Summary of all work, Extracurricular Activities, and Education History</label><br></br>
                            <label className='upload-box upload-box-2'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={(e) => handleImageChange(e, 'resume')} /> <CloudUploadOutlined />Drop a file here and click upload<p style={{color: "grey"}}>Maximum file size:33.65MB</p></label>

                            {resume ?
                                <>
                                    <div className="image-box">
                                        <img src={URL.createObjectURL(resume)} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                    </div>
                                    <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'resume')}><CloseOutlined /></button>
                                </>
                                : <>

                                    {api_resume && <>
                                        <div className="image-box">
                                            <img src={SIS_STORAGE + "/students/" + api_resume} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                        </div>
                                        <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'api_resume')}><CloseOutlined /></button>
                                    </>}
                                </>}
                            {errors?.resume && <><span style={{ color: "red" }}>{errors?.resume[0]}</span></>}

                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='input-box' style={{ position: "relative" }}>
                            <label>Photocopy of Passport or Equivalent Documents</label><br></br>
                            <label className='upload-box upload-box-2'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={(e) => handleImageChange(e, 'photocopy_of_passport_or_equivalent_documents')} /> <CloudUploadOutlined />Drop a file here and click upload<p style={{color: "grey"}}>Maximum file size:33.65MB</p></label>

                            {photocopy_of_passport_or_equivalent_documents ?
                                <>
                                    <div className="image-box">
                                        <img src={URL.createObjectURL(photocopy_of_passport_or_equivalent_documents)} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                    </div>
                                    <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'photocopy_of_passport_or_equivalent_documents')}><CloseOutlined /></button>
                                </>
                                : <>

                                    {api_photocopy_of_passport_or_equivalent_documents && <>
                                        <div className="image-box">
                                            <img src={SIS_STORAGE + "/students/" + api_photocopy_of_passport_or_equivalent_documents} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                        </div>
                                        <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'api_photocopy_of_passport_or_equivalent_documents')}><CloseOutlined /></button>
                                    </>}
                                </>}
                            {errors?.photocopy_of_passport_or_equivalent_documents && <><span style={{ color: "red" }}>{errors?.photocopy_of_passport_or_equivalent_documents[0]}</span></>}

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='input-box' style={{ position: "relative" }}>
                            <label>Proof of english</label><br></br>
                            <label className='upload-box upload-box-2'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={(e) => handleImageChange(e, 'proof_of_english')} /> <CloudUploadOutlined />Drop a file here and click upload <p style={{color: "grey"}}>Maximum file size:33.65MB</p></label>

                            {proof_of_english ?
                                <>
                                    <div className="image-box">
                                        <img src={URL.createObjectURL(proof_of_english)} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                    </div>
                                    <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'proof_of_english')}><CloseOutlined /></button>
                                </>
                                : <>
                                    {api_proof_of_english && <>
                                        <div className="image-box">
                                            <img src={SIS_STORAGE + "/students/" + api_proof_of_english} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                        </div>
                                        <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'api_proof_of_english')}><CloseOutlined /></button>
                                    </>}
                                </>}
                            {errors?.proof_of_english && <><span style={{ color: "red" }}>{errors?.proof_of_english[0]}</span></>}

                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='input-box' style={{ position: "relative" }}>
                            <label>One Page Essay Explaining Applicant's Interest in a Lincoin University Program</label><br></br>
                            <label className='upload-box upload-box-2'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={(e) => handleImageChange(e, 'essay_explaining_interest')} /> <CloudUploadOutlined />Drop a file here and click upload <p style={{color: "grey"}}>Maximum file size:33.65MB</p></label>

                            {essay_explaining_interest ?
                                <>
                                    <div className="image-box">
                                        <img src={URL.createObjectURL(essay_explaining_interest)} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                    </div>
                                    <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'essay_explaining_interest')}><CloseOutlined /></button>
                                </>
                                : <>
                                    {api_essay_explaining_interest && <>
                                        <div className="image-box">
                                            <img src={SIS_STORAGE + "/students/" + api_essay_explaining_interest} frameborder="0" style={{ width: "100%", height: "150px" }}></img>
                                        </div>
                                        <button className='image-remove' onClick={(e) => handleRemoveImage(e, 'api_essay_explaining_interest')}><CloseOutlined /></button>
                                    </>}
                                </>}
                            {errors?.essay_explaining_interest && <><span style={{ color: "red" }}>{errors?.essay_explaining_interest[0]}</span></>}

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='input-box' style={{ position: "relative" }}>
                            <label>Recruiter</label><br></br>
                            <Input onChange={(e) => set_recruiter(e.target.value)} value={recruiter} />
                            {errors?.recruiter && <><span style={{ color: "red" }}>{errors?.recruiter[0]}</span></>}
                        </div>
                    </div>

                </div>

                <div className='row'>
                    <div className='col-12'>
                        <div className='input-box'>
                            {loader ? <>
                                <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Submit</Button>
                            </> : <>
                                <Button type="primary" onClick={ADD_API}>Submit</Button>
                            </>}
                        </div>

                    </div>
                </div>

            </div>

        </div>
    );
};

export default StudentDocuments;